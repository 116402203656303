import React, { useState, useEffect, useRef } from "react";
import "./TextSearchFilter.css";
import { ReactComponent as IconSearch } from "../../../assets/images/icon-search-fill.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";

const TextSearchFilter = ({
  isOpen,
  onClose,
  candidateData,
  onSelectCandidate,
  onDelete,
  setFilteredData,
  fetchFilteredCandidates,
  selectedTextSearch,
  setSelectedTextSearch,
  textSearchFilterType,
  setTextSearchFilterType,
  setIsEditView
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
   
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  // const handleDone = () => {
  //   if (searchTerm && searchTerm.length >= 2) {
  //      setSelectedTextSearch(searchTerm);
  //     onSelectCandidate(searchTerm);
  //     setIsConfirmed(true);
  //   }
  // };

  const handleDone = () => {
    if (searchTerm && searchTerm.length >= 2) {
      setSelectedTextSearch(searchTerm);
      onSelectCandidate(searchTerm);
      setIsConfirmed(true);
      console.log("Search Confirmed:", searchTerm);
      console.log("isConfirmed:", isConfirmed);
      setIsEditView(true);
    }
  };
  
  

  const handleEdit = () => {
    setIsConfirmed(false);
  };



  useEffect(() => {
    if (selectedTextSearch === null || selectedTextSearch ==="") {
   
      setFilteredData([]);
    }
  }, [selectedTextSearch]); 
  
  

  const handleDelete = () => {
    setSelectedTextSearch(null);
    setSearchTerm("");
    setIsConfirmed(false);
    
    setTimeout(() => {
      onDelete(null);
      setSelectedTextSearch(null);
    
    }, 100);
  };
  

  const handleFilterTypeChange = (event) => {
    setTextSearchFilterType(event.target.value);
 
  };

  if (!isOpen) return null;

  return (
    <div className="text-search-modal-main">
      <div className="text-search-modal-content" ref={modalRef}>
        {isConfirmed ? (
          <div className="confirmation-view">
            <div className="selected_header">
              <div>
                <h6>
                  <IconSearch /> Text Search
                </h6>
              </div>
              <div className="manage-icons">
                <EditIcon onClick={handleEdit} />
                <DeleteIcon onClick={handleDelete} />
              </div>
            </div>
            <p className="selected-candidate-name">
              {textSearchFilterType} {" "} { selectedTextSearch}
            </p>
          </div>
        ) : (
          <>
            <div className="filter-options">
              <h6>Text Search</h6>
              <div className="filter-type-dropdown">
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is one of"
                    checked={textSearchFilterType === "is one of"}
                    onChange={handleFilterTypeChange}
                  />
                  Contains one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not one of"
                    checked={textSearchFilterType === "is not one of"}
                    onChange={handleFilterTypeChange}
                  />
                  Does not contain any of
                </label>
              </div>
            </div>
            <input
              type="text"
              placeholder="Search candidate"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="text-search-bar"
            />
            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TextSearchFilter;
