import React, { useState, useEffect } from "react";
import "./TargetIndustries.css";
import { ReactComponent as SettingIcon } from "../../../assets/images/setting.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusCircle.svg";
import SelectTargetIndustries from "./SelectTargetIndustries/SelectTargetIndustries";
import { ReactComponent as EditIcon } from "../../../assets/images/icon-edit-line.svg";

function TargetIndustries({ candidate, hideEditIcon }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedCandidate, setUpdatedCandidate] = useState(null);

  // Debugging logs to check the values
  // console.log("Initial Candidate Prop:", candidate);

  useEffect(() => {
    if (candidate && typeof candidate === "object") {
      setUpdatedCandidate({
        ...candidate,
        targetIndustries: Array.isArray(candidate.targetIndustries)
          ? candidate.targetIndustries
          : [],
      });
    } else {
      setUpdatedCandidate({ targetIndustries: [] }); // Safe default value
    }
  }, [candidate]);

  // console.log("Updated Candidate State:", updatedCandidate);

  const handleUpdate = (updatedData) => {
    setUpdatedCandidate((prev) => ({
      ...prev,
      targetIndustries: Array.isArray(updatedData?.targetIndustries)
        ? updatedData.targetIndustries
        : [],
    }));
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Ensure UI does not crash if updatedCandidate is still null
  if (!updatedCandidate) {
    return <div>Loading...</div>; // Temporary placeholder until data loads
  }

  return (
    <div className="target_industries">
      <h4>Target Industries</h4>

      {!isModalOpen ? (
        <div className="target-industries-section">
  {Array.isArray(updatedCandidate.targetIndustries) &&
  updatedCandidate.targetIndustries.length > 0 ? (
    <div className="exist-target-value">
      {!hideEditIcon && (
        <div className="editIcon" onClick={openModal}>
          <EditIcon />
        </div>
      )}
      
      <div className="target-list-options">
        <span className="industry-tag">
          {updatedCandidate.targetIndustries.length > 0
            ? updatedCandidate.targetIndustries.map((industryObj) => industryObj.industry).join(", ")
            : "No industries added yet"}
        </span>
      </div>
    </div>
  ) : (
    <div className="empty-state">
      <div className="plusIcon" onClick={openModal}>
        <PlusIcon />
      </div>
      <SettingIcon />
      <p>Let companies know the industries you're most interested in.</p>
      <button onClick={openModal}>Select Target Industries</button>
    </div>
  )}
</div>

  


        
      ) : (
        <SelectTargetIndustries
          isOpen={isModalOpen}
          onClose={closeModal}
          candidate={updatedCandidate}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  );
}

export default TargetIndustries;
