import React, { useState } from "react";
import "./Resume.css";
import { ReactComponent as ResumeIcon } from "../../../assets/images/resume.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusCircle.svg";
import UploadRedactedResume from "./UploadResume/UploadRedactedResume";
import { ReactComponent as PdfIcon } from "../../../assets/images/pdfIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/icon-edit-line.svg";

function RedactedResume({ candidate, onViewReductedResume }) {
  const [showUploadResume, setShowUploadResume] = useState(false);
  const [updatedResume, setUpdatedResume] = useState(null);

  const handleUpdate = (updatedFile) => {
    const updatedFileURL = URL.createObjectURL(updatedFile);
    setUpdatedResume(updatedFileURL); // Save temporary URL in state
    setShowUploadResume(false); // Close upload section after updating
  };

  const toggleUploadResume = () => {
    setShowUploadResume(!showUploadResume);
  };

  // If updatedResume exists, show it; otherwise, use candidate.redactedResumeLink
  const displayResume = updatedResume || candidate.redactedResumeLink;

  return (
    <div className="resume-sec">
      <h4>Redacted Resume</h4>

      {!showUploadResume ? (
        displayResume ? (
          <div className="show-state">
            <div className="editIcon" onClick={toggleUploadResume}>
              <EditIcon />
            </div>
            <div className="resume-link">
              <div>
                <PdfIcon />
                <span style={{ paddingLeft: "5px" }}>Redacted-Resume...</span>
              </div>
              <div>
              <button onClick={() => onViewReductedResume(displayResume)}>View Resume</button>

              </div>
            </div>
          </div>
        ) : (
          <div className="empty-state">
            <div className="plusIcon" onClick={toggleUploadResume}>
              <PlusIcon />
            </div>
            <div className="emptyIcon">
              <ResumeIcon />
            </div>
            <p>
              A resume is crucial for attracting potential employers. Upload your resume to give companies a complete view of your qualifications.
            </p>
            <button onClick={toggleUploadResume}>Upload Resume</button>
          </div>
        )
      ) : (
        <UploadRedactedResume
          isOpen={showUploadResume}
          onClose={toggleUploadResume}
          onUpdate={handleUpdate} // Passing updated function
          candidate={candidate}
        />
      )}
    </div>
  );
}

export default RedactedResume;
