import React, { useState } from "react";
import Modal from "react-modal";
import CompleteCompanyImageUpload from "./CompleteCompanyImageUpload"; // Image upload component
import "./CompanyImageUpload.css";

// Modal setup
Modal.setAppElement("#root");

const CompanyImageUpload = ({ isOpen, onClose, company, onImageUpload }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "45%",
      marginRight: "-50%",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      padding: "0px",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Upload Photo"
      onRequestClose={onClose}
      style={customStyles}
    >
      <div className="popup-content profile_upload_popup_content">
        <button onClick={onClose} className="close-btn">
          X
        </button>
        <CompleteCompanyImageUpload
          onClose={onClose}
          onImageUpload={(newImageUrl) => {
            onImageUpload(newImageUrl); // Parent ko new image URL pass karein
            onClose();
          }}
          company={company}
        />
      </div>
    </Modal>
  );
};

export default CompanyImageUpload;
