import React, { useState, useContext } from "react";
//import profileImage from "../../assets/images/profileDefaultCandidate.png"; // Profile image placeholder
// import pinImg from "../../../assets/images/Pin.png"; // Profile image placeholder
// import profileEditIcon from "../../../assets/images/profileEdit.png"; // Profile image placeholder


import { ReactComponent as EditIconLine } from "../../../assets/images/icon-edit-line.svg"; 

import "../Profile/CandidateProfileBox.css"; // For Settings component
import { Link } from "react-router-dom";
import ProfileImagePopup from "../ProfileImagePopup/ProfileImagePopup";
import ProfileImageUpload from "../ProfileImagePopup/ProfileImageUpload";

function CandidateProfileBox({ candidate }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Modal open or close function
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  // const profileImage = `${BASE_URL}/${candidate.headshotLink}`;
  const [profileImage, setProfileImage] = useState(`${candidate.headshotLink}`);
  const defaultProfileImage = `${BASE_URL}/${candidate.headshotLink}`;
  const handleImageUploadSuccess = (newImageUrl) => {
    setProfileImage(newImageUrl);  // State update karein
    candidate.headshotLink = newImageUrl; // Candidate object update karein
  };
  
  
  
  return (
    <>
      <div className="profile-box-wrap-dashboard">
        <div className="left-box">
          <div className="img-box profile-box-img">
            <div>
              {candidate.headshotLink ? (
                <div className="exist-profile-image">
               <img
  src={profileImage }
  alt="Profile-Img"
  className="company-logo-img"
/>

                </div>
              ) : (

                <div className="ProfileDefaultImg">
                  <h2>{candidate.name.charAt(0)}</h2>
                </div>
              )}
            </div>
            <div
              className="profileEdit"
              onClick={openModal}
              candidate={candidate}
            >
              <EditIconLine />
            </div>
          </div>
        </div>
      </div>
      <ProfileImageUpload
        isOpen={isModalOpen}
        onClose={closeModal}
        candidate={candidate}
        onImageUpload={handleImageUploadSuccess}
      />
    </>
  );
}

export default CandidateProfileBox;
