import React, { useState, useRef, useContext, useEffect, useCallback } from "react";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from '../../../context/AuthContext';
import InputMask from "react-input-mask";
import CandidateProfileBox from "../../Candidates/Profile/CandidateProfileBox";
import axios from 'axios';

function UpdateBasicInformation({ isOpen, onUpdate, onClose, candidate }) {
  const { keycloak } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    ...candidate,
    fname: candidate.fname || "",
    lname: candidate.lname || "",
    email: candidate.email || "",
    phone: candidate.phone || "",
    // idno: candidate.recruiterFlowId || "",
    state: candidate.state || "",
    city: candidate.city || "",
    type: candidate.type || "",
  });

  const [errors, setErrors] = useState({});
  

 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (value.trim() !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.fname.trim()) newErrors.fname = "First name is required";
    if (!formData.lname.trim()) newErrors.lname = "Last name is required";
    if (!formData.city.trim()) newErrors.city = "City is required";
    if (!formData.state.trim()) newErrors.state = "State is required";
    if (!formData.type) newErrors.type = "Candidate type is required";

    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    // Phone validation
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formData.phone)) {
      newErrors.phone = "Invalid phone format (e.g., (123) 456-7890)";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/candidates/${candidate._id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          "Content-Type": "application/json",
        },
        
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message || "Failed to update candidate");
      }

      toast.success("Candidate updated successfully!");
      onUpdate && onUpdate(formData);
      setTimeout(() => onClose && onClose(), 2000);
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const [searchResults, setSearchResults] = useState([]);
  const [inputValue, setInputValue] = useState(formData.city || "");
  const [debouncedValue, setDebouncedValue] = useState("");
  
  useEffect(() => {
    setInputValue(formData?.city || "");
  }, [formData?.city]);
  
  useEffect(() => {
    if (inputValue.trim()) {
      const handler = setTimeout(() => {
        setDebouncedValue(inputValue);
      }, 1000); // 1-second delay
  
      return () => clearTimeout(handler);
    }
  }, [inputValue]);
  
  useEffect(() => {
    if (debouncedValue) {
      fetchPlaces(debouncedValue);
    }
  }, [debouncedValue]);
  
  const fetchPlaces = useCallback(async (input) => {
    if (!input) return;
    try {
      const res = await axios.get(`/api/google-places?query=${input}`);
      setSearchResults(res.data.predictions || []);
    } catch (error) {
      console.error("Error fetching places:", error);
    }
  }, []);
  
  const handleChangePlace = (e) => {
    setInputValue(e.target.value);
  };
  
  const handleSelect = (place) => {
    setInputValue(place.description);
    setFormData((prevData) => ({ ...prevData, city: place.description }));
    setSearchResults([]);
  };
  
  // console.log("searchResults:::", searchResults);
  // console.log("locationnn::::", formData);
  // console.log("input::::", inputValue);
  
  return (
    <>
      <ToastContainer />
      <div className="popup-content updateprofileform">
        <h6>Candidate Information</h6>
        <h5>Profile Photo</h5>
        <CandidateProfileBox candidate={candidate} />
        <form className="popup-form" onSubmit={handleSubmit}>
          {/* First Name & Last Name */}
          <div className="two-fields">
            <div className="form-field">
              <label>First Name <span>*</span></label>
              <input type="text" name="fname" value={formData.fname} onChange={handleChange} className={errors.fname && "error-border"} />
              {errors.fname && <span className="error-message">{errors.fname}</span>}
            </div>
            <div className="form-field">
              <label>Last Name <span>*</span></label>
              <input type="text" name="lname" value={formData.lname} onChange={handleChange} className={errors.lname && "error-border"} />
              {errors.lname && <span className="error-message">{errors.lname}</span>}
            </div>
          </div>


          {/* Email & Phone */}
          <div className="two-fields">
     
            <div className="form-field">
              <label>Email <span>*</span></label>
              <input type="email" name="email" value={formData.email} onChange={handleChange} className={errors.email && "error-border"} />
              {errors.email && <span className="error-message">{errors.email}</span>}
            </div>
            <div className="form-field">
              <label>Phone <span>*</span></label>
              <InputMask mask="(999) 999-9999" name="phone" value={formData.phone} onChange={handleChange} className={errors.phone && "error-border"} />
              {errors.phone && <span className="error-message">{errors.phone}</span>}
            </div>
          </div>

          {/* Location Search */}
          <div className="form-field">
      <>
        <label>
          Location<span>*</span>
        </label>
        <input
          type="text"
          placeholder="Search location"
          value={inputValue}
          onChange={handleChangePlace}
          style={{
            width: "100%",
            padding: "14px",
            borderRadius: "10px",
            border: "1px solid #ddddde",
          }}
          className={!formData.city ? "error-border" : ""}
        />

        {/* Show dropdown results */}
        {searchResults.length > 0 && (
          <ul
            style={{
              listStyle: "none",
              padding: "0",
              margin: "0",
              border: "1px solid #ddd",
              borderRadius: "10px",
              background: "#fff",
              position: "absolute",
              zIndex: "1000",
              width: "100%",
            }}
          >
            {searchResults.map((place, index) => (
              <li
                key={index}
                onClick={() => handleSelect(place)}
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  borderBottom: "1px solid #ddd",
                }}
              >
                {place.description}
              </li>
            ))}
          </ul>
        )}

        {errors.city && <span className="error-message-field">{errors.city}</span>}
      </>
    </div>

          {/* Candidate Type */}
          <div className="form-field">
            <label>Candidate Type<span>*</span></label>
            <select name="type" value={formData.type} onChange={handleChange} className={errors.type && "error-border"}>
              <option value="">Select Candidate Type</option>
              <option value="Transitioning Service Member">Transitioning Service Member</option>
              <option value="Veteran">Veteran</option>
            </select>
            {errors.type && <span className="error-message">{errors.type}</span>}
          </div>

          <button type="submit" className="btn-save-changes">Save</button>
        </form>
      </div>
    </>
  );
}

export default UpdateBasicInformation;
