import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import "./SelectTargetIndustries.css";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../../../context/AuthContext";

Modal.setAppElement("#root");

const SelectTargetIndustries = ({ isOpen, onClose, candidate, onUpdate }) => {
  const { keycloak } = useContext(AuthContext);
  const [currentTargets, setCurrentTargets] = useState(candidate.targetIndustries || []);
  const [inputValue, setInputValue] = useState(""); 
  const [suggestions, setSuggestions] = useState([]);
  const [industriesList, setIndustriesList] = useState([]); // API se fetched industries store karega

  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${apiUrl}/industry/industry`, {
          headers: { Authorization: `Bearer ${keycloak.token}` }
        });

        if (response.data) {
          setIndustriesList(response.data); // API se industries set karein
        }
      } catch (error) {
        console.error("Error fetching industries:", error);
      }
    };

    fetchIndustries();
  }, []);

  const handleInputChange = (e) => {
    const input = e.target.value;
    setInputValue(input);
  
    if (input.length > 0) {
      const filteredSuggestions = industriesList
        .filter(
          (ind) =>
            ind.visible &&
            ind.industry &&
            ind.industry.toLowerCase().includes(input.toLowerCase())
        );
  
      const exactMatch = filteredSuggestions.some(
        (ind) => ind.industry.toLowerCase() === input.toLowerCase()
      );
  
      // If not an exact match, add "Create new" entry
      if (!exactMatch) {
        filteredSuggestions.push({ _id: "new", industry: `Create new: ${input}` });
      }
  
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = async (suggestion) => {
    let newIndustry = suggestion.industry;
  
    // Handle "Create new" case
    if (newIndustry.startsWith("Create new:")) {
      newIndustry = newIndustry.replace("Create new: ", "").trim();
    }
  
    // Avoid duplicate selection
    if (
      !currentTargets.some(
        (ind) => ind.industry.toLowerCase() === newIndustry.toLowerCase()
      )
    ) {
      let existingIndustry = industriesList.find(
        (ind) => ind.industry.toLowerCase() === newIndustry.toLowerCase()
      );
  
      if (!existingIndustry) {
        // Create new industry
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetch(`${apiUrl}/industry/industry`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${keycloak.token}`,
            },
            body: JSON.stringify({ industry: newIndustry }),
          });

          const data = await response.json();
          if (!response.ok) throw new Error();
          existingIndustry = { _id: data._id, industry: data.industry };
          setIndustriesList((prev) => [...prev, existingIndustry]);
        } catch (error) {
          toast.error("Failed to create new industry.");
          return;
        }
      }
        setCurrentTargets((prev) => [...prev, existingIndustry]);
    } 
    setInputValue("");
    setSuggestions([]);
  }; 


  const handleRemoveIndustry = (index) => {
    const updatedTargets = currentTargets.filter((_, i) => i !== index);
    setCurrentTargets(updatedTargets);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(
        `${apiUrl}/candidates/${candidate._id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...candidate,
            targetIndustries: currentTargets.map(ind => ind._id),
            
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update candidate");
      }

      const updatedCandidate = await response.json();

      toast.success("Candidate updated successfully!");
      if (onUpdate) {
        onUpdate({
          ...candidate,
          targetIndustries: currentTargets,
        });
      }

      setTimeout(() => {
        if (onClose) {
          onClose();
        }
      }, 2000);
    } catch (error) {
      toast.error("Error updating candidate: " + error.message);
    }
  };

  return (
    <>
      <ToastContainer />

      <div className="target-industries-popup popup-content">
        <input
          type="text"
          value={inputValue || ""}
          onChange={handleInputChange}
          placeholder="Type or search industries"
        />

{Array.isArray(suggestions) && suggestions.length > 0 && (
  <ul className="suggestions">
    {suggestions.map((suggestion, index) => (
      <li
        key={index}
        onClick={() => handleSuggestionClick(suggestion)}
        className="suggestion-item"
      >
        {suggestion.industry || "Unknown Industry"}
      </li>
    ))}
  </ul>
)}


        <div className="selected-industries" style={{ display: "flex", flexWrap: "wrap" }}>
        {Array.isArray(currentTargets) && currentTargets.length > 0 ? (
            currentTargets.map((industry, index) => (
              <span key={index} className="tag">
                {industry?.industry || "Unknown Industry"}
                <button onClick={() => handleRemoveIndustry(index)}>x</button>
              </span>
            ))
          ) : (
            <p className="empty-message">No industries selected</p>
          )}
        </div>

        <div className="form-btn">
          <button onClick={handleSubmit} className="btn-save-changes">
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectTargetIndustries;
