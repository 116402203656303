import React, { useState } from "react";
import "./Resume.css";
import { ReactComponent as ResumeIcon } from "../../../assets/images/resume.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusCircle.svg";
import UploadResume from "./UploadResume/UploadOriginalResume";
import { ReactComponent as PdfIcon } from "../../../assets/images/pdfIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/icon-edit-line.svg";

function OriginalResume({ candidate, onViewResume }) {
  const [showUploadResume, setShowUploadResume] = useState(false);
  const [updatedResume, setUpdatedResume] = useState(null);

  const handleUpdate = (updatedFile) => {
    const updatedFileURL = URL.createObjectURL(updatedFile);
    setUpdatedResume(updatedFileURL);
    setShowUploadResume(false);
  };

  const toggleUploadResume = () => {
    setShowUploadResume(!showUploadResume);
  };

  const displayResume = updatedResume || candidate.resumeLink;

  return (
    <div className="resume-sec">
      <h4>Resume</h4>
      {!showUploadResume ? (
        displayResume ? (
          <div className="show-state">
            <div className="editIcon" onClick={toggleUploadResume}>
              <EditIcon />
            </div>
            <div className="resume-link">
              <div>
                <PdfIcon />
                <span style={{ paddingLeft: "5px" }}>{candidate.fname}-Resume...</span>
              </div>
              <div>
                <button onClick={() => onViewResume(displayResume)}>View Resume</button>
              </div>
            </div>
          </div>
        ) : (
          <div className="empty-state">
            <div className="plusIcon" onClick={toggleUploadResume}>
              <PlusIcon />
            </div>
            <div className="emptyIcon">
              <ResumeIcon />
            </div>
            <p>
              A resume is crucial for attracting potential employers. Upload your resume to give companies a complete view of your qualifications.
            </p>
            <button onClick={toggleUploadResume}>Upload Resume</button>
          </div>
        )
      ) : (
        <UploadResume
          isOpen={showUploadResume}
          onClose={toggleUploadResume}
          onUpdate={handleUpdate}
          candidate={candidate}
        />
      )}
    </div>
  );
}

export default OriginalResume;
