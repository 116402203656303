import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import "./NotesList.css";
import { AuthContext } from '../../../context/AuthContext';

function NotesList({ candidate }) {
  const { keycloak } = useContext(AuthContext);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/candidates/${candidate._id}/notes`,
          {
            headers: {
              Authorization: `Bearer ${keycloak.token}`, // Use Keycloak token for authentication
              "Content-Type": "application/json",
            },
          }
        );

        // Sort the notes by created_time in descending order
        const sortedNotes = response.data.sort(
          (a, b) => b.created_time - a.created_time
        );
        setNotes(sortedNotes);
      } catch (error) {
        console.error("Error fetching notes", error);
      }
    };

    if (candidate && candidate._id && keycloak?.token) {
      fetchNotes();
    }
  }, [candidate, keycloak?.token]);

  // Custom function to format the date
  const formatDate = (timestamp) => {
    if (!timestamp) return "Unknown date"; // Handle undefined date safely

    const options = {
      month: "short", // e.g., "Sep"
      day: "numeric", // e.g., "25"
    };
    const formattedDate = new Date(timestamp).toLocaleDateString("en-US", options);
    const time = new Date(timestamp)
      .toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .toLowerCase();

    return `${formattedDate} at ${time}`;
  };

  return (
    <div className="note_list_section">
      {notes.length > 0 ? (
        notes.map((note, index) => (
          <div key={index} className="note_box">
            <div className="user_info_date">
              <div className="user_profile_info">
              <div className="profile-image" style={{ width: "24px",
    height: "24px",
    backgroundColor: "#e8e9eb"}}>
                    
                    <h2 style={{fontSize:"14px"}}>{note.author?.name.charAt(0)}</h2>

                  </div>
                <h6>{note.author?.name || "Unknown User"}</h6>
              </div>
              <small>{formatDate(note.created_time)}</small>
            </div>

            <div dangerouslySetInnerHTML={{ __html: note.content }} />
          </div>
        ))
      ) : (
        <p>No notes available.</p>
      )}
    </div>
  );
}

export default NotesList;
