import React, { useState } from "react";
import "./isHotCandidateFilters.css";
import { ReactComponent as IconUser } from "../../../assets/images/icon-user-fill.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";

const IsHotCandidateFilters = ({
  isOpen,
  onClose,
  hotCandidateFilterType,
  setHotCandidateFilterType,
  fetchFilteredCandidates
}) => {
  // ✅ Hooks must be at the top level
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isHotValueType, setIsHotValueType] = useState(false);

  const handleDone = () => {
    setIsConfirmed(true);
    if(hotCandidateFilterType === "is set"){
      setIsHotValueType("true");
    }
    else{
      setIsHotValueType("false");
    }
  };

  const handleEdit = () => {
    setIsConfirmed(false);
  };

  const handleDelete = () => {
    setHotCandidateFilterType(null);
    setIsConfirmed(false);
    fetchFilteredCandidates();
  };

  const handleFilterTypeChange = (event) => {
    setHotCandidateFilterType(event.target.value);
    fetchFilteredCandidates();
  };

  // ✅ Return null inside JSX, not before hooks
  if (!isOpen) {
    return null;
  }

  return (
    <div className="hot-candidate-filter-modal-main">
      <div className="hot-candidate-filter-modal-content">
        {isConfirmed ? (
          <div className="confirmation-view">
            <div className="selected_header">
              <div>
                <h6>
                  <IconUser /> Hot Candidate
                </h6>
              </div>
              <div className="manage-icons">
                <EditIcon onClick={handleEdit} />
                <DeleteIcon onClick={handleDelete} />
              </div>
            </div>
            <p className="selected-manager-name">{hotCandidateFilterType} {""} {isHotValueType}</p>
          </div>
        ) : (
          <>
            <div className="filter-options">
              <h6>
                <IconUser /> Hot Candidate
              </h6>
              <div className="hot-candidate-radio-buttons" style={{ paddingTop: "10px", display:"grid" }}>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is set"
                    checked={hotCandidateFilterType === "is set"}
                    onChange={handleFilterTypeChange}
                    style={{marginRight:"5px"}}
                  />
                  is set
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not set"
                    checked={hotCandidateFilterType === "is not set"}
                    onChange={handleFilterTypeChange}
                    style={{marginRight:"5px"}}
                  />
                  is not set
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default IsHotCandidateFilters;
