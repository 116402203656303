import React,{useState} from "react";
import "./AdminProfile.css";
import { FaEnvelope, FaPhone, FaCalendarAlt } from "react-icons/fa";
import { ReactComponent as EmailIcon } from "../../../../assets/images/emailIcon.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/images/phoneicon.svg";
import { ReactComponent as CalendlyIcon } from "../../../../assets/images/calender.svg";
import { ReactComponent as EditIcon } from "../../../../assets/images/edit.svg";
import AdminUpdateProfile from "./UpdateAdminProfile";
import EmailPermission from "./EmailPermission";

function AdminProfile({admin,setAdmin}) {
    const [EditProfile, setEditProfile] = useState (false);
  const handleEditProfile = () =>{
    setEditProfile(true);
  }
  // console.log('adminadmin:::',admin)
  return (
    <div className="section_profile">
      <div className="header_profile">
        <h2>Profile</h2>
      </div>
      <div className="profile_table">
      {EditProfile ? ( 
  <AdminUpdateProfile setEditProfile={setEditProfile} admin={admin} setAdmin={setAdmin} />
) : (  
  <div className="profile_card">
    <h3>Personal Information</h3>
    <p>Manage your personal details and connect your email account. changing</p>
    <div className="profile_info">
      <div className="profile_photo_admin">
        { admin?.headshotLink ? (
          <img
            src={admin?.headshotLink}
            alt="Profile"
            className="profile_image"
          />
        ) : (
          <div className="ProfileDefaultImg">
            <h2>{admin?.fname?.charAt(0)}</h2>
          </div>
        )}
        <strong>{admin?.fname+" "+admin?.lname}</strong>
      </div>
      <div className="profile_details">
        <p><EmailIcon/> {admin?.email}</p>
        <p><PhoneIcon/> {admin?.phone}</p>
        <p><CalendlyIcon /> 
          <a href={admin?.calendlyLink} target="_blank" rel="noopener noreferrer">
            {/* https://calendly.com/jethrojamero/30min */}
            {admin?.calendlyLink}
          </a>
        </p>
      </div>
    </div>
    <button className="edit_btn" onClick={handleEditProfile}>
      <EditIcon/> Edit
    </button>
  </div>
)}

      {/* Email Permission */}

<EmailPermission/>
      </div>
    </div>
  );
}

export default AdminProfile;
