import React, { useState, useContext, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./JobSearchStatus.css";
import { AuthContext } from '../../../context/AuthContext';

function UpdateJobSearchSlider({ candidate, onUpdate, onClose }) {
  const { keycloak } = useContext(AuthContext);
  const [jobStatuses, setJobStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(candidate?.jobSearchStatus[0]?._id || "");
const [updateJobStatus,setUpdateJobStatus]=useState([]);
  useEffect(() => {



    const fetchJobStatuses = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/jobstatus/jobstatus`, {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        });
        if (!response.ok) throw new Error("Failed to fetch job statuses");

        const data = await response.json();
        setJobStatuses(data);
      } catch (error) {
        console.error("Error fetching job statuses:", error);
      }
    };
    fetchJobStatuses();
  }, [keycloak.token]);

  const handleChange = (e) => {
    const selectedId = e.target.value;
    setSelectedStatus(selectedId);
  
    const fullStatus = jobStatuses.find((status) => status._id === selectedId);
    if (fullStatus) {
      setUpdateJobStatus([fullStatus]); // Store full object inside an array
    } else {
      setUpdateJobStatus([]); // Reset if no match found
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/candidates/${candidate._id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ jobSearchStatus: selectedStatus }),
      });

      if (!response.ok) throw new Error("Failed to update candidate");

      toast.success("Candidate updated successfully!");
      onUpdate({ jobSearchStatus: updateJobStatus });

      setTimeout(() => onClose(), 2000);
    } catch (error) {
      toast.error("Error updating candidate: " + error.message);
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <form onSubmit={handleSubmit} className="update-job-search-form">
        <select
          name="jobSearchStatus"
          value={selectedStatus}
          onChange={handleChange}
          disabled={!jobStatuses.length}
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "10px",
            marginTop: "20px",
            fontSize: "14px",
          }}
        >
          <option value="">Select Job Status</option>
          {jobStatuses.map((status) => (
            <option key={status._id} value={status._id}>
              {status.status}
            </option>
          ))}
        </select>

        <button type="submit" className="btn-save-changes">
          Save
        </button>
      </form>
    </>
  );
}

export default UpdateJobSearchSlider;
