import React, { useState } from "react";
import "./EmailTemplates.css";
import { ReactComponent as EditIcon } from "../../../../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/trash.svg";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Chevron icons

const EmailTemplates = () => {
  const [isExpanded, setIsExpanded] = useState(false); // Toggle state

  const email_templates = [
    {
      name: "777 - Candidate Pool - Introducing POC",
      subject: "Your dedicated POC at...",
      type: "Candidate Email",
    },
    {
      name: "777 - Candidate Pool - Introducing POC",
      subject: "Your dedicated POC at...",
      type: "Candidate Email",
    },
    {
      name: "777 - Candidate Pool - Introducing POC",
      subject: "Your dedicated POC at...",
      type: "Candidate Email",
    },
  ];

  return (
    <div className="section_email_templates">
      {/* Header with Chevron Toggle */}
      <div className="email-template-header" onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? <FaChevronUp style={{color:"#045FB5" , cursor:"pointer"}} /> : <FaChevronDown style={{color:"#045FB5", cursor:"pointer"}}/>} {/* Dynamic Chevron */}
        <h6 style={{ marginLeft: "8px", display: "inline-block", cursor: "pointer" }}>
          Email Template
        </h6>
      </div>

      {/* Table section (only show if expanded) */}
      {isExpanded && (
        <div className="email_templates_table">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Subject</th>
                <th>Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {email_templates.map((email, index) => (
                <tr key={index}>
                  <td>{email.name}</td>
                  <td>{email.subject}</td>
                  <td>{email.type}</td>
                  <td>
                    <EditIcon style={{ marginRight: "5px", cursor: "pointer" }} />
                    <DeleteIcon style={{ cursor: "pointer" }} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default EmailTemplates;
