import React, { useState, useEffect } from "react";
import { ReactComponent as EditIcon } from "../../../assets/images/icon-edit-line.svg";
import UpdateJobSearchSlider from "./UpdateJobSearchSlider";

function JobSearchStatus({ candidate }) {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedCandidate, setUpdatedCandidate] = useState(candidate);

  useEffect(() => {
    setUpdatedCandidate(candidate);
  }, [candidate]);

  const handleUpdate = (updatedData) => {
    setUpdatedCandidate((prev) => ({
      ...prev,
      jobSearchStatus: updatedData.jobSearchStatus, // Update jobSearchStatus object
    }));

    setTimeout(() => {
      setIsEditing(false);
    }, 500);
  };

  return (
    <div className="basicInformation">
      <h4>Job Search Status</h4>
      {isEditing ? (
        <UpdateJobSearchSlider
          candidate={updatedCandidate}
          onUpdate={handleUpdate}
          onClose={() => setIsEditing(false)}
        />
      ) : (
        <div className="job-status-container">
          <div className="job-status">
            <h4 className="job_search_number">
              {updatedCandidate.jobSearchStatus[0]?.value || ""}
            </h4>
            <p>{updatedCandidate.jobSearchStatus[0]?.status || ""}</p>
          </div>
          <div className="editIcon" onClick={() => setIsEditing(true)}>
            <EditIcon />
          </div>
        </div>
      )}
    </div>
  );
}

export default JobSearchStatus;
