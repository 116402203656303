import React from "react";
import "./LeftTabs.css";
import { ReactComponent as UsersIcons} from "../../../../assets/images/icon-users-fill.svg";

import { ReactComponent as UsersIcon} from "../../../../assets/images/icon-user-fill.svg";
import { ReactComponent as MessageIcon } from "../../../../assets/images/icon-message-fill.svg";
import { ReactComponent as KanbanIcon } from "../../../../assets/images/icon-kanban-fill.svg";
import { ReactComponent as IconDown } from "../../../../assets/images/icon-down-fill.svg";
import { ReactComponent as IconForm } from "../../../../assets/images/icon-form-fill.svg";
import { ReactComponent as IconRole } from "../../../../assets/images/User Roles Icon.svg";

const tabIcons = {
  "Users": <UsersIcons style={{marginRight:"5px"}} />, 
  // "Roles": <IconRole style={{marginRight:"5px"}} />, 
  // "Templates": <KanbanIcon style={{marginRight:"5px"}}/>, 
  "Profile": <UsersIcon  style={{marginRight:"5px"}}/>, 
  // "Pipeline Stages": <MessageIcon style={{marginRight:"5px"}} />, 
  "Manage Dropdown": <KanbanIcon style={{marginRight:"5px"}} />, 
  // "Forms": <IconForm style={{marginRight:"5px"}} />
};

// Left Sidebar Component
const LeftTabs = ({ activeTab, setActiveTab }) => {
  const tabs = [
    "Users",
    // "Roles",
    // "Templates",
    "Profile",
    // "Pipeline Stages",
    "Manage Dropdown",
    // "Forms",
  ];

  return (
    <div className="left_setting_sidebar">
      <ul>
        {tabs.map((tab) => (
          <li
            key={tab}
            className={`tab-item ${activeTab === tab ? "active" : ""}`}
            onClick={() => setActiveTab(tab)}
          >
            {tabIcons[tab]} {tab}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeftTabs;
