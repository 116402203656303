import React, { useState, useEffect, useRef, useContext , useCallback} from "react";

import { Link , useNavigate} from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AuthContext } from '../../context/AuthContext';
import TokenDecode from "../../context/DecodeJWT";
import axios from "axios";
// Icon Import 
import { ReactComponent as Logo } from "../../assets/images/logo-7eagle.svg";
import { ReactComponent as FilterIcon } from "../../assets/images/FilterIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/EditIcon.svg";
import { ReactComponent as ColumnEditIcon } from "../../assets/images/column-line-icon.svg";
// Component Import
import Header from "../../components/Common/Header/Header";
import Sidebar from "../../components/Common/Sidebar/Sidebar";
import CandidateTable from "../../components/Candidates/Candidate Records Table/CandidateTable";
import CandidateSearch from "../../components/Candidates/Search Filters/CandidateSearch";
import ColumnEditModal from "../../components/Candidates/Candidate Records Table/ColumnEditModal";
import AddCandidateForm from "../../components/Candidates/Add New Candidates/AddNewCandidateForm";
//  CSS Import
import "../AdminDashboard.css";
// Filters Component Import

import AllFiltersModal from "../../components/Candidates/Candidate Filters/AllFiltersModal";
import useAdmin from "../Common/useAdmin";

const AdminCandidateDashboard = () => {
  const { keycloak } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  
  // Filter modals
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isManagerModalOpen, setIsManagerModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [isTextSearchModalOpen, setIsTextSearchModalOpen] = useState(false);
  const [isRelocationModalOpen, setIsRelocationModalOpen] = useState(false);
  const [isCertificationModalOpen, setIsCertificationModalOpen] = useState(false);
  const [isSecurityClearanceModalOpen, setIsSecurityClearanceModalOpen] = useState(false);
  const [isJobStatusModalOpen, setIsJobStatusModalOpen] = useState(false);
  const [isCandidateTypeModalOpen, setIsCandidateTypeModalOpen] = useState(false);
  const [isMilitaryBranchModalOpen, setMilitaryBranchModalOpen] = useState(false);
  const [isTargetIndustriesModalOpen, setTargetIndustriesModalOpen] = useState(false);
  const [isAffiliateModalOpen, setIsAffiliateModalOpen] = useState(false);
  const [isHotCandidateModalOpen, setIsHotCandidateModalOpen] = useState(false);






  
  // Selected filters Managers

  const [selectedManager, setSelectedManager] = useState(null);
  const [filterType, setFilterType] = useState("is one of"); // Default value set karein

// Selected Filters Candidate Type
const [selectedCandidateType, setSelectedCandidateType] = useState(null);
const [typeFilterType, setTypeFilterType] = useState("is one of");

// Select Filters Branch
const [selectedBranch, setSelectedBranch] = useState(null);
const [branchFilterType, setBranchFilterType] = useState("is one of");

// Text Search Filter States
const [selectedTextSearch, setSelectedTextSearch] = useState(null);
const [textSearchFilterType, setTextSearchFilterType] = useState("is one of");


// Certification Filter States

const [selectedCertifications, setSelectedCertifications] = useState(null);
const [certificationFilterType, setCertificationFilterType] = useState("contains one of");

// Job Search Status Filter States
const [selectedStatus, setSelectedStatus] = useState(null);
const [jobSearchfilterType, setJobSearchfilterType] = useState("is one of");

// Security Clearance
const [selectedSecurityClearance, setSelectedSecurityClearance] = useState(null);
const [securityFilterType, setSecurityFilterType] = useState("is one of");

//Target Industries

const [selectedIndustries, setSelectedIndustries] = useState(null);
const [industryFilterType, setIndustryFilterType] = useState("contains one of");

// Relocation Filter States
const [selectedRelocation, setSelectedRelocation] = useState(null);
const [relocationFilterType, setRelocationFilterType] = useState("is one of");
// Affiliate
const [selectedAffiliate, setSelectedAffiliate] = useState(null);
const [affiliateFilterType, setAffiliateFilterType] = useState("is one of");


// Is Hot Candidate Model

const [selectedHotCandidate, setSelectedHotCandidate] = useState(null);
const [hotCandidateFilterType, setHotCandidateFilterType] = useState("is one of");



const [selectedCandidate, setSelectedCandidate] = useState(null);

  const [selectedMilitaryBranch, setSelectedMilitaryBranch] = useState(null);
  const [selectedJobStatus, setSelectedJobStatus] = useState(null);

  const [selectedCertification, setSelectedCertification] = useState(null);
  
  // Other states
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);


  const { admin ,loading} = useAdmin();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [candidateData, setCandidateData] = useState([]); // Initialize candidate data as empty array
  const [showAddCandidateForm, setShowAddCandidateForm] = useState(false);

// CandidateTable Pagination Logic State

const [candidates, setCandidates] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage, setRecordsPerPage] = useState(2);
const [totalRecords, setTotalRecords] = useState(0);
const [totalPages, setTotalPages] = useState(1);


  const [columns, setColumns] = useState([
    { id: 1, name: "Name", field: "name", visible: true }, 
    { id: 2, name: "Candidate Type", field: "type", visible: true }, 
    { id: 3, name: "Manager", field: "managers", visible: true },
    // { id: 4, name: "Job & Stage", field: "jobs", visible: true }, 
    { id: 4, name: "Company & Stage", field: "companyStage", visible: true },
    { id: 5, name: "Profile Progress", field: "profileProgress", visible: true },
    { id: 6, name: "Location", field: "veteranLocation", visible: true }, 
    { id: 7, name: "Last activity", field: "lastActivity", visible: true },
    { id: 8, name: "Phone", field: "phone", visible: true },
    { id: 9, name: "Email", field: "email", visible: true },
    { id: 10, name: "Date Added", field: "dateAdded", visible: true },
    { id: 11, name: "Affiliate", field: "affiliate", visible: true },
   { id: 12, name: "Hot Candidate", field: "hotCandidate", visible: true },
  ]);
  
  // Toggle sidebar
  const handleToggleSidebar = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  // Toggle column visibility 
  const toggleColumnVisibility = (id) => {
    setColumns(
      columns.map((col) =>
        col.id === id ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const onColumnReorder = (newColumns) => {
    setColumns(newColumns);
  };

// Filters handlers functions




  const resetTableData = () => {
    setFilteredData(candidateData); // Reset to original candidate data
  };

 
  const handleFilterClick = (filter) => {
    if (filter === "Manager") {
      setIsManagerModalOpen(true);
    }

    if (filter === "Text Search") {
      setIsTextSearchModalOpen(true);
    }

      if (filter === "Target Industries") {
      setTargetIndustriesModalOpen(true);
    }
    if (filter === "Candidate Type") {
      setIsCandidateTypeModalOpen(true);
    }

    if (filter === "Military Branch") {
      setMilitaryBranchModalOpen(true);
    }

    if (filter === "Job Search Status") {
      setIsJobStatusModalOpen(true);
    }

    if (filter === "Relocation Option") {
      setIsRelocationModalOpen(true);
    }

    if (filter === "Security Clearance") {
      setIsSecurityClearanceModalOpen(true);
    }

    if(filter === "Affiliate"){
      setIsAffiliateModalOpen(true);
    }

    if (filter === "Certifications") {
      setIsCertificationModalOpen(true);
    } 
    
    if(filter === "Hot Candidate"){
      setIsHotCandidateModalOpen(true);
    }
    
    else {
      console.log(`${filter} filter clicked!`);
    }
  };


useEffect(() => {
  const fetchApiCandidates = async () => {
    try {
      const response = await axios.get('/api/admin/candidates', {
        params: {
          page: currentPage,
          perPage: recordsPerPage
        },
        headers: {
          Authorization: `Bearer ${keycloak.token}`, // Token authorization
        },
      });

      console.log("API Candidate Data", response.data);
      setCandidates(response.data.candidates);
      setFilteredData(response.data.candidates);
      setTotalRecords(response.data.total);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching candidates:", error);

      // Redirect if error is 400, 403, or 500
      if (error.response) {
        const status = error.response.status;
        if ([400, 403, 500].includes(status)) {
          navigate("/error", { state: { errorCode: status } });
        }
      }
    }
  };
  fetchApiCandidates();
}, [currentPage, recordsPerPage]);

const fetchFilteredCandidates = useCallback(async (textsearchOverride,managerOverride,candidateTypeOverride, branchOverride, jobSearchStatusOverride, securityClearanceOverride, certificationOverride , industryOverride, relocationOverride, affiliateOverride, isHotOveride) => {
  try {
    const params = { _t: new Date().getTime() };
   
   
    if (searchTerm && searchTerm.trim() !== "") {
      params.search = searchTerm.trim();
    }

    
 
    let isManagerFilterApplied = false;
    let isCandidateTypeFilterApplied = false;
    let isBranchFilterApplied = false;
    let isJobSearchStatusFilterApplied = false;
    let isSecurityClearanceFilterApplied = false;
    let isCertificationFilterApplied = false;
    let isIndustryFilterApplied = false;
    let isRelocationFilterApplied = false;
    let isAffiliateFilterApplied = false;
    let isHotCandidateFilterApplied = false;
    let isTextSearchFilterApplied = false;


    if(industryOverride !== undefined && industryOverride !== null) {
      params.industry = industryOverride;
      isIndustryFilterApplied = true;
    } else if(selectedIndustries !== null && selectedIndustries !== undefined) {
      params.industry = selectedIndustries;
      isIndustryFilterApplied = true;
    }

    // Certification Filter Apply
    if (certificationOverride !== undefined && certificationOverride !== null) {
      params.certification = certificationOverride;
      isCertificationFilterApplied = true;
    } else if (selectedCertification !== null && selectedCertification !== undefined) {
      params.certification = selectedCertification;
      isCertificationFilterApplied = true;
    }


       // ✅ Job Search Status filter apply karein
       if (jobSearchStatusOverride !== undefined && jobSearchStatusOverride !== null) {
        params.jobSearchStatus = jobSearchStatusOverride;
        isJobSearchStatusFilterApplied = true;
      } else if (selectedStatus !== null && selectedStatus !== undefined) {
        params.jobSearchStatus = selectedStatus;
        isJobSearchStatusFilterApplied = true;
      }
      
    // ✅ Manager filter apply karein
    if (managerOverride !== undefined && managerOverride !== null) {
      params.manager = managerOverride;
      isManagerFilterApplied = true;
    } else if (selectedManager !== null && selectedManager !== undefined) {
      params.manager = selectedManager;
      isManagerFilterApplied = true;
    }

    // ✅ Candidate Type filter apply karein
    if (candidateTypeOverride !== undefined && candidateTypeOverride !== null) {
      params.type = candidateTypeOverride;
      isCandidateTypeFilterApplied = true;
    } else if (selectedCandidateType !== null && selectedCandidateType !== undefined) {
      params.type = selectedCandidateType;
      isCandidateTypeFilterApplied = true;
    }

    // Relocation Type Filter
    if (relocationOverride !== undefined && candidateTypeOverride !== null){
      params.relocationprefrence = relocationOverride;
      isRelocationFilterApplied = true;

    } else if (selectedRelocation !== null && selectedRelocation !== undefined){
      params.relocationprefrence = selectedRelocation;
      isRelocationFilterApplied = true;
    }

       // ✅ Security Clearance filter apply karein
       if (securityClearanceOverride !== undefined && securityClearanceOverride !== null) {
        params.securityClearance = securityClearanceOverride;
        isSecurityClearanceFilterApplied = true;
      } else if (selectedSecurityClearance !== null && selectedSecurityClearance !== undefined) {
        params.securityClearance = selectedSecurityClearance;
        isSecurityClearanceFilterApplied = true;
      }
   

    // ✅ Military Branch filter apply karein
    if (branchOverride !== undefined && branchOverride !== null) {
      params.branch = branchOverride;
     isBranchFilterApplied = true;
    
    } else if(selectedBranch !== null && selectedBranch !== undefined) {
      params.branch = selectedBranch;
      isBranchFilterApplied = true;
    }

    
    if (textsearchOverride !== undefined && textsearchOverride !== null) {
      params.textsearch = textsearchOverride.trim(); // Ensure clean input
      isTextSearchFilterApplied = true;
    } else if (selectedTextSearch !== null && selectedTextSearch !== undefined) {
      params.textSearch = selectedTextSearch.trim();
      isTextSearchFilterApplied = true;
    }

    // isHotCandidate 
    if (hotCandidateFilterType === "is set") {
      params.isHot = true;
      isHotCandidateFilterApplied = true;
    } else if (hotCandidateFilterType === "is not set") {
      params.isHot = false;
      isHotCandidateFilterApplied = true;
    }
    

    // Affiliate

    if (affiliateOverride !== undefined && affiliateOverride !== null) {
      params.affiliate = affiliateOverride;
     isAffiliateFilterApplied = true;
    
    } else if(selectedAffiliate !== null && selectedAffiliate !== undefined) {
      params.affiliate = selectedAffiliate;
      isAffiliateFilterApplied = true;
    }


    // ✅ Sirf relevant filter type bhejna
    if (isManagerFilterApplied) params.filterType = filterType;
    if (isCandidateTypeFilterApplied) params.typeFilterType = typeFilterType;
    if (isBranchFilterApplied) params.branchFilterType = branchFilterType;
    if(isJobSearchStatusFilterApplied) params.jobSearchfilterType = jobSearchfilterType;
    if(isSecurityClearanceFilterApplied) params.securityFilterType = securityFilterType;
    if (isCertificationFilterApplied) params.certificationFilterType = certificationFilterType;
    if (isIndustryFilterApplied) params.industryFilterType = industryFilterType;
    if (isRelocationFilterApplied) params.relocationFilterType = relocationFilterType;
    if (isAffiliateFilterApplied) params.affiliateFilterType = affiliateFilterType;
    if (isHotCandidateFilterApplied) params.hotCandidateFilterType = hotCandidateFilterType;


    console.log("Fetching with params:", params);

    const response = await axios.get(`/api/admin/candidates`, {
      params,
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "Cache-Control": "no-cache",
      },
    });

    console.log("API Response:", response.data);

    setFilteredData(response.data.candidates);

  } catch (error) {
    console.error("Error fetching candidates:", error);
  }
}, [searchTerm, selectedManager, selectedCandidateType, selectedBranch, filterType, typeFilterType, branchFilterType, selectedStatus, jobSearchfilterType, selectedSecurityClearance, securityFilterType, certificationFilterType, selectedCertification, selectedIndustries, industryFilterType, relocationFilterType, selectedRelocation, selectedAffiliate, affiliateFilterType, selectedHotCandidate, hotCandidateFilterType]);

// ✅ Page Load pe data bina debounce ke le lo
useEffect(() => {
  console.log("Fetching data on page load...");
  fetchFilteredCandidates();
}, [fetchFilteredCandidates]);  // Fix: Added fetchFilteredCandidates in dependencies

// ✅ Debounced search, manager & candidateType changes
useEffect(() => {
  const timeoutId = setTimeout(() => {
    fetchFilteredCandidates();
  }, 500); 

  return () => {
    clearTimeout(timeoutId);
  };
}, [searchTerm, selectedManager, selectedCandidateType, selectedBranch, filterType, typeFilterType, branchFilterType, selectedStatus, jobSearchfilterType, selectedRelocation, securityFilterType,selectedSecurityClearance, certificationFilterType, selectedCertification, fetchFilteredCandidates, selectedIndustries, industryFilterType, selectedAffiliate, affiliateFilterType, relocationFilterType, selectedRelocation,selectedHotCandidate, hotCandidateFilterType]);

// ✅ Manager select hone par state update & API call
const handleManagerSelect = (managerName) => {
  setSelectedManager(managerName);
};

// ✅ Candidate Type select hone par state update & API call
const handleCandidateTypeSelect = (candidateType) => {
  setSelectedCandidateType(candidateType);
};

// ✅ Military Branch select hone par state update & API call
const handleSelectBranch = (branch) => {
  setSelectedBranch(branch);
};

// Text Search Filter Handler
const handleTextSearch = (textSearch) => {
  setSelectedTextSearch(textSearch);
};


// JOB SEARCH STATUS FILTER HANDLER
  const handleSelectStatus = (jobstatus) => {
    setSelectedStatus(jobstatus);
  };

  const handleCertificationSelect = (certification) => {
    setSelectedCertification(certification);
  };


  const handleSecurityClearance = (securityClearance) => {
    setSelectedSecurityClearance(securityClearance);
 };


 const handleIndustrySelect = (industry) => {
  setSelectedIndustries(industry);
};


const handleSelectRelocation = (relocation) => {
setSelectedRelocation(relocation);
};


const handleAffiliateFilter = (affiliate)=>{
  setSelectedAffiliate(affiliate);
}

const handleHotCandidate = (isHot) =>{
  setSelectedHotCandidate(isHot);
}


  if (loading) {
    return (
      <div className="loading-container">
 <Logo style={{width:"150px"}}/>
        <div className="spinner"></div>
        <div>
          <h6>Please Wait..</h6>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <Header admin={admin} />
      <div className="row dashboardrow" style={{ minHeight: "100vh" }} >
        <div
          className={`col-lg-2 col-md-2 col-sm-4 col-4 sidebar ${
            isSidebarCollapsed ? "sidebarCollapsed" : ""
          }`}
        >
          <Sidebar onToggleSidebar={handleToggleSidebar} admin={admin} />
        </div>
        <div
          className={`col-lg-10 col-md-10 col-sm-8 col-8 sidebar-column contentbar ${
            isSidebarCollapsed ? "contentExpanded" : ""
          }`}
        >
          <main>
            <div className="main_heading_dashboard">
              <div className="left_content">
                <h4 style={{ color: "black" }}>Candidates</h4>
                <span>{filteredData.length} Candidates</span>
              </div>

              <div className="right_content">
                <Link to="/admin/create-new-candidates">
                  <button>+ Add New Candidate</button>
                </Link>
                {showAddCandidateForm && <AddCandidateForm />}
              </div>
            </div>

            <div className="search_filter_section">
              <div className="row">
                <div className="col-lg-7 col-md-12">
                  <div className="search_bar">
                    <CandidateSearch onSearch={setSearchTerm} />
                  </div>
                </div>

                <div className="col-lg-5 col-md-12">
                  <div className="select_options_filter">
                    <button
                      className="add_new_filter_company"
                      onClick={() => {
                        setIsFilterModalOpen(true);
                        setIsEditing(false); // Normal behavior
                      }}
                      
                    >
                
                   <FilterIcon style={{marginRight:"6px"}}/>
                      Add Filter
                    </button>

                    <button
                      className="edit_filter_company"
                      onClick={
                        () => {setIsFilterModalOpen(true);
                        setIsEditing(true); }// Normal behavior
                      }
                    >
                   
                      <EditIcon style={{marginRight:"6px"}}/>
                      Edit Filters (3)
                    </button>
                    <span style={{ fontSize: "12px", color: "#212529" }}>
                      {" "}
                      Sort By:
                    </span>
                    <select className="recently-added">
                      <option>Recently Added</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="edit_column_icon">
                <button onClick={() => setIsModalOpen(true)}>
                  {/* <img src={columnIcon} alt="columnIcon" /> */}
                  <ColumnEditIcon style={{    marginRight: "1px",
    width: "20px",
    height: "20px"}}/>
                  Edit Column
                </button>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="candidate_table_records">
                  <CandidateTable
  columns={columns || []}  
  data={filteredData.filter(item => item && typeof item === "object" && Array.isArray(item.jobs))}
  admin={admin}
  totalRecords={totalRecords}
  totalPages={totalPages}
  currentPage={currentPage}
  setCurrentPage={setCurrentPage}
  setRecordsPerPage={setRecordsPerPage}
  recordsPerPage={recordsPerPage}
/>


                  </div>
                </div>


{/* SideBar Filter For Candidates */}
                <div  className={`main-box-filter ${
                    isFilterModalOpen ? "show" : "hide"
                  }`} 
                  onClick={(e) => e.stopPropagation()}
                  >

                        <div className="mainbox-filter-header">
                                        <h6>
                                      <FilterIcon style={{marginRight:"10px"}}/>
                                          Candidate Filter
                                        </h6>
                    
                                        <div className="clearFilter">
                                  <FilterIcon style={{marginRight:"10px"}}/>
                    
                                          <h5 style={{fontSize:"13px !important"}}>Clear Filter</h5>
                                          <span
                                             onClick={(e) => {
                                              e.stopPropagation(); // Stop event bubbling
                                              setIsFilterModalOpen(false);
                                            }}
                                            style={{ cursor: "pointer" , marginRight:"12px", marginLeft:"13px"}}
                                          >
                                            X
                                          </span>
                                        </div>
                      </div>
                  
                <div className="filter-box-main">
                <AllFiltersModal
                      isFilterModalOpen={isFilterModalOpen}
                      setIsFilterModalOpen={setIsFilterModalOpen}
                      isManagerModalOpen={isManagerModalOpen}
                      setIsManagerModalOpen={setIsManagerModalOpen}
                      isTextSearchModalOpen={isTextSearchModalOpen}
                      setIsTextSearchModalOpen={setIsTextSearchModalOpen}
                      isCandidateTypeModalOpen={isCandidateTypeModalOpen}
                      setIsCandidateTypeModalOpen={setIsCandidateTypeModalOpen}
                      isMilitaryBranchModalOpen={isMilitaryBranchModalOpen}
                      setMilitaryBranchModalOpen={setMilitaryBranchModalOpen}
                      isJobStatusModalOpen={isJobStatusModalOpen}
                      setIsJobStatusModalOpen={setIsJobStatusModalOpen}
                      isRelocationModalOpen={isRelocationModalOpen}
                      setIsRelocationModalOpen={setIsRelocationModalOpen}
                      isSecurityClearanceModalOpen={isSecurityClearanceModalOpen}
                      setIsSecurityClearanceModalOpen={setIsSecurityClearanceModalOpen}
                      candidateData={candidateData}
                      handleFilterClick={handleFilterClick}
                      handleManagerSelect={handleManagerSelect}
                      resetTableData={resetTableData}
                      // handleSelectCandidate={handleSelectCandidate}
                      handleCandidateTypeSelect={handleCandidateTypeSelect}
                      handleSelectBranch={handleSelectBranch}
                      handleSelectStatus={handleSelectStatus}
                      handleSecurityClearance={handleSecurityClearance}
                      onDelete={fetchFilteredCandidates}
                      setFilteredData={setFilteredData}
                      filterType={filterType}
                      setFilterType={setFilterType}
                      fetchFilteredCandidates={fetchFilteredCandidates}
                      selectedCandidateType={selectedCandidateType}
                      setSelectedCandidateType={setSelectedCandidateType}
                      typeFilterType={typeFilterType}
                      setTypeFilterType={setTypeFilterType}
                      branchFilterType={branchFilterType}
                      setBranchFilterType={setBranchFilterType}
                      selectedBranch={selectedBranch}
                      setSelectedBranch={setSelectedBranch}
                      selectedManager={selectedManager}
                      setSelectedManager={setSelectedManager}
                      handleTextSearch={handleTextSearch}
                      selectedTextSearch={selectedTextSearch}
                      setSelectedTextSearch={setSelectedTextSearch}
                      textSearchFilterType={textSearchFilterType}
                      setTextSearchFilterType={setTextSearchFilterType}
                      selectedStatus={selectedStatus}
                      setSelectedStatus={setSelectedStatus}
                      jobSearchfilterType={jobSearchfilterType}
                      setJobSearchfilterType={setJobSearchfilterType}
                 securityFilterType={securityFilterType}
                 setSecurityFilterType={setSecurityFilterType}
                  selectedSecurityClearance={selectedSecurityClearance}
                 setSelectedSecurityClearance={setSelectedSecurityClearance}
                  isCertificationModalOpen={isCertificationModalOpen}
                  setIsCertificationModalOpen={setIsCertificationModalOpen}
                  selectedCertifications={selectedCertifications}
                  setSelectedCertifications={setSelectedCertifications}
                  certificationFilterType={certificationFilterType}
                  setCertificationFilterType={setCertificationFilterType}
                  handleCertificationSelect={handleCertificationSelect}   
                  isTargetIndustriesModalOpen={isTargetIndustriesModalOpen}
                  setTargetIndustriesModalOpen={setTargetIndustriesModalOpen}  
                  selectedIndustries={selectedIndustries} 
                  setSelectedIndustries={setSelectedIndustries}
                  industryFilterType={industryFilterType}
                  setIndustryFilterType={setIndustryFilterType}
                  handleIndustrySelect={handleIndustrySelect}
                 selectedRelocation={selectedRelocation}
                setSelectedRelocation={setSelectedRelocation}
                relocationFilterType={relocationFilterType}
                setRelocationFilterType={setRelocationFilterType}
                handleSelectRelocation={handleSelectRelocation}
                isEditing={isEditing}
                handleAffiliateFilter={handleAffiliateFilter}
                selectedAffiliate={selectedAffiliate}
                setSelectedAffiliate={setSelectedAffiliate}
                affiliateFilterType={affiliateFilterType}
                setAffiliateFilterType={setAffiliateFilterType}
                isAffiliateModalOpen={isAffiliateModalOpen}
                setIsAffiliateModalOpen={setIsAffiliateModalOpen}
               
                selectedHotCandidate={selectedHotCandidate}
                setSelectedHotCandidate={setSelectedHotCandidate}
                hotCandidateFilterType={hotCandidateFilterType}
                setHotCandidateFilterType={setHotCandidateFilterType}
                handleHotCandidate={handleHotCandidate}
                isHotCandidateModalOpen={isHotCandidateModalOpen}
                      />
                </div>
               
              </div>
            </div>
</div>
            {/* Button to open modal */}
            <DndProvider backend={HTML5Backend}>
            <ColumnEditModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              columns={columns}
              toggleColumnVisibility={toggleColumnVisibility}
              onColumnReorder={onColumnReorder}
            />
            </DndProvider>
          </main>
        </div>
      </div>
    </div>
  );
};

export default AdminCandidateDashboard;
