import React from 'react';
import { ReactComponent as Logo } from "../../assets/images/logo-7eagle.svg";
import { ReactComponent as Icon404 } from "../../assets/images/404.svg";

import useAdmin from './useAdmin';  // Import the custom hook
import Header from '../../components/Common/Header/Header';
import './PageError.css';
function PageError404() {
  const { admin, loading } = useAdmin();

  if (loading) {
      return (
        <div className="loading-container">
          <Logo style={{width:"150px"}}/>
          <div className="spinner"></div>
          <div>
            <h6>Please Wait..</h6>
          </div>
        </div>
      );
  }

  return (
    <div>
        <Header admin={admin} />

        <div className="error-page">
        <div className="error-page-content">
          <div className="icon-error">
            <Icon404 />
          </div>
          <div>
            <p className="sub-error">Error 404: Page Not Found</p>
            <h2>Oops! Looks like you’re lost </h2>
            <p>
              The page you’re looking for isn’t here. It might have moved or
              doesn’t exist.
            </p>
            <div className="back-to-home">
              <a href="/admin/dashboard">Back to Dashboard</a>
            </div>

            <div className="error-page-footer">
              <p>
                {" "}
                Still having trouble? <a href="">Contact Support</a>
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default PageError404;
