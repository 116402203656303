import React, { useState, useEffect , useContext} from "react";

import { Link , useNavigate} from "react-router-dom";
import axios from "axios";
import { ReactComponent as Logo } from "../assets/images/logo-7eagle.svg";
import { ReactComponent as FilterIcon } from "../assets/images/FilterIcon.svg";
import { ReactComponent as FilterWhiteIcon } from "../assets/images/filterWhite.svg";
import { AuthContext } from '../context/AuthContext';
import { ReactComponent as GridIcon } from "../assets/images/icon_grid.svg";
import { ReactComponent as ListIcon } from "../assets/images/icon_list.svg";
import Header from "../components/Common/Header/Header";
import Sidebar from "../components/Common/Sidebar/Sidebar";
import "./AdminDashboard.css";
import AssignedCandidateBox from "../components/Candidates/Assigned Candidate Box/AssignedCandidateBox";
import AssignedCompanyBox from "../components/Candidates/Assigned Companies Box/AssignedCompanyBox";
import AssignedJobBox from "../components/Candidates/Assigned Jobs Box/AssignedJobBox";
import AssignedHiredBox from "../components/Candidates/Assigned Hired Box/AssignedHiredBox";
import AddCandidateForm from "../components/Candidates/Add New Candidates/AddNewCandidateForm";
import CandidateTable from "../components/Candidates/Candidate Records Table/CandidateTable";
import CandidateSearch from "../components/Candidates/Search Filters/CandidateSearch";
import { ReactComponent as KanbanIcon } from "../assets/images/icon-kanban.svg";

import ColumnEditModal from "../components/Candidates/Candidate Records Table/ColumnEditModal";
import CompanySearch from "../components/Companies/SearchFilters/CompanySearchFilter";

import CompanyGridList from "../components/Companies/Company List/CompanyGridList";
import ComapnyListTable from "../components/Companies/Company List/CompanyListTable";




import TokenDecode from "../context/DecodeJWT";
import CompanyKanbanView from "../components/Companies/Company List/CompanyKanbanViewList";
import CandidateGridList from "../components/Candidates/Candidate Records Table/CandidateList/CandidateGridList";
import CandidateKanbanView from "../components/Candidates/Candidate Records Table/CandidateKanbanViewList/CandidateKanbanViewList";
const AdminDashboardPage = () => {
    const decodedToken = TokenDecode();
      const { keycloak } = useContext(AuthContext);
  const navigate = useNavigate();


  const [isGridView, setIsGridView] = useState(false);
const [isKanbanView, setIsKanbanView] = useState(false);
  const [activeView, setActiveView] = useState("tablelist");

  const [candidateActiveView, setCandidateActiveView] = useState("tablelist");

  const [isCandidateGridView, setIsCandidateGridView] = useState(false);
const [isCandidateKanbanView, setIsCandidateKanbanView] = useState(false);
  // Function to toggle to Grid View

  
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [admin, setAdmin] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchCandidateTerm, setSearchCandidateTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [candidateData, setCandidateData] = useState([]); // Initialize candidate data as empty array
  const [filteredData, setFilteredData] = useState([]);

  const [candidatefilteredData, setCandidateFilteredData] = useState([]);

  const [filterType, setFilterType] = useState("is one of"); // Define filterType in parent
  const [companyData, setcompanyData] = useState([]); // Initialize candidate data as empty array

const [candidates, setCandidates] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage, setRecordsPerPage] = useState(10);
const [totalRecords, setTotalRecords] = useState(0);
const [totalPages, setTotalPages] = useState(1);



  const handleToggleSidebar = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };


  // Company Function to toggle to Grid View
const showGridView = () => {
  setActiveView("grid");
  setIsGridView(true); // Activate Grid View
  setIsKanbanView(false); // Deactivate Kanban View
};

// Function to toggle to Kanban View
const showKanbanView = () => {
  setActiveView("kanban");
  setIsKanbanView(true); // Activate Kanban View
  setIsGridView(false); // Deactivate Grid View
};

// Function to toggle to Table View
const showTableView = () => {
  setActiveView("tablelist");
  setIsGridView(false); // Deactivate Grid View
  setIsKanbanView(false); // Deactivate Kanban View
};

// Candidate View Icons.

const showCandidateGridView = () => {
  setCandidateActiveView("grid");
  setIsCandidateGridView(true); // Activate Grid View
  setIsCandidateKanbanView(false); // Deactivate Kanban View
};

// Function to toggle to Kanban View
const showCandidateKanbanView = () => {
  setCandidateActiveView("kanban");
  setIsCandidateKanbanView(true); // Activate Kanban View
  setIsCandidateGridView(false); // Deactivate Grid View
};

// Function to toggle to Table View
const showCandidateTableView = () => {
  setCandidateActiveView("tablelist");
  setIsCandidateGridView(false); // Deactivate Grid View
  setIsCandidateKanbanView(false); // Deactivate Kanban View
};
  const [columns, setColumns] = useState([

    { id: 1, name: "Name", field: "name", visible: true }, 
    { id: 2, name: "Candidate Type", field: "type", visible: true }, 
    { id: 3, name: "Manager", field: "managers", visible: true },
    { id: 4, name: "Job & Stage", field: "jobs", visible: true }, 
    { id: 5, name: "Profile Progress", field: "profileProgress", visible: true },
    { id: 6, name: "Location", field: "veteranLocation", visible: true }, 
    { id: 7, name: "Last activity", field: "lastActivity", visible: true },
    { id: 8, name: "Phone", field: "phone", visible: true },
    { id: 9, name: "Email", field: "email", visible: true },
    { id: 10, name: "Date Added", field: "dateAdded", visible: true },
    { id: 11, name: "Affiliate", field: "affiliate", visible: true },
    { id: 12, name: "Company & Stage", field: "companyStage", visible: true },
    { id: 13, name: "Hot Candidate", field: "hotCandidate", visible: true },

  ]);

  //   Company Column

  const [Companycolumns, CompanysetColumns] = useState([
    { id: 1, name: "Company Name", field: "name", visible: true },
    { id: 2, name: "Currunt Plan", field: "userType", visible: true },
    { id: 3, name: "Manager", field: "manager", visible: true },
    { id: 4, name: "Refered By", field: "referredBy", visible: true }, // `type` field mapped here
    { id: 5, name: "Recent Activity", field: "recentActivity", visible: true },
    { id: 6, name: "Applicants", field: "applicants", visible: true },
    {
      id: 7,
      name: "Hired Candidates",
      field: "hiredCandidates",
      visible: true,
    },
    {
      id: 8,
      name: "Interested Candidates",
      field: "interestedCandidate",
      visible: true,
    },

    { id: 9, name: "Interview", field: "interview", visible: true },
    { id: 10, name: "Sent Offer", field: "sentOffer", visible: true },
    {
      id: 11,
      name: "Submitted Candidates",
      field: "submittedCandidates",
      visible: true,
    },

    {
      id: 12,
      name: "Interested Candidates",
      field: "interestedcandidates",
      visible: true,
    },
    {
      id: 13,
      name: "Favorite Candidates",
      field: "favoriteCandidates",
      visible: true,
    },
    { id: 14, name: "Affiliate", field: "affiliate", visible: true },

    { id: 15, name: "Hot Candidate", field: "hotCandidate", visible: true },
  ]);


  const toggleColumnVisibility = (id) => {
    setColumns(
      columns.map((col) =>
        col.id === id ? { ...col, visible: !col.visible } : col
      )
    );
  };



  const onColumnReorder = (newColumns) => {
    setColumns(newColumns);
  };

  // Fetch candidate data from API
  // useEffect(() => {
  //   const fetchCandidateData = async () => {

  
  //     try {
  //       const response = await axios.get('/api/admin/candidates', {
  //         headers: {
  //           Authorization: `Bearer ${keycloak.token}`,
  //         },
  //       });
  //       console.log("Candidate Response", response.data);
  //       setCandidateData(response.data); // Set fetched data to candidateData
  //       setCandidateFilteredData(response.data); // Initialize filteredData with the fetched data
  //     } catch (error) {
  //       console.error("Error fetching candidate data:", error);
  //       // Redirect if error is 400, 403, or 500
  //     const status = error.response?.status;
  //     if ([400, 403, 500].includes(status)) {
  //       navigate("/error", { state: { errorCode: status } });
  //     }
  //     }
  //   };

  //   fetchCandidateData();
  // }, []);

  useEffect(() => {
    const fetchApiCandidates = async () => {
      try {
        const response = await axios.get('/api/admin/candidates', {
          params: {
            page: currentPage,
            perPage: recordsPerPage
          },
          headers: {
            Authorization: `Bearer ${keycloak.token}`, // Token authorization
          },
        });
  
        console.log("API Candidate Data", response.data);
        setCandidates(response.data.candidates);
        setCandidateFilteredData(response.data.candidates);
        setTotalRecords(response.data.total);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching candidates:", error);
  
        // Redirect if error is 400, 403, or 500
        if (error.response) {
          const status = error.response.status;
          if ([400, 403, 500].includes(status)) {
            navigate("/error", { state: { errorCode: status } });
          }
        }
      }
    };
  
    fetchApiCandidates();
  }, [currentPage, recordsPerPage]);
  

  // Filter candidate data based on search term
  useEffect(() => {
    const lowerCaseSearchTerm = searchCandidateTerm.toLowerCase();
    const filtered = candidateData.filter((candidate) =>
      Object.values(candidate).some(
        (value) => String(value).toLowerCase().includes(lowerCaseSearchTerm) // Safely convert to string
      )
    );
    setCandidateFilteredData(filtered);
  }, [searchCandidateTerm, candidateData]);

  // Fetch company data from API
  // useEffect(() => {
  //   const fetchcompanyData = async () => {
  //     try {
  //       const response = await axios.get(`/api/admin/clients`, {
  //         headers: {
  //           Authorization: `Bearer ${keycloak.token}`,
  //         },
  //       });
  //       setcompanyData(response.data); // Set fetched data to companyData
  //       setFilteredData(response.data); // Initialize filteredData with the fetched data
  //     } catch (error) {
  //       console.error("Error fetching candidate data:", error);

  //       // Redirect if error is 400, 403, or 500
  //     // const status = error.response?.status;
  //     // if ([400, 403, 500].includes(status)) {
  //     //   navigate("/error", { state: { errorCode: status } });
  //     // }
  //     }
  //   };

  //   fetchcompanyData();
  // }, []);


  // Fetch Dummy Data for Company

   useEffect(() => {
      const fetchCompanyData = async () => {
        try {
     
          const dummyData = [
            {
              name: "Tech Solutions",
              userType: "Basic Plan",
              manager: "John Doe",
              referredBy: "Sarah Connor",
              recentActivity: "September 23, 2024",
              applicants: [
                { id: 1, name: "Alice", status: "2w ago" },
                { id: 2, name: "Bob", status: "3w ago" },
                { id: 3, name: "Charlie", status: "9w ago" }
              ],
              hiredCandidates: [
                { id: 1, name: "Alice", status: "2w ago" },
                { id: 2, name: "Bob", status: "2w ago" }
              ],
              interestedCandidate: [
                { id: 1, name: "David", status: "2w ago" },
                { id: 2, name: "Eva", status: "2w ago" }
              ],
              interview: [
                { id: 1, name: "Frank", status: "2w ago" }
              ],
              sentOffer: [
                { id: 1, name: "Grace", status: "2w ago" }
              ],
              submittedCandidates: [
                { id: 1, name: "Hannah", status: "2w ago" }
              ],
              interestedcandidates: [
                { id: 1, name: "Isaac",status: "2w ago" }
              ],
              favoriteCandidates: [
                { id: 1, name: "Jack", status: "2w ago" },
                { id: 2, name: "Karen", status: "2w ago" }
              ],
              affiliate: "Affiliate A",
              hotCandidate: [
                { id: 1, name: "Nina", status: "2w ago" },
                { id: 2, name: "Oscar", status: "2w ago" }
              ]
            },
            {
              name: "Innovative Labs",
              userType: "Enterprise Plan",
              manager: "Jane Smith",
              referredBy: "Michael Scott",
              recentActivity: "September 23, 2024",
              applicants: [
                { id: 1, name: "Paul", status: "2w ago" },
                { id: 2, name: "Olivia", status: "2w ago" }
              ],
              hiredCandidates: [
                { id: 1, name: "Paul", status: "2w ago"}
              ],
              interestedCandidate: [
                { id: 1, name: "Nina", status: "2w ago" }
              ],
              interview: [
                { id: 1, name: "Peter", status: "2w ago"}
              ],
              sentOffer: [
                { id: 1, name: "Quincy",status: "2w ago"}
              ],
              submittedCandidates: [
                { id: 1, name: "Ruby", status: "2w ago"}
              ],
              interestedcandidates: [
                { id: 1, name: "Sam", status: "2w ago" }
              ],
              favoriteCandidates: [
                { id: 1, name: "Tina", status: "2w ago" }
              ],
              affiliate: "Affiliate B",
              hotCandidate: [
                { id: 1, name: "Ursula",status: "2w ago" }
              ]
            },
            {
              name: "Future Tech",
              userType: "All-Access Plan",
              manager: "Alice Johnson",
              referredBy: "Bruce Wayne",
              recentActivity: "1 week ago",
              applicants: [
                { id: 1, name: "George", status: "2w ago" },
                { id: 2, name: "Ivy", status: "2w ago" },
                { id: 3, name: "James", status: "2w ago" }
              ],
              hiredCandidates: [
                { id: 1, name: "George", status: "2w ago" },
                { id: 2, name: "Ivy", status: "2w ago" }
              ],
              interestedCandidate: [
                { id: 1, name: "Jack", status: "2w ago" }
              ],
              interview: [
                { id: 1, name: "Kennedy", status: "2w ago" }
              ],
              sentOffer: [
                { id: 1, name: "Lena", status: "2w ago" }
              ],
              submittedCandidates: [
                { id: 1, name: "Mike", status: "2w ago" }
              ],
              interestedcandidates: [
                { id: 1, name: "Nancy", status: "2w ago"}
              ],
              favoriteCandidates: [
                { id: 1, name: "Olivia", status: "2w ago" },
                { id: 2, name: "Peter", status: "2w ago" }
              ],
              affiliate: "Affiliate C",
              hotCandidate: [
                { id: 1, name: "Quincy", status: "2w ago" },
                { id: 2, name: "Rachel",status: "2w ago"}
              ]
            }
          ];
          
          // Set dummy data for testing
          setcompanyData(dummyData);
          setFilteredData(dummyData);
        } catch (error) {
          console.error("Error fetching company data:", error);
        }
      };
    
      fetchCompanyData();
    }, []);

  // Filter candidate data based on search term
  useEffect(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filtered = companyData.filter((candidate) =>
      Object.values(candidate).some(
        (value) => String(value).toLowerCase().includes(lowerCaseSearchTerm) // Safely convert to string
      )
    );
    setFilteredData(filtered);
  }, [searchTerm, companyData]);

  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        setLoading(true);
        const userEmail = decodedToken?.email;
     
      if (userEmail) {
          const response = await axios.get(`/api/admin/?email=${userEmail}`);
          setAdmin(response.data);
          console.log(response.data.email
          );
        }
      } catch (error) {
        console.log(
          "Error fetching Admin:",
          error.response ? error.response.data : error.message
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAdmin();
  }, [decodedToken]);

  if (loading) {
    return (
      <div className="loading-container">
            <Logo/>
        <div className="spinner"></div>
        <div>
          <h6>Please Wait..</h6>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="loading-container">
      <Logo style={{width:"150px"}}/>
        <div className="spinner"></div>
        <div>
          <h6>Please Wait..</h6>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard">
    <Header admin={admin} />
    <div className="row dashboardrow" style={{ minHeight: "100vh" }}>
      <div
        className={`col-lg-2 col-md-2 col-sm-4 col-4 sidebar ${
          isSidebarCollapsed ? "sidebarCollapsed" : ""
        }`}
      >
        <Sidebar onToggleSidebar={handleToggleSidebar} admin={admin} />
      </div>
      <div
        className={`col-lg-10 col-md-10 col-sm-8 col-8 sidebar-column contentbar ${
          isSidebarCollapsed ? "contentExpanded" : ""
        }`}
      >
        <main>
          <div className="main_heading_dashboardd">
            <div className="content">
              <h4 style={{ color: "black" }}>My Dashboard</h4>

              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="dashboard_count_boxs">
                    <AssignedCandidateBox candidateData={candidateData} />
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="dashboard_count_boxs">
                    <AssignedCompanyBox companyData={companyData} />
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="dashboard_count_boxs">
                    <AssignedJobBox />
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="dashboard_count_boxs">
                    <AssignedHiredBox />
                  </div>
                </div>
              </div>

              <main className="candidate_main_box">
                <div className="main_heading_dashboard">
                  <div className="left_content">
                    <h4 style={{ color: "black" }}>My Candidates</h4>
                    <span>{candidatefilteredData.length} Candidates</span>
                  </div>
                </div>

                <div className="search_filter_section">
                  <div className="row">
                    <div className="col-lg-7 col-md-12">
                      <div className="search_bar">
                        <CandidateSearch onSearch={setSearchCandidateTerm} />

                        <div className="toggle-btns dashboard_toggles">
                           <button onClick={showCandidateTableView} className={candidateActiveView === "tablelist" ? "active" : ""}>
                            <ListIcon/>
                            </button>
                            <button onClick={showCandidateGridView} className={candidateActiveView === "grid" ? "active" : ""}>
                            <GridIcon/>
                            </button>
                             <button onClick={showCandidateKanbanView} className={candidateActiveView === "kanban" ? "active" : ""}>
                            <KanbanIcon/>
                            </button>
    </div>
                      </div>
                    </div>

                    <div className="col-lg-5 col-md-12">
                      <div className="select_options_filter">
                
                        <span style={{ fontSize: "12px", color: "#212529" }}>
                          {" "}
                          Sort By:
                        </span>
                        <select className="recently-added">
                          <option>Recently Added</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{marginTop:"20px"}}>
                    <div className="col-lg-12 col-md-12">
          

                      <div className="candidate_table_records">
                  {isCandidateKanbanView ? (
  <CandidateKanbanView data={candidatefilteredData} />
) : isCandidateGridView ? (
  <CandidateGridList columns={columns} data={candidatefilteredData} admin={admin} />
) : (
  <CandidateTable
  columns={columns}
  data={candidatefilteredData}
  admin={admin}
  totalRecords={totalRecords}
  totalPages={totalPages}
  currentPage={currentPage}
  setCurrentPage={setCurrentPage}
  setRecordsPerPage={setRecordsPerPage}
  recordsPerPage={recordsPerPage}
/>

)}

 </div>
                    </div>

                  </div>
                </div>

                {/* Button to open modal */}

                <ColumnEditModal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  columns={columns}
                  toggleColumnVisibility={toggleColumnVisibility}
                  onColumnReorder={onColumnReorder}
                />
              </main>

              <main className="company_main_box">
                <div className="main_heading_dashboard">
                  <div className="left_content">
                    <h4 style={{ color: "black" }}>My Companies</h4>
                    <span>{filteredData.length} Companies</span>
                  </div>
                </div>

                <div className="search_filter_section">
                  <div className="row">
                    <div className="col-lg-7 col-md-12">
                      <div className="search_bar">
                        <CompanySearch onSearch={setSearchTerm} />

                        <div className="toggle-btns dashboard_toggles">
          
                              <button onClick={showTableView} className={activeView === "tablelist" ? "active" : ""}>
                                   <ListIcon/>
                              </button>
                               <button onClick={showGridView} className={activeView === "grid" ? "active" : ""}>
                                                <GridIcon/>
                               </button>
                          
                               <button onClick={showKanbanView} className={activeView === "kanban" ? "active" : ""}>
                                                <KanbanIcon/>
                                 </button>
                        </div>

                      </div>
                    </div>

                    <div className="col-lg-5 col-md-12">
                      <div className="select_options_filter">
                 
                        <span style={{ fontSize: "12px", color: "#212529" }}>
                          {" "}
                          Sort By:
                        </span>
                        <select className="recently-added">
                          <option>Recently Added</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      {/* <div className="candidate_table_records">
                        {isGridView ? (
                          <CompanyGridList
                            columns={columns}
                            data={filteredData}
                            admin={admin}
                          />
                        ) : (
                          <ComapnyListTable
                            columns={Companycolumns}
                            data={filteredData}
                            admin={admin}
                          />
                        )}
                      </div> */}

<div className="candidate_table_records">
                  {isKanbanView ? (
  <CompanyKanbanView data={filteredData} />
) : isGridView ? (
  <CompanyGridList columns={Companycolumns} data={filteredData} admin={admin} />
) : (
  <ComapnyListTable columns={Companycolumns} data={filteredData} admin={admin} />
  // <h2>Company List Table</h2>
)}

 </div>
                    </div>

      
                  </div>
                </div>

                {/* Button to open modal */}

                <ColumnEditModal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  columns={columns}
                  toggleColumnVisibility={toggleColumnVisibility}
                  onColumnReorder={onColumnReorder}
                />
              </main>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
  );
};

export default AdminDashboardPage;
