import React, { useState, useRef, useContext } from "react";

import "./UpdateBasicInformationPopup.css";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { toast, ToastContainer } from "react-toastify";
import { statesWithCities } from "./LocationList";
import InputMask from "react-input-mask"; 
import CompanyProfileBox from "../Profile/CompanyProfileBox";
// import CompanyProfileBox from "../Profile/CompanyProfileBox";

function UpdateBasicInformation({ isOpen, onUpdate, onClose, company }) {
  const [formData, setFormData] = useState({
    ...company,
    name: company.name || "",
  
    domainName: company.domainName || "",
   
    state: company.state || "",
    city: company.city || "",
   
  });

  const [errors, setErrors] = useState({});
  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (value) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.name) newErrors.name = "This field can not be empty";
    if (!formData.domainName) newErrors.domainName = "This field can not be empty";
    if (!formData.idno) newErrors.idno = "This field can not be empty";
 

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/company/${company._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update candidate");
      }

      const updatedCandidate = await response.json();
      toast.success("Candidate updated successfully!");

      if (onUpdate) {
        onUpdate(formData); // Send updated candidate to the parent component
      }

      setTimeout(() => {
        if (onClose) {
          onClose(); // This will close the form by changing the state in the parent
        }
      }, 2000); // Close the form after the update
    } catch (error) {
      toast.error("Error updating candidate: " + error.message);
    }
  };

  const inputref = useRef(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const handleOnPlacesChanged = () => {
    let places = inputref.current.getPlaces();

    if (places && places.length > 0) {
      const addressComponents = places[0].address_components;

      if (addressComponents) {
        let cityName = "";
        let stateName = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            stateName = component.long_name;
          }
        });

        // Update the formData state
        setFormData((prevData) => ({
          ...prevData,
          city: cityName,
          state: stateName,
        }));
      } else {
        console.error("No address components found.");
      }
    } else {
      console.error("No places found.");
    }
  };

  return (
    <>
      <ToastContainer />

      <div className="popup-content updateprofileform">
        <h6>Company Information</h6>

        <CompanyProfileBox company={company}/>
        {/* <CandidateProfileBox company={company} candidate={company}/> */}
        <form className="popup-form" onSubmit={handleSubmit}>
          <div className="two-fields">
            <div className="form-field">
              <label>
                Company Name <span>*</span>
              </label>
              <input
                type="text"
                placeholder="Type Here"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={!formData.name ? "error-border" : ""} // Adds class if empty
              />

              {errors.name && (
                <span className="error-message-field">{errors.name}</span>
              )}
            </div>

          </div>



          <div className="form-field">
            {isLoaded && (
              <div>
                <label>
                  Location <span>*</span>
                </label>
                <StandaloneSearchBox
                  onLoad={(ref) => (inputref.current = ref)}
                  onPlacesChanged={handleOnPlacesChanged}
                  style={{
                    zIndex: "10000",
                    position: "absolute",
                    top: "0px",
                    width: "100%",
                  }} // Higher z-index for the search box
                >
                  <input
                    type="text"
                    placeholder="Search location"
                    className={!formData.city ? "error-border" : ""}
                    style={{
                      width: "100%",
                      padding: "14px",
                      borderRadius: "10px",
                      border: "1px solid #ddddde",
                    }}
                  />
                </StandaloneSearchBox>
                {errors.city && (
                  <span className="error-message-field">{errors.city}</span>
                )}
              </div>
            )}
          </div>

          <div className="two-fields">
            <div className="form-field">
              <label>
                Domain Link <span>*</span>
              </label>
              <input
                type="text"
                placeholder="www.7eagle.com"
                name="domainName"
                onChange={handleChange}
                value={formData.domainName}
                // readOnly
                style={{ backgroundColor: "#f6f6f6" }}
                // Adds class if empty
              />
            </div>
          </div>

          <button type="submit" className="btn-save-changes">
            Save
          </button>
        </form>
      </div>
    </>
  );
}

export default UpdateBasicInformation;
