import React, { useState } from "react";
import "./CreateInvoice.css"; // CSS file import karenge

const CreateInvoice = ({ onCLoseInvoice }) => {
  const [formData, setFormData] = useState({
    sendTo: "",
    name: "",
    email: "",
    phone: "",
    invoiceNumber: "",
    amount: "",
    clientManager: "",
    recruitingManager: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Invoice Data: ", formData);
    alert("Invoice Sent!");
  };

  return (
    <div className="form-container">
      <div className="form_header">
        <h1 className="form-title">Create Invoice</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-fields-group">
          <div className="form-group">
            <label className="form-label">Send Invoice To</label>
            <select
              name="sendTo"
              value={formData.sendTo}
              onChange={handleChange}
              className="form-input"
            >
              <option value="">Select</option>
              <option value="HumanIT">HumanIT</option>
              <option value="Another Company">Another Company</option>
            </select>
          </div>
          {["Name", "Email", "Phone", "InvoiceNumber", "Amount"].map(
            (field) => (
              <div className="form-group" key={field}>
                <label className="form-label">
                  {field.replace(/([A-Z])/g, " $1")}
                </label>
                <input
                  type="text"
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="Type Here"
                />
              </div>
            )
          )}
          {["ClientManager", "RecruitingManager"].map((field) => (
            <div className="form-group" key={field}>
              <label className="form-label">
                {field.replace(/([A-Z])/g, " $1")}
              </label>
              <select
                name={field}
                value={formData[field]}
                onChange={handleChange}
                className="form-input"
              >
                <option value="">Select</option>
                <option value="Cyber Security Analyst">
                  Cyber Security Analyst
                </option>
              </select>
            </div>
          ))}
          <div className="form-group">
            <label className="form-label">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="form-input"
              rows="4"
              placeholder="Type Here"
            ></textarea>
          </div>
        </div>
        <div className="form-actions-footer">
          <button onClick={onCLoseInvoice}>Cancel</button>
          <button type="submit" className="form-button">
            Send Invoice
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateInvoice;
