import React from 'react'
import { ReactComponent as GoogleIcon } from "../../../../assets/images/googleLogo.svg";
import { ReactComponent as TrueIcon } from "../../../../assets/images/trueIcon.svg";


function EmailPermission() {
  return (
    <div className="email_card">
    <h3>Email Permissions</h3>
    <p>Connect your Gmail account to be able to send emails.</p>
    <button className="connect_google">
     <GoogleIcon/>
      Connect with Google
    </button>
    <div className="gsuite_connected">
      <span className="checkmark"><TrueIcon style={{width:"60px", height:"60px"}}/></span> Your GSuite Account is connected.
    </div>
  </div>
  )
}

export default EmailPermission