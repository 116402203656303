import React, { useState } from "react";
import EditJobTitleItem from "./EditJobTitleItem"; // Child Component
import AddJobTitlePopup from "./AddJobTitlePopup"; // Popup Component
import "./EditJobTitleChoice.css";

const EditJobTitleChoices = () => {
  const [items, setItems] = useState([
    "Network Engineer",
    "Cyber Security Analyst",
    "Mechanical Engineer",
    "Security Operations Center (SOC) Analyst",
    "Incident Response Analyst",
    "Security Compliance Analyst",
  ]);
  const [searchQuery, setSearchQuery] = useState(""); // Search Query State
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Popup State

  const handleUpdate = (index, newTitle) => {
    const updatedItems = [...items];
    updatedItems[index] = newTitle;
    setItems(updatedItems);
  };

  // 🔹 Add new job titles from popup
  const handleAddNewTitles = (newTitles) => {
    setItems([...items, ...newTitles]); // Append new titles
  };

  // 🔹 Filter items based on search query
  const filteredItems = items.filter((item) =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container">
          <div className="header_profile" style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                  <div className="title_choice">
                  <h2 className="dropdown-title">Edit Job Title Choices</h2>
                  </div>
                  <div>
                  <button className="add-button" onClick={() => setIsPopupOpen(true)}>Add Choice +</button>
                  </div>
             
            </div>
  <div className="list_input">
  <input
        type="text"
        className="search-input"
        placeholder="Enter any keyword"
        value={searchQuery} // 🔹 Search input value
        onChange={(e) => setSearchQuery(e.target.value)} // 🔹 Update state on change
      />


      {/* 🔹 Job Titles List */}
      <ul className="dropdown-list edit_profile_ul">
        {filteredItems.map((item, index) => (
          <EditJobTitleItem
            key={index}
            index={index}
            title={item}
            onUpdate={handleUpdate} // Function to update title
          />
        ))}
      </ul>
  </div>

      {/* 🔹 Add Job Title Popup */}
      {isPopupOpen && (
        <AddJobTitlePopup
          onClose={() => setIsPopupOpen(false)}
          onAdd={handleAddNewTitles} // Function to add new job titles
        />
      )}
    </div>
  );
};

export default EditJobTitleChoices;
