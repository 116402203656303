import React, { useState } from "react";
// import "./ProfileImageUpload.css";
import "./AddNewCandidatePhoto.css";
import { ReactComponent as UploadIcon } from "../../../assets/images/uploadIcon.svg";
  

import fileImg from "../../../assets/images/file.png";

function AddNewCandidatePhoto({ candidate, onClose, onImageSelect }) {
  const [image, setImage] = useState(null); // State for the image
  const [error, setError] = useState(""); // State for error handling

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setError("");
      setImage(file); // Store the image file in the state
      onImageSelect(file); // Pass image to the parent component immediately
    } else {
      setError(
        "You can’t upload this file. Only .jpg and .png formats are allowed."
      );
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setError("");
      setImage(file); // Store the image file in the state
      onImageSelect(file); // Pass image to the parent component immediately
    } else {
      setError(
        "You can’t upload this file. Only .jpg and .png formats are allowed."
      );
    }
  };

  return (
    <>
      <h6 style={{ fontSize: "14px" }}>Candidate Information</h6>

      <p
        style={{
          fontSize: "14px",
          fontWeight: "600",
          marginBottom: "5px",
          marginTop: "15px",
        }}
      >
        Profile Photo
      </p>
      <div className="row" style={{ alignItems: "center" }}>
        <div className="col-lg-6">
          <div
            className="upload-box upload_new_candidate_profile"
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            {image ? (
              <div className="image-preview">
                <img src={URL.createObjectURL(image)} alt="Preview" />
              </div>
            ) : (
              <>

    <UploadIcon/>
                <p
                  style={{
                    padding: "10px",
                    fontWeight: "600",
                    fontSize: "16px",
                    paddingBottom: "0px",
                    margin: "0px",
                  }}
                >
                  Drag & drop files or{" "}
                  <label
                    htmlFor="file-upload"
                    className="browse-link"
                    style={{ cursor: "pointer " }}
                  >
                    <b> Browse</b>
                  </label>
                </p>
                <p style={{ fontSize: "12px" }}>
                  Supported formats: .jpg, .png
                </p>
              </>
            )}
            <input
              type="file"
              id="file-upload"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
        </div>

        <div className="col-lg-6"></div>
      </div>

      {error && <p className="error-message">{error}</p>}
    </>
  );
}

export default AddNewCandidatePhoto;