import React, { useState, useEffect, useRef } from "react";
import "./SecurityClearanceFilter.css";
import { ReactComponent as IconUser } from "../../../assets/images/icon-job-fill.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";

const SecurityClearanceFilter = ({
  isOpen,
  onClose,
  candidateData,
  onSelectSecurityClearance,
  securityFilterType,
  setSecurityFilterType,
  selectedSecurityClearance,
  setSelectedSecurityClearance,
  onDelete,
  setFilteredData,
  fetchFilteredCandidates
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const handleDone = () => {
    if (searchTerm && searchTerm.length >= 2) {
      setSelectedSecurityClearance(searchTerm);
      onSelectSecurityClearance(searchTerm);
      setIsConfirmed(true);
    }
  };

  const handleEdit = () => {
    setIsConfirmed(false);
  };

  useEffect(() => {
    if (!selectedSecurityClearance) {
      setFilteredData([]);
    }
  }, [selectedSecurityClearance]);

  const handleDelete = () => {
    setSelectedSecurityClearance(null);
    setSearchTerm("");
    setIsConfirmed(false);
    onDelete(null);
  };

  const handleFilterTypeChange = (event) => {
    setSecurityFilterType(event.target.value);
  
  };

  if (!isOpen) return null;

  return (
    <div className="security-clearance-modal-main">
      <div className="security-clearance-modal-content" ref={modalRef}>
        {isConfirmed ? (
          <div className="confirmation-view">
            <div className="selected_header">
              <h6>
                <IconUser /> Security Clearance
              </h6>
              <div className="manage-icons">
                <EditIcon onClick={handleEdit} />
                <DeleteIcon onClick={handleDelete} />
              </div>
            </div>
            <p className="selected-clearance-name">{securityFilterType} {selectedSecurityClearance}</p>
          </div>
        ) : (
          <>
            <div className="filter-options">
              <h6><IconUser /> Security Clearance</h6>
              <div className="clearance-list-radio-buttons">
                {["is one of", "is not one of", "is set", "is not set"].map(option => (
                  <label key={option}>
                    <input
                      type="radio"
                      name="filter"
                      value={option}
                      checked={securityFilterType === option}
                      onChange={handleFilterTypeChange}
                    />
                    {option}
                  </label>
                ))}
              </div>
            </div>
            <input
              type="text"
              placeholder="Search Security Clearance"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="clearance-search-bar"
            />
            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>Done</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SecurityClearanceFilter;
