import React, { useState, useEffect, useRef } from "react";
import "./MilitaryBranchFilter.css";
import { ReactComponent as IconUser } from "../../../assets/images/icon-user-fill.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";

const MilitaryBranchFilter = ({
  isOpen,
  onClose,
  onSelectBranch,
  branchFilterType,
  setBranchFilterType,
  onDelete,
  setFilteredData,
  fetchFilteredCandidates,
  selectedManager,
  selectedCandidateType,
  selectedBranch,
  setSelectedBranch
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBranchValue, setSelectedBranchValue] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleDone = () => {
    if (searchTerm.trim().length >= 2) {
      setSelectedBranch(searchTerm);
      onSelectBranch(searchTerm);
      setSelectedBranchValue(searchTerm);
      setIsConfirmed(true);
    }
  };

  const handleEdit = () => {
    setIsConfirmed(false);
  };

  const handleDelete = () => {


    setSelectedBranch(null);
    setSearchTerm("");
    setIsConfirmed(false);
    setFilteredData([]); // Clear UI instantly
    onDelete(null);
  
  };

  useEffect(() => {
    if (selectedBranch === null || selectedBranch === "") {
      setFilteredData([]);
      
    }
  }, [selectedBranch]);

  const handleFilterTypeChange = (event) => {
    setBranchFilterType(event.target.value);
   fetchFilteredCandidates();
  };

  if (!isOpen) return null;

  return (
    <div className="military-branch-modal-main">
      <div className="military-branch-modal-content">
        {isConfirmed ? (
          <div className="confirmation-view">
            <div className="selected_header">
              <h6>
                <IconUser /> Military Branch
              </h6>
              <div className="manage-icons">
                <EditIcon onClick={handleEdit} />
                <DeleteIcon onClick={handleDelete} />
              </div>
            </div>
            <p className="selected-branch-name">
              {branchFilterType} {" "} {selectedBranchValue}
            </p>
          </div>
        ) : (
          <>
            <div className="filter-options">
              <h6>
                <IconUser /> Military Branch
              </h6>
              <div className="branch-list-radio-buttons" style={{ paddingTop: "10px" }}>
                {["is one of", "is not one of", "is set", "is not set"].map((filter) => (
                  <label key={filter}>
                    <input
                      type="radio"
                      name="filter"
                      value={filter}
                      checked={branchFilterType === filter}
                      onChange={handleFilterTypeChange}
                    />
                    {filter}
                  </label>
                ))}
              </div>
            </div>
            <input
              type="text"
              placeholder="Enter Military Branch"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="branch-search-bar"
            />
            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MilitaryBranchFilter;
