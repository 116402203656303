import React from "react";
// import jsPDF from "jspdf";
// import "jspdf-autotable"; // Import the autoTable plugin
// import { Download } from "lucide-react"; // Ensure lucide-react is installed
import "./Invoices.css";

const Invoices = ({ onCreateInvoice }) => {
  const invoices = [
    {
      number: "INV-2010",
      issueDate: "11-3-24",
      dueDate: "11-11-24",
      status: "Paid",
      description: "Mark Johnson Internship",
      amount: "$5,000",
    },
    {
      number: "INV-2011",
      issueDate: "11-5-24",
      dueDate: "11-15-24",
      status: "Unpaid",
      description: "John Smith Skillbridge",
      amount: "$5,000",
    },
  ];

  // const downloadPDF = () => {
  //   const doc = new jsPDF();

  //   // Add Invoice Heading
  //   doc.setFontSize(18);
  //   doc.text("7 Eagle Group Invoice", 14, 15);

  //   // Prepare table columns and rows
  //   const tableColumns = [
  //     "Inv. Number",
  //     "Issue Date",
  //     "Due Date",
  //     "Status",
  //     "Description",
  //     "Amount",
  //   ];
  //   const tableRows = invoices.map((invoice) => [
  //     invoice.number,
  //     invoice.issueDate,
  //     invoice.dueDate,
  //     invoice.status,
  //     invoice.description,
  //     invoice.amount,
  //   ]);

  //   // Generate table using autoTable
  //   doc.autoTable({
  //     head: [tableColumns],
  //     body: tableRows,
  //     startY: 25, // Position after heading
  //   });

  //   // Save PDF
  //   doc.save("invoices.pdf");
  // };

  return (
    <div className="invoice-container">
      <div className="header_invoice">
        <h2>Invoices</h2>
        <button className="create-invoice-button" onClick={onCreateInvoice}>Create Invoice</button>
      </div>
      <table className="invoice-table">
        <thead>
          <tr>
            <th>Inv. Number</th>
            <th>Issue Date</th>
            <th>Due Date</th>
            <th>Status</th>
            <th>Description</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, index) => (
            <tr key={index}>
              <td>{invoice.number}</td>
              <td>{invoice.issueDate}</td>
              <td>{invoice.dueDate}</td>
              <td>
                <span
                  className={`status ${
                    invoice.status.toLowerCase() === "paid" ? "paid" : "unpaid"
                  }`}
                >
                  {invoice.status}
                </span>
              </td>
              <td>{invoice.description}</td>
              <td>{invoice.amount}</td>
              <td>
                {/* <Download
                  onClick={downloadPDF}
                  className="download-icon"
                  size={16}
                /> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Invoices;
