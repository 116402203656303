import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";

const useAdmin = () => {

  const [admin, setAdmin] = useState(null);
  const [loading, setLoading] = useState(true);
const { keycloak } = useContext(AuthContext); // Get Keycloak instance
  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        setLoading(true);
          const response = await axios.get(`/api/admin/userdetails`, {
            headers: {
              Authorization: `Bearer ${keycloak.token}`,
            },
          });
          const adminData = response.data;
          console.log('adminData::',adminData)
          setAdmin({
            _id: adminData._id ?? "", // Store _id
            fname: adminData.name?.split(" ")[0] ?? "",
            lname: adminData.name?.includes(" ") ? adminData.name.split(" ").slice(1).join(" ") : "",
            email: adminData.email ?? "",
            phone: adminData.phone ?? "",
            calendlyLink: adminData.calendlyLink ?? "",
            sub: adminData.sub ?? "",
            headshotLink: adminData.headshotLink ?? "",
          });
        
      } catch (error) {
        console.log("Error fetching Admin:", error.response ? error.response.data : error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAdmin();
  }, [keycloak.token]);

  return { admin, loading ,setAdmin};
};

export default useAdmin;
