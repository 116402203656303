import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/images/logo-7eagle.svg";
import { ReactComponent as NotificationIcon } from "../../../assets/images/notification.svg";
import { ReactComponent as SettingIcon } from "../../../assets/images/icon-gear-fill.svg";

import "../Header/Header.css"; // For Header component

const Header = ({ admin }) => {
  // const { logout, isAuthenticated } = useContext(AuthContext); // Get isAuthenticated from context
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <header className="header">
      <Link to="/admin/dashboard">

        <Logo className="logo-img" />
      </Link>

      <nav>
        <ul>
          <Link to="/admin/settings">
          <SettingIcon className="setting-icon"/>
          </Link>
          <Link to="/admin/notifications">
 
            <NotificationIcon className="setting-icon" />
          </Link>
          <li className="profile-menu" onClick={toggleDropdown}>
            <div>
              {admin ? (
                admin?.headshotLink ? (
                  <div className="exist-profile-image-header">
                    <img
                      src={admin?.headshotLink }
                      alt="Profile"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                ) : (
                  <div className="profile-image">
                    {/* <h2>{admin.name.charAt(0)}</h2> */}
                    <h2>{admin?.fname?.charAt(0)}</h2>

                  </div>
                )
              ) : (
                <div className="profile-image">
                  <h2>?</h2>{" "}
                  {/* Placeholder for when candidate is not available */}
                </div>
              )}
            </div>

            <span className="arrow">▼</span>
            {isDropdownOpen && (
              <div className="dropdown-menu-profile">
                <button>Logout</button>
              </div>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
