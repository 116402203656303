import React from "react";
import { useDrag, useDrop } from "react-dnd";


import { ReactComponent as IconDrag } from "../../../assets/images/move.svg";

import "./ColumnEditModal.css";
import Cursor from "quill/blots/cursor";

const ItemType = {
  COLUMN: "column",
};

const ColumnEditModal = ({
  isOpen,
  onClose,
  columns,
  toggleColumnVisibility,
  onColumnReorder,
}) => {
  if (!isOpen) return null;

  const handleCheckboxChange = (col) => {
    toggleColumnVisibility(col.id); // Toggle visibility state
  };

  const moveColumn = (dragIndex, hoverIndex) => {
    const newColumns = [...columns]; //  Immutable copy
    const [draggedColumn] = newColumns.splice(dragIndex, 1); //  Remove dragged column
    newColumns.splice(hoverIndex, 0, draggedColumn); //  Insert at new position
  
    onColumnReorder(newColumns); // Update state via parent function
  };
  

  return (

      <div
        className="modal fade show"
        style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "111111111" } }
        tabIndex="-1"
        
      >
        <div className="modal-dialog modal-dialog-centered modal-edit-column">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Choose which columns to display</h5>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body edit-column-box">
              <div>
                <h6>SYSTEM FIELDS</h6>
                <div className="leftsie_column_edit">
  {columns.map((col) => (
    <div key={col.id} className="form-check" style={{ display: col.name === "Name" ? "none" : "block" }}>
      <input
        type="checkbox"
        className="form-check-input"
        checked={col.visible}
        onChange={() => handleCheckboxChange(col)}
         // Disable checkbox for "Name" column
      />
      <label className="form-check-label" style={{ display: col.name === "Name" ? "none" : "block" }}>{col.name}</label>
    </div>
  ))}
</div>

              </div>

              <div>
                <h6>DISPLAYED COLUMNS</h6>
                <div className="rightside_column_edit">
  {columns
    .filter((col) => col.visible) // Only show visible columns
    .map((col, index) => (
      <DraggableColumn
        key={col.id}
        index={index}
        col={col}
        moveColumn={moveColumn}
      />
    ))}
</div>

              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-save" onClick={onClose}>
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
 
  );
};

const DraggableColumn = ({ col, index, moveColumn }) => {
  const [, ref] = useDrag({
    type: ItemType.COLUMN,
    item: { index },
    canDrag: col.name !== "Name",  // Disable drag for "Name" column
  });

  const [, drop] = useDrop({
    accept: ItemType.COLUMN,
    hover: (item) => {
      if (item.index !== index && col.name !== "Name") { // Prevent moving "Name" column
        moveColumn(item.index, index);
        item.index = index;
      }
    },
  });

  return (
    <div
      ref={(node) => ref(drop(node))}
      style={{
        padding: "8px",
        marginBottom: "10px",
        fontSize: "14px",
        backgroundColor: "#E6F3FF",
        border: "1px solid #E6F3FF",
        borderRadius: "4px",
        alignItems: "center",
        cursor:"pointer",
        display: col.name === "Name" ? "none" : "flex" // Disable cursor for "Name" column
    
      }}
    >
     <IconDrag/>
      <p style={{fontSize:
        "14px", marginLeft: "10px", marginBottom: "0px"
      }}>{col.name}</p>
    </div>
  );
};

export default ColumnEditModal;
