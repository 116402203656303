import React, { useState, useEffect, useRef } from "react";
import "./CandidateTypeFilter.css";

import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";
import { ReactComponent as IconUser } from "../../../assets/images/icon-user-fill.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";

const CandidateTypeFilter = ({
  isOpen,
  onClose,
  onSelectCandidateType,
  typeFilterType,
  setTypeFilterType, 
  selectedCandidateType,
  setSelectedCandidateType,
  onDelete,
  setFilteredData,
  fetchFilteredCandidates ,
  setIsEditView

}) => {
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedCandidateType, setSelectedCandidateType] = useState(null);
   const [selectedCandidateTypeValue, setSelectedCandidateTypeValue] = useState(null);

  const [isConfirmed, setIsConfirmed] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      console.log("Handling outside click...");
    };
  
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);
  

  const handleDone = () => {
    if (searchTerm && searchTerm.length >= 2) {
      setSelectedCandidateType(searchTerm);
      onSelectCandidateType(searchTerm);
      setSelectedCandidateTypeValue(searchTerm)
      setIsConfirmed(true);
      setIsEditView(true);
    }
  };

  const handleEdit = () => {
    setIsConfirmed(false);
  };

  useEffect(() => {
    if (selectedCandidateType === null || selectedCandidateType === "") {
      console.log("Candidate Type is null, resetting table...");
      setFilteredData([]);
    }
  }, [selectedCandidateType]);


  const handleDelete = () => {
    console.log("Deleting candidate type filter...");
  
    setSelectedCandidateType(null);
    setSearchTerm("");
    setIsConfirmed(false);
    onDelete(null);
  };
  

  


  const handleFilterTypeChange = (event) => {
    setTypeFilterType(event.target.value);
    // setTimeout(() => {
    //   fetchFilteredCandidates();  // Ensure state update hone ke baad call ho
    // }, 100);
  };
  

  if (!isOpen) return null;

  return (
    <div className="candidate-type-modal-main">
      <div className="candidate-type-modal-content" ref={modalRef}>
        {isConfirmed ? (
          <div className="confirmation-view">
            <div className="selected_header">
              <div>
                <h6>
                  <IconUser /> Candidate Type
                </h6>
              </div>
              <div className="manage-icons">
                <EditIcon onClick={handleEdit} />
                <DeleteIcon onClick={handleDelete} />
              </div>
            </div>
            <p className="selected-candidate-type-name">
              {typeFilterType} {" "} {selectedCandidateTypeValue}
            </p>
          </div>
        ) : (
          <>
            <div className="filter-options">
              <h6> 
                <IconUser /> Candidate Type
              </h6>
              <div className="candidate-type-radio-buttons" style={{ paddingTop: "10px" }}>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is one of"
                    checked={typeFilterType === "is one of"}
                    onChange={handleFilterTypeChange}
                  />
                  Is one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not one of"
                    checked={typeFilterType === "is not one of"}
                    onChange={handleFilterTypeChange}
                  />
                  Is not one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is set"
                    checked={typeFilterType === "is set"}
                    onChange={handleFilterTypeChange}
                  />
                  Is set
                </label>
                <label style={{paddingBottom:"10px"}}>
                  <input
                    type="radio"
                    name="filter"
                    value="is not set"
                    checked={typeFilterType === "is not set"}
                    onChange={handleFilterTypeChange}
                  />
                  Is not set
                </label>
              </div>
            </div>
            <input
              type="text"
              placeholder="Enter Candidate Type"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="candidate-type-search-bar"
            />
            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CandidateTypeFilter;