import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./AddCertificate.css";
import { AuthContext } from "../../../../context/AuthContext";
import { toast, ToastContainer } from "react-toastify";

Modal.setAppElement("#root");

const AddCertificate = ({ isOpen, onClose, candidate, onUpdate }) => {
    const navigate = useNavigate();
    const { keycloak } = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_URL;

    const [certificationsList, setCertificationsList] = useState([]);
    const [currentCertifications, setCurrentCertifications] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [currentSuggestions, setCurrentSuggestions] = useState([]);

    useEffect(() => {
        if (candidate?.certifications && Array.isArray(candidate.certifications)) {
            setCurrentCertifications(candidate.certifications.map(cert => ({
                _id: cert._id || null,
                certification: cert.certification || cert.name || "",
            })));
        } else {
            setCurrentCertifications([]);
        }

        axios.get(`${apiUrl}/certification/certification`)
            .then(response => {
                if (Array.isArray(response.data)) {
                    setCertificationsList(response.data);
                } else {
                    setCertificationsList([]);
                }
            })
            .catch(error => {
                console.error("Error fetching certifications:", error);
                setCertificationsList([]);
            });
    }, [candidate]);

    // const addCurrentCertification = (certification) => {
    //     if (!currentCertifications.some(cert => cert.certification === certification.certification)) {
    //         setCurrentCertifications((prev) => [...prev, certification]);
    //     }
    // };


    const addCurrentCertification = (certification) => {
        // Check if certification already exists in an array (case insensitive & trim spaces)
        if (!currentCertifications.some(cert => cert.certification.trim().toLowerCase() === certification.certification.trim().toLowerCase())) {
            setCurrentCertifications((prev) => [...prev, { ...certification, certification: certification.certification.trim() }]);
        } else {
            toast.warn("Certification already exists!"); // Alert user
        }
    };
    
    const handleCurrentInput = (e) => {
        const input = e.target.value.trim();
        setInputValue(input);

        if (input.length > 0 && Array.isArray(certificationsList)) {
            const filteredSuggestions = certificationsList
                .filter((cert) => cert.certification.toLowerCase().includes(input.toLowerCase()))
                .slice(0, 5);

            if (!filteredSuggestions.some(cert => cert.certification.toLowerCase() === input.toLowerCase())) {
                filteredSuggestions.push({ _id: null, certification: input });
            }
            setCurrentSuggestions(filteredSuggestions);
        } else {
            setCurrentSuggestions([]);
        }
    };

    const handleCurrentSuggestionClick = (certification) => {
        addCurrentCertification(certification);
        setInputValue("");
        setCurrentSuggestions([]);
    };

    const handleRemoveCert = (index) => {
        setCurrentCertifications(currentCertifications.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!candidate || !candidate._id) {
            toast.error("Invalid candidate data.");
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/candidates/${candidate._id}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${keycloak?.token || ""}`,
                },
                body: JSON.stringify({
                    certifications: currentCertifications.map(cert => ({
                        _id: cert._id ? cert._id : undefined, // Agar _id hai toh bhejain, warna backend create karega
                        certification: cert.certification
                    })),
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to update candidate");
            }

            toast.success("Candidate updated successfully!");
            if (onUpdate) {
                onUpdate({
                    ...candidate,
                    certifications: currentCertifications,
                });
            }
            setTimeout(() => {
                if (onClose) onClose();
            }, 2000);
        } catch (error) {
            toast.error("Error updating candidate: " + error.message);
            if (error.response) {
                const status = error.response.status;
                if ([400, 403, 500].includes(status)) {
                    navigate("/error", { state: { errorCode: status } });
                }
            }
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="certification-popup popup-content">
                <div className="certificate-heading">
                    <label>Your Current Certifications</label>
                    <input
                        type="text"
                        value={inputValue}
                        onChange={handleCurrentInput}
                        placeholder="Type or search your certifications here"
                    />

                    {Array.isArray(currentSuggestions) && currentSuggestions.length > 0 && (
                        <ul className="suggestions">
                            {currentSuggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleCurrentSuggestionClick(suggestion)}
                                    className="suggestion-item"
                                >
                                    {suggestion.certification}
                                </li>
                            ))}
                        </ul>
                    )}

                    <div className="certifications-tags">
                        {currentCertifications.length > 0 ? (
                            currentCertifications.map((cert, index) => (
                                <span key={index} className="tag">
                                    {cert.certification}
                                    <button onClick={() => handleRemoveCert(index)}>x</button>
                                </span>
                            ))
                        ) : (
                            <p>No certifications added yet.</p>
                        )}
                    </div>
                </div>

                <div className="form-btn">
                    <button onClick={handleSubmit} className="btn-save-changes">
                        Save
                    </button>
                </div>
            </div>
        </>
    );
};

export default AddCertificate;
