import React, { useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { ReactComponent as EditIcon } from "../../../../../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/images/trash.svg";
import { ReactComponent as DeleteCircle } from "../../../../../../assets/images/deletecircle.svg";


const EditJobTitleItem = ({ index, title, onUpdate, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(title);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const handleSave = () => {
    onUpdate(index, editText);
    setIsEditing(false);
  };

  return (
    <>
      <li className="dropdown-item edit_dropdown_item">
        {isEditing ? (
          <div className="edit-mode">
            <input
              type="text"
              value={editText}
              onChange={(e) => setEditText(e.target.value)}
              className="edit-input"
            />
            <div>
              <button onClick={() => setIsEditing(false)} className="cancel-icon">
                <FaTimes />
              </button>
              <button onClick={handleSave} className="check-icon">
                <FaCheck />
              </button>
            </div>
          </div>
        ) : (
          <>
            <span>{title}</span>
            <div className="icon-group">
              <EditIcon onClick={() => setIsEditing(true)} />
              <DeleteIcon onClick={() => setShowDeletePopup(true)} />
            </div>
          </>
        )}
      </li>
      
      {showDeletePopup && (
        <div className="delete-popup">
          <div className="popup-content">
            <span className="popup-icon"><DeleteCircle/></span>
            <h2>Delete Choice</h2>
            <p>Are you sure you want to delete this choice? This action cannot be undone.</p>
            <div className="popup-buttons">
              <button onClick={() => setShowDeletePopup(false)} className="cancel-button">
                Cancel
              </button>
              <button onClick={() => { onDelete(index); setShowDeletePopup(false); }} className="delete-button">
                Delete Choice
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditJobTitleItem;
