import React, { useState, useEffect } from "react";
import CandidateDetailsPopup from "../Candidate Details/CandidateDetailsPopup"; // Import the new Popup Component

const CandidateTable = ({ columns, data, admin, totalRecords, totalPages, currentPage, setCurrentPage, setRecordsPerPage, recordsPerPage }) => {
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage, setRecordsPerPage] = useState(10); // Default value set to 10
  const [selectedCandidate, setSelectedCandidate] = useState(null); // State to store selected candidate
  const [expandedRows, setExpandedRows] = useState([]); // Track expanded rows for companies

  
  const visibleColumns = columns.filter((col) => col.visible);



  const [currentRecords, setCurrentRecords] = useState([]);

useEffect(() => {
  const indexOfLastRecord = Math.min(currentPage * recordsPerPage, data.length);
  const indexOfFirstRecord = Math.max(0, indexOfLastRecord - recordsPerPage);
  setCurrentRecords(data.slice(indexOfFirstRecord, indexOfLastRecord));
}, [currentPage, recordsPerPage, data]); // Re-run on state change

  // Handle candidate row click
  const handleCandidateClick = (candidate) => {
    setSelectedCandidate(candidate);
  };

  const getColorForLetter = (letter) => {
    const colors = {
      A: "#FFD757",
      B: "#288C03",
      C: "#03A9F4",
      D: "#FF5722",
      E: "#8E24AA",
      F: "#03DAC5",
      G: "#2196F3",
      H: "#FF4081",
      I: "#9C27B0",
      J: "#4CAF50",
      K: "#FF9800",
      L: "#607D8B",
      M: "#00BCD4",
      N: "#FFEB3B",
      O: "#8BC34A",
      P: "#673AB7",
      Q: "#FF4081",
      R: "#9E9E9E",
      S: "#3F51B5",
      T: "#00BFAE",
      U: "#9E9E9E",
      V: "#4CAF50",
      W: "#3F51B5",
      X: "#E91E63",
      Y: "#FFEB3B",
      Z: "#9C27B0",
    };
    return colors[letter.toUpperCase()] || "rgb(200, 200, 200)";
  };

  const calculateProfileProgress = (row) => {
  const hasBasicInfo =
    row.fname &&
    row.lname &&
    row.phone &&
    row.city &&
    row.state;

  const hasMilitaryBackground =
    row.branch &&
    row.rank &&
    row.mos &&
    row.yearsServed;

  const hasCompleteProfileInfo =
    row.description &&
    row.securityClearance &&
    row.yearsOfExperience &&
    row.idealJobTitle &&
    row.relocationprefrence;

  const hasProfilePhoto = row.headshotLink;

  const totalSections = 4;
  let completedSections = 0;

  if (hasBasicInfo) completedSections++;
  if (hasMilitaryBackground) completedSections++;
  if (hasProfilePhoto) completedSections++;
  if (hasCompleteProfileInfo) completedSections++;

  return (completedSections / totalSections) * 100;
};

// Close the popup
  const closePopup = () => {
    setSelectedCandidate(null);
  };


    // Handle page change
    const handlePageChange = (pageNumber) => {
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
      }
    };


  // // Handle records per page change
  // const handleRecordsPerPageChange = (e) => {
  //   setRecordsPerPage(Number(e.target.value));
  //   setCurrentPage(1); // Reset to first page when records per page is changed
  // };

    // Handle records per page change
    const handleRecordsPerPageChange = (e) => {
      setRecordsPerPage(Number(e.target.value));
      setCurrentPage(1); // Reset to first page when changing records per page
    };

  // Toggle expand for companies
  const toggleExpand = (rowIndex) => {
    if (expandedRows.includes(rowIndex)) {
      setExpandedRows(expandedRows.filter((index) => index !== rowIndex));
    } else {
      setExpandedRows([...expandedRows, rowIndex]);
    }
  };

  return (
    <>
  

<div className="table_candidates">
<table>
  <thead>
 

<tr>
  {visibleColumns.map((col, index) => (
 <th
 key={col.id}
 style={{
   ...(index === 0 ? { position: "sticky", left: 0, backgroundColor: "rgb(232 233 235)", zIndex: 1 } : {}),
   ...(col.name === "Job & Stage" ? { minWidth: "343px" } : {}),
   ...(col.name === "Candidate Type" ? { minWidth: "264px" } : {}),
 }}
>
 {col.name}
</th>

  ))}
</tr>

  </thead>
  <tbody>
    {currentRecords.length > 0 ? (
      currentRecords.map((row, rowIndex) => (
    
        
        <React.Fragment key={rowIndex}>
      <tr>
                    {visibleColumns.map((col, index) => (
                      <td
                        key={col.id}
                        style={index === 0 ? { position: "sticky", left: 0, backgroundColor: "#fff", zIndex: 1 , padding:" 12px 15px"} : {}}
                        className={
                          col.field === "type"
                            ? (row.type === "Veteran")
                              ? "veteran-class"
                              : (row.type)
                              ? "transitioning-class"
                              : ""
                            : ""
                        }
                        
                      >
                        {/* Conditional rendering for different fields */}
                        {col.field === "name" ? (
                          <>
                            {row.headshotLink ? (
                            <>
                              <img
                                src={`${row.headshotLink}`}
                                alt="Profile"
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                  marginRight: "8px",
                                  objectFit: "cover",
                                }}
                                onClick={() => handleCandidateClick(row)}
                              />

                              <span onClick={() => handleCandidateClick(row)}>{row.fname} {""}{row.lname}</span>
                            </>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                    backgroundColor: getColorForLetter(
                                      row.fname.charAt(0)
                                    ),
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginRight: "8px",
                                  }}
                                  onClick={() => handleCandidateClick(row)}
                                >
                                  <span
                                    style={{ fontSize: "18px", color: "#fff" }}
                                  >
                                    {row.fname.charAt(0)}
                                  </span>
                                </div>

                                <span
                                  onClick={() => handleCandidateClick(row)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {row.fname} {" "} {row.lname}
                                </span>
                              </div>
                            )}
                          </>
                        ) :
                
                        col.field === "dateAdded" ? (
                          <span>
                            {new Date(row.dateAdded).toLocaleDateString("en-GB")}
                          </span>
                        ):
                        col.field === "profileProgress" ? (
                          <div
                            style={{
                              display: "block",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color:
                                  calculateProfileProgress(row) <= 12
                                    ? "red"
                                    : calculateProfileProgress(row) < 100
                                    ? "orange"
                                    : "#4caf50",
                              }}
                            >
                              {calculateProfileProgress(row).toFixed(0)}%
                            </span>
                            <div
                              style={{
                                width: "100%",
                                backgroundColor: "#e0e0df",
                                borderRadius: "5px",
                                marginRight: "8px",
                              }}
                            >
                              <div
                                style={{
                                  width: `${calculateProfileProgress(row).toFixed(0)}%`,
                                  backgroundColor:
                                    calculateProfileProgress(row) <= 12
                                      ? "red"
                                      : calculateProfileProgress(row) < 100
                                      ? "orange"
                                      : "#4caf50",
                                  height: "10px",
                                  borderRadius: "5px",
                                }}
                              ></div>
                            </div>
                          </div>
                        ) 
                        
                        : 
                        
                        col.field === "managers" ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                backgroundColor: "#ccc",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "8px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "18px",
                                  color: "#fff",
                                  fontWeight: "600",
                                }}
                              >
                                  {row?.managers?.[0]?.charAt(0) || "M"}
                              </span>
                            </div>
                            <span
                              style={{ fontWeight: "500", color: "#024a8d" }}
                            >
                              {row?.managers[0] ? row.managers[0] : "N/A"}

                            </span>
                          </div>
                        ) :
                        
                         col.field === "type" ? (
                          <span>{row?.type}</span>
                        ) : 
                        col.field === "companyStage" ? (
                          <div>
                          {row?.companyStage && row?.companyStage.length > 0 ? (
                            <>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <span
                                  style={{
                                    flex: "2 1 0%",
                                    textAlign: "left",
                                    color: "#024a8d",
                                    fontWeight: "500",
                                    fontSize: "13px",
                                  }}
                                >
                                  {row?.companyStage[0].companyTitle || "No Company"}
                                </span>
                                <span
                                  style={{
                                    textAlign: "center",
                                    flex: 1,
                                    backgroundColor:
                                      row?.companyStage[0].stage === "Sent Offer"
                                        ? "#C6FFB2"
                                        : "#e8e9eb",
                                    margin: "0",
                                    paddingBottom: "0",
                                    height: "fit-content",
                                    padding: "4px",
                                    fontSize: "10px",
                                    borderRadius: "10px",
                                    color:
                                      row?.companyStage[0].stage === "Sent Offer"
                                        ? "green"
                                        : "inherit",
                                  }}
                                >
                                  {row?.companyStage[0].stage || "No Stage"}
                                </span>
                                <span style={{ flex: 1, textAlign: "right" }}>
                                  {row?.companyStage.length > 1 && !expandedRows.includes(rowIndex) && (
                                    <button
                                      onClick={() => toggleExpand(rowIndex)}
                                      style={{
                                        marginLeft: "8px",
                                        cursor: "pointer",
                                        border: "none",
                                        background: "none",
                                        color: "blue",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      +{row?.companyStage.length - 1}
                                    </button>
                                  )}
                                  {expandedRows.includes(rowIndex) && (
                                    <button
                                      onClick={() => toggleExpand(rowIndex)}
                                      style={{
                                        marginLeft: "8px",
                                        cursor: "pointer",
                                        border: "none",
                                        background: "none",
                                        color: "blue",
                                      }}
                                    >
                                      Less
                                    </button>
                                  )}
                                </span>
                              </span>
                        
                              {expandedRows.includes(rowIndex) && row?.companyStage.length > 1 && (
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  {row?.companyStage.slice(1).map((companyStage, index) => (
                                    <span
                                      key={index}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        padding: "5px 0",
                                      }}
                                    >
                                      <span
                                        style={{
                                          flex: 2,
                                          textAlign: "left",
                                          color: "#024a8d",
                                          fontWeight: "500",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {companyStage?.companyTitle || "No Company "}
                                      </span>
                                      <span
                                        style={{
                                          textAlign: "center",
                                          flex: 1,
                                          backgroundColor:
                                          companyStage?.stage === "Sent Offer" ? "#C6FFB2" : "#efefef",
                                          padding: "4px",
                                          fontSize: "10px",
                                          borderRadius: "10px",
                                          color: companyStage?.stage === "Sent Offer" ? "green" : "#616569",
                                        }}
                                      >
                                        {companyStage?.stage || "No Stage"}
                                      </span>
                                    </span>
                                  ))}
                                </span>
                              )}
                            </>
                          ) : (
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                padding: "5px 0",
                              }}
                            >
                              <span
                                style={{
                                  flex: 2,
                                  textAlign: "left",
                                  color: "#024a8d",
                                  fontWeight: "500",
                                  fontSize: "13px",
                                }}
                              >
                                No Company 
                              </span>
                              <span
                                style={{
                                  textAlign: "center",
                                  flex: 1,
                                  backgroundColor: "#e8e9eb",
                                  padding: "4px",
                                  fontSize: "10px",
                                  borderRadius: "10px",
                                  color: "#616569",
                                }}
                              >
                                No Stage
                              </span>
                            </span>
                          )}
                        </div>
                        
                        )
                         : (
                          row[col.field]
                        )}
                      </td>
                    ))}
                  </tr>

        </React.Fragment>
      ))
    ) : (
      <tr>
        <td colSpan="100" style={{ textAlign: "center" }}>No Records Found</td>
      </tr>
    )}
  </tbody>
</table>

      </div>

      <div className="pagination-limit">
  <div>
    <label htmlFor="recordsPerPage">Candidates per page: </label>
    <select
      id="recordsPerPage"
      value={recordsPerPage}
      onChange={handleRecordsPerPageChange}
    >
      <option value={10}>10</option>
      <option value={20}>20</option>
      <option value={30}>30</option>
      <option value={40}>40</option>
      <option value={50}>50</option>
    </select>
  </div>

  <div className="pagination_numbers">
    {totalRecords > recordsPerPage && (
      <div>
        {/* Previous Page Button */}
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt;
        </button>

        {/* First 6 Pages */}
        {Array.from({ length: Math.min(6, totalPages) }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}

        {/* Ellipsis if more pages exist */}
        {totalPages > 6 && <span>...</span>}

        {/* Last Page */}
        {totalPages > 6 && (
          <button
            onClick={() => handlePageChange(totalPages)}
            className={currentPage === totalPages ? "active" : ""}
          >
            {totalPages}
          </button>
        )}

        {/* Next Page Button */}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>
    )}
  </div>
</div>

      
      
      {/* Candidate Details Popup */}
      {selectedCandidate && (
        <CandidateDetailsPopup
          candidate={selectedCandidate}
          onClose={closePopup}
        />
      )}
    </>
  );
};

export default CandidateTable;
