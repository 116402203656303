import React, { useState } from "react";
import "./Certification.css";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusCircle.svg";     
import AddCertificate from "./AddCertificate/AddCertificate";
import { ReactComponent as EditIcon } from "../../../assets/images/icon-edit-line.svg"; 

function Certification({ candidate, hideEditIcon }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedCandidate, setUpdatedCandidate] = useState(candidate);

  const handleUpdate = (updatedData) => {
    setUpdatedCandidate((prev) => ({
      ...prev,
      certifications: updatedData.certifications,
    }));
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="certification">
      <h4 className="main_certification_heading">Certifications</h4>

      {!isModalOpen && (
        <div className="certificate-sections-list">
          {updatedCandidate.certifications?.length > 0 ? (
            <div className="exist-target-value">
              {!hideEditIcon && (
                <div className="editIcon" onClick={openModal}>
                  <EditIcon />
                </div>
              )}

              <div className="certification-list-options">
                <span className="industry-tag">
                  {updatedCandidate.certifications && Array.isArray(updatedCandidate.certifications)
                    ? updatedCandidate.certifications.length > 0
                      ? updatedCandidate.certifications.map(cert => cert.certification).join(", ")
                      : "No certifications added yet"
                    : "No certifications available"}
                </span>
              </div>
            </div>
          ) : (
            <div className="empty-state">
              <div className="plusIcon" onClick={openModal}>
                <PlusIcon />
              </div>
              <p>
                Highlight your skills and qualifications by adding any relevant
                certifications. Stand out to companies looking for your expertise.
              </p>
              <button onClick={openModal}>Add Certifications</button>
            </div>
          )}
        </div>
      )}

      {isModalOpen && (
        <AddCertificate
          isOpen={isModalOpen}
          onClose={closeModal}
          candidate={updatedCandidate}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  );
}

export default Certification;
