import React from 'react';
import './CurrentPlan.css';

function CurrentPlanBilling() {
  return (
    <div className="plan-section">

      <div className="current-plan-label">
      <h3>Current Plan</h3>
      <p>Basic Plan (Pay-Per-Hire)</p>
      </div>

      <div className="change-plan-button">
      <button className="change-plan-btn">Change Plan</button>
      </div>
     
    </div>
  );
}

export default CurrentPlanBilling;