import React, { useState } from "react";
import "./BillingInfo.css";
import { ReactComponent as EditIcon } from "../../../../assets/images/IconEdit.svg"; 

function BillingInfo() {
  const [isEditing, setIsEditing] = useState(false);
  const [billingInfo, setBillingInfo] = useState({
    companyName: "7 Eagle Group",
    billingAddress: "450 7th Avenue, Suite 1409 New York, NY 10123",
    name: "Jordie Kern",
    email: "jk@7Eagle.com",
    phone: "914-349-0930",
  });

  const handleEdit = () => setIsEditing(!isEditing);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBillingInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => setIsEditing(true);
  const handleClose = () => setIsEditing(false);

  return (
    <div className="billing-info-section">
      {!isEditing ? (
        <>
          <h3>Billing Information</h3>
          <div>
            <div className="company_detail_group">
              <div className="company_detail">
                <label>Company Name</label>
                <p>{billingInfo.companyName}</p>
              </div>

              <div className="company_detail">
                <label>Billing Address:</label>
                <p>{billingInfo.billingAddress}</p>
              </div>
            </div>

            <div className="company_detail_group">
              <div className="company_detail">
                <label>Name</label>
                <p>{billingInfo.billingAddress}</p>
              </div>

              <div className="company_detail">
                <label>Email</label>
                <p>{billingInfo.email}</p>
              </div>

              <div className="company_detail">
                <label>Phone</label>
                <p>{billingInfo.phone}</p>
              </div>
            </div>
            <div className="edit_icon">
          
              <EditIcon onClick={handleEdit}/>
            </div>
          </div>
        </>
      ) : (
        <>
          <h3>Edit Billing Information</h3>
          <div className="edit-form">
            <div className="form-row-group">
              <div className="form-group">
                <label>Company Name</label>
                <input
                  name="companyName"
                  value={billingInfo.companyName}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label>Billing Address</label>
                <input
                  name="billingAddress"
                  className="billing_address_field"
                  value={billingInfo.billingAddress}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row-group">
              <div className="form-group">
                <label>Name</label>
                <input
                  name="name"
                  value={billingInfo.name}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  name="email"
                  value={billingInfo.email}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label>Phone</label>
                <input
                  name="phone"
                  value={billingInfo.phone}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="buttons">
              <button onClick={handleClose} className="close_btn">
                Cancle
              </button>
              <button className="save_btn" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BillingInfo;
