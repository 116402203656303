import React, { useState } from "react";
import axios from "axios";
import TextEditor from "../TextEditor/TextEditor"; // Your custom Text Editor
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./EmailEditor.css";

function EmailEditor({candidate}) {
  const [subject, setSubject] = useState("");
  const [toEmails, setToEmails] = useState(candidate.email ? [candidate.email] : "arsalanshamim786@gmail.com");
  const [emailInput, setEmailInput] = useState("");
  const [content, setContent] = useState(""); // Email content from Text Editor

  // Add an email to the recipient list
  const handleAddEmail = () => {
    if (emailInput && !toEmails.includes(emailInput)) {
      setToEmails([...toEmails, emailInput]);
      setEmailInput("");
    }
  };

  // Remove an email from the recipient list
  const handleRemoveEmail = (email) => {
    setToEmails(toEmails.filter((item) => item !== email));
  };

  // Handle changes in the email input field
  const handleEmailInputChange = (e) => setEmailInput(e.target.value);

  // Handle the send email logic
  const handleSendEmail = async () => {
    // Validate if there are recipients and content
    if (toEmails.length === 0) {
      toast.error("Please add at least one recipient email.");
      return;
    }
    if (!content.trim()) {
      toast.error("Please provide the email content.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/candidates/send-email`,
        {
          subject,
          content,
          toEmails,
        }
      );
      toast.success("Email sent successfully!");
      setSubject("");
      setContent("");
    } catch (error) {
      if (error.response) {
        console.error("Error sending email:", error.response.data);
        toast.error(`Failed to send email: ${error.response.data.message}`);
      } else {
        console.error("Error sending email:", error);
        toast.error("Failed to send email: Unknown error");
      }
    }
  };

  return (
    <div className="email_sent_editor">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar />

      <div className="email-tags">
        <div className="tags-input">
          {toEmails.map((email, index) => (
            <span key={index} className="email-tag">
              {email}{" "}
              <button onClick={() => handleRemoveEmail(email)}>x</button>
            </span>
          ))}
          <div className="to_cc">
            <label className="to_email">To</label>
            <input
              type="text"
              placeholder="Add recipient"
              value={emailInput}
              onChange={handleEmailInputChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleAddEmail();
              }}
            />
          </div>
        </div>
        <input
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="subject-text"
        />
      </div>

      {/* Text Editor for email content */}
      <TextEditor value={content} onChange={setContent} />

      <div className="footer_text_area">
        <button onClick={handleSendEmail}>Send</button>
      </div>
    </div>
  );
}

export default EmailEditor;
