import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./AddNewCandidateForm.css";
import { Link , useNavigate} from "react-router-dom";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import AddNewCandidatePhoto from "./AddNewCandidatePhoto";
import AddNewCandidateResume from "./AddNewCandidateResume";
import AddNewCandidateVideoIntro from "./AddNewCandidateVideoIntro";

const AddCandidateForm = () => {
  const [selectedImage, setSelectedImage] = useState(null);
   const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [jobStatuses, setJobStatuses] = useState([]); // Default: Empty array
  const [rank, setRank] = useState([]); // Default: Empty array
 const apiUrl = process.env.REACT_APP_API_URL;
  // const apiUrl = "https://arsalan.dev.7eagle.com/api/admin";

  const [values, setValues] = useState([]); // Default: Empty array
  const [targetIndustriesList, setTargetIndustriesList] = useState([]); // Default: Empty array
  const [certificationList, setCertificationList] = useState([]); // Default: Empty array
  const [mosList, setMosList] = useState([]); // Default: Empty array
  const [targetIndustry, setTargetIndustry] = useState(""); // Default: Empty string
  const [filteredIndustries, setFilteredIndustries] = useState([]); // Default: Empty array
  const [selectedTargetIndustries, setSelectedTargetIndustries] = useState([]); // Default: Empty array
  const [isCreatingIndustry, setIsCreatingIndustry] = useState(false); // Default: Boolean false
  const [selectedRanks, setSelectedRanks] = useState([]); // Default: Empty array
  const [selectedFile, setSelectedFile] = useState(null);
  const [candidateData, setCandidateData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    idno: 12345678,
    city: "",
    state: "",
    candidateType: "",
    rank: "",
    mos: "",
    branch: "",
    yearsServed: "",
    targetIndustries: [],
    certifications: "",
    Pursuingcertifications: "",
    description: "",
    securityClearance: "",
    yearsOfExperience: "",
    skillbridgeStartDate: "",
    skillbridgeEndDate: "",
    idealJobTitle: "",
    duringSkillbridgeCity: "",
    duringSkillbridgeState: "",
    afterskillbridgeCity: "",
    afterskillbridgeState: "",
    relocationprefrence: "",
    education: "",
    jobSearchStatus: "",
    jobSearchStatusName: "",
    locationDuringSkillbridge: "",
    managerName: "",
    locationAfterSkillbridge: "",
    headshotUrl: "",
    videoIntroUrl: "",
  });

  // Ideal Job Title
  const [jobTitlesList, setJobTitlesList] = useState([]); // Default: Empty array
  const [idealJobTitle, setIdealJobTitle] = useState("");
  const [idealJobSuggestions, setIdealJobSuggestions] = useState([]);
  const [selectedJobTitles, setSelectedJobTitles] = useState([]);

  useEffect(() => {
    // Function to fetch all required data when the component mounts
    const fetchData = async () => {
      setLoading(true);
      try {
        // Use Promise.all to fetch all data concurrently
        const [
          jobStatusResponse,
          targetIndustriesResponse,
          rankResponse,
          jobTitlesResponse,
          certificationsResponse,
          mosListResponse,
        ] = await Promise.all([
          axios.get(`${apiUrl}/jobstatus/jobstatus`),
          axios.get(`${apiUrl}/industry/industry`),
          axios.get(`${apiUrl}/rank/rank`),
          axios.get(`${apiUrl}/jobtitle/jobtitle`),
          axios.get(`${apiUrl}/certification/certification`),
          axios.get(`${apiUrl}/moslist/moslist`),
        ]);

             setJobTitlesList(jobTitlesResponse.data); // Update state with job titles
        
            // console.log("Job Status Response", jobStatusResponse.data);
            // console.log("Target Industries Response", targetIndustriesResponse.data);
            // console.log("Certification Response", certificationsResponse.data);
            // console.log("Mos List", mosListResponse.data)
        setJobStatuses(jobStatusResponse.data); // Update state with job statuses
        setValues(jobStatusResponse.data);
        setRank(rankResponse.data);
        setTargetIndustriesList(targetIndustriesResponse.data); // Update state with target industries
         setCertificationList(certificationsResponse.data); // Update state with certifications
        setMosList(mosListResponse.data); // Update state with MOS list
       
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data.");
              //  Redirect if error is 400, 403, or 500
              const status = error.response?.status;
              if ([400, 403, 500].includes(status)) {
                navigate("/error", { state: { errorCode: status } });
              }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  const handleJobTitleInput = (e) => {
    const input = e.target.value;
    setIdealJobTitle(input);

    if (input.trim().length > 0) {
      const filteredSuggestions = jobTitlesList.filter(
        (job) =>
          job.title && job.title.toLowerCase().includes(input.toLowerCase())
      );

      if (
        !filteredSuggestions.some(
          (suggestion) => suggestion.title.toLowerCase() === input.toLowerCase()
        )
      ) {
        filteredSuggestions.push({ _id: null, title: `Create new: ${input}` });
      }

      setIdealJobSuggestions(filteredSuggestions);
    } else {
      setIdealJobSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    if (suggestion.title.startsWith("Create new: ")) {
      const newTitle = suggestion.title.replace("Create new: ", "");
      const newId = Date.now(); // Unique ID generate karna
  
      addJobTitle({ _id: newId, title: newTitle });
    } else {
      addJobTitle({ _id: suggestion._id, title: suggestion.title });
    }
    
    setIdealJobTitle("");
    setIdealJobSuggestions([]);
  };
  

  const addJobTitle = (job) => {
    if (!job || !job.title) return;

    if (job._id) {
      if (!selectedJobTitles.some((j) => j._id === job._id)) {
        setSelectedJobTitles((prev) => [...prev, job]);
      }
    } else {
      if (!selectedJobTitles.some((j) => j.title === job.title)) {
        setSelectedJobTitles((prev) => [
          ...prev,
          { _id: null, title: job.title },
        ]);
      }
    }
  };

  const removeJobTitle = (index) => {
    setSelectedJobTitles((prev) => prev.filter((_, i) => i !== index));
  };

  // Certification Start state

  const [certification, setCertification] = useState("");
  const [certificationSuggestions, setCertificationSuggestions] = useState([]);
  const [selectedCertifications, setSelectedCertifications] = useState(
    Array.isArray(candidateData.certifications)
      ? candidateData.certifications
      : []
  );

  // Certification Function

  const handleCertificationInput = (e) => {
    const input = e.target.value;
    setCertification(input);

    if (input.length > 0) {
      const filteredSuggestions = certificationList
        .filter((item) =>
          item.certification.toLowerCase().includes(input.toLowerCase())
        ) // Access the certification string
        .map((item) => item.certification); // Return only the certification name

      // Add "Create new:" suggestion if not in the list
      if (!filteredSuggestions.includes(input)) {
        filteredSuggestions.push(`Create new: ${input}`);
      }

      setCertificationSuggestions(filteredSuggestions);
    } else {
      setCertificationSuggestions([]);
    }
  };
  const handleCertificationSuggestionClick = (cert) => {
    // Find the selected certification object from the list
    const selectedCert = certificationList.find(
      (c) => c.certification === cert
    );

    if (selectedCert) {
      addCertification(selectedCert);
    }

    setCertification("");
    setCertificationSuggestions([]);
  };
  const addCertification = (cert) => {
    if (!selectedCertifications.some((c) => c._id === cert._id)) {
      setSelectedCertifications((prev) => [...prev, cert]);
    }
  };
  const removeCertification = (index) => {
    setSelectedCertifications((prev) => prev.filter((_, i) => i !== index));
  };

  // Target ranks
  // Fetch ranks from the server
  const fetchRanks = async () => {
    try {
      const response = await axios.get(`${apiUrl}/rank/rank`);
      setRank(response.data); // Set the list of ranks
      setFilteredRanks(response.data); // Initially show all ranks in the filtered list
    } catch (error) {
      console.error("Error fetching ranks:", error);
            //  Redirect if error is 400, 403, or 500
            const status = error.response?.status;
            if ([400, 403, 500].includes(status)) {
              navigate("/error", { state: { errorCode: status } });
            }
    }
  };

  // Handle changes in the rank dropdown
  const handleRankChange = (e) => {
    const { value } = e.target;
    setCandidateData((prevData) => ({
      ...prevData,
      rank: value,
    }));
  };

  // Handle input change when user types in the rank field
  const [filteredRanks, setFilteredRanks] = useState([]); // List of ranks filtered by user input
  const [newRank, setNewRank] = useState(""); // User input for custom rank
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const handleRankInput = (e) => {
    const value = e.target.value;
    setNewRank(value);

    if (value.trim() !== "") {
      const filteredRanks = rank.filter((rankObj) =>
        rankObj.rank.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredRanks(filteredRanks); // Update filtered ranks based on user input
    } else {
      setFilteredRanks(rank); // Reset to the full list if input is empty
    }
  };

  // Handle selection of a rank from the dropdown list
  const handleRankSelection = (selectedRank) => {
    const rankObj = rank.find((r) => r.rank === selectedRank); // Find full rank object from the list

    if (!rankObj) return; // Safety check

    // Check if rank ID already exists to prevent duplicates
    if (!selectedRanks.includes(rankObj._id)) {
      setSelectedRanks([...selectedRanks, rankObj._id]); // Store only _id
    }

    setCandidateData({
      ...candidateData,
      rank: rankObj._id, // Pass only _id to backend
    });

    setNewRank("");
    setFilteredRanks([]);
  };

  const handleCreateNewRank = async () => {
    if (newRank.trim() === "") return; // Do not proceed if input is empty

    try {
      const response = await axios.post(`${apiUrl}/rank/rank`, {
        rank: newRank,
      });

      const newCreatedRank = response.data; // Full rank object with _id

      // Add new rank to the list (frontend display)
      setRank([...rank, newCreatedRank]);

      // Add only the `_id` to selectedRanks (backend)
      setSelectedRanks([...selectedRanks, newCreatedRank._id]);

      setCandidateData({
        ...candidateData,
        rank: newCreatedRank._id, // Send only _id to backend
      });

      setNewRank("");
      setFilteredRanks([]);
    } catch (error) {
      console.error("Error creating new rank:", error);
            //  Redirect if error is 400, 403, or 500
            const status = error.response?.status;
            if ([400, 403, 500].includes(status)) {
              navigate("/error", { state: { errorCode: status } });
            }
    }
  };

  // Function to remove selected rank
  const removeSelectedRank = (rankId) => {
    const updatedRanks = selectedRanks.filter((id) => id !== rankId);
    setSelectedRanks(updatedRanks);
  };

  // Target Industries Function
  const fetchIndustries = async () => {
    try {
      const response = await axios.get(`${apiUrl}/industry/industry`); // Replace with your API endpoint
      setTargetIndustriesList(response.data);
      setFilteredIndustries(response.data); // Set filtered industries to full list initially
    } catch (error) {
      console.error("Error fetching industries", error);
    }
  };

  const handleTargetIndustryInput = (e) => {
    const value = e.target.value;
    setTargetIndustry(value);

    // If the input field is not empty, filter the list based on user input
    if (value.trim() !== "") {
      const filteredIndustries = targetIndustriesList.filter((industryObj) => {
        const industry = industryObj.industry;

        // Ensure that industry is a valid string
        if (typeof industry !== "string") {
          console.warn("Invalid industry found:", industryObj);
          return false;
        }

        // Perform case-insensitive search
        return industry.toLowerCase().includes(value.toLowerCase());
      });

      setFilteredIndustries(filteredIndustries); // Update the filtered list
    } else {
      // Reset the filtered list to the full list if input is empty
      setFilteredIndustries(targetIndustriesList);
    }
  };

  const handleTargetIndustrySuggestionClick = (industryObj) => {
    if (
      !selectedTargetIndustries.some(
        (industry) => industry._id === industryObj._id
      )
    ) {
      setSelectedTargetIndustries([...selectedTargetIndustries, industryObj]); // Store full object
    }

    setTargetIndustry(""); // Clear the input field after selection
    setFilteredIndustries([]); // Hide the suggestion list
  };

  const handleCreateNewIndustry = async () => {
    if (targetIndustry.trim() === "") return;

    try {
      // Send a POST request to create a new industry
      const response = await axios.post(
        `${apiUrl}/industry/industry`,
        {
          industry: targetIndustry,
        }
      );

      // After creating the industry, update the list and select it
      setSelectedTargetIndustries([
        ...selectedTargetIndustries,
        response.data.industry,
      ]);

      // Fetch industries again to include the newly created industry
      fetchIndustries();
      setTargetIndustry(""); // Clear the input field
    } catch (error) {
      console.error("Error creating new industry", error);
            //  Redirect if error is 400, 403, or 500
            const status = error.response?.status;
            if ([400, 403, 500].includes(status)) {
              navigate("/error", { state: { errorCode: status } });
            }
    }
  };

  const removeTargetIndustry = (index) => {
    // Validate if selectedTargetIndustries[index] is defined
    if (!selectedTargetIndustries[index]) return;

    const removedIndustry = selectedTargetIndustries[index];
    const newSelectedIndustries = selectedTargetIndustries.filter(
      (_, i) => i !== index
    );

    // Find the removed industry in the targetIndustriesList
    const removedIndustryObj = targetIndustriesList.find(
      (industryObj) => industryObj?.industry === removedIndustry
    );

    // Only update filteredIndustries if the industry exists
    if (removedIndustryObj) {
      setFilteredIndustries((prevIndustries) => [
        ...prevIndustries,
        removedIndustryObj,
      ]);
    }

    // Update selected industries safely
    setSelectedTargetIndustries(newSelectedIndustries.filter(Boolean));
  };

  const handleImageSelect = (image) => {
    setSelectedImage(image);

    // Update the candidateData state to include the selected image
    setCandidateData((prevData) => ({
      ...prevData,
      headshotUrl: image, // Assign the selected image to headshotUrl
    }));
  };
  const [errors, setErrors] = useState({});
  const handleOnPlacesChanged = () => {
    let places = inputref.current.getPlaces();

    if (places && places.length > 0) {
      const addressComponents = places[0].address_components;

      if (addressComponents) {
        let cityName = "";
        let stateName = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            stateName = component.long_name;
          }
        });

        // Update the candidateData state
        setCandidateData((prevData) => ({
          ...prevData,
          city: cityName,
          state: stateName,
        }));
      } else {
        console.error("No address components found.");
      }
    } else {
      console.error("No places found.");
    }
  };

  const inputref = useRef(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Handle jobSearchStatus specifically
    if (name === "jobSearchStatus") {
      const numericValue = Number(value); // Convert to number if needed
      const status = values.find((statusObj) => statusObj._id === numericValue); // Find the status object based on _id

      setCandidateData((prevData) => ({
        ...prevData,
        [name]: numericValue, // Update the jobSearchStatus with the numeric value
        jobSearchStatusName: status ? status.status : "", // Set jobSearchStatusName to the matching status
      }));
    } else {
      // For all other fields, just update the state normally
      setCandidateData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };



  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  
  //   // Required field validation
  //   if (!candidateData.fname || !candidateData.lname || !candidateData.email || !candidateData.phone || !candidateData.candidateType) {
  //     toast.error("Please fill in all required fields.");
  //     return;
  //   }
  
  //   // Email format validation
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailRegex.test(candidateData.email)) {
  //     toast.error("Please enter a valid email address.");
  //     return;
  //   }
  
  //   // Phone number validation (basic check)
  //   const phoneRegex = /^\d{10,15}$/; // Allows 10-15 digits
  //   if (!phoneRegex.test(candidateData.phone.replace(/\D/g, ""))) {
  //     toast.error("Please enter a valid phone number.");
  //     return;
  //   }
  
  //   try {
  //     toast.info("Submitting candidate data, please wait...");
  
  //     // Step 1: Submit candidate data
  //     const response = await axios.post(`${apiUrl}/candidates/new-candidates`, {
  //       ...candidateData,
  //       idealJobTitles: selectedJobTitles.map((jobTitle) => jobTitle._id),
  //       certifications: selectedCertifications.map((cert) => cert._id),
  //       targetIndustries: selectedTargetIndustries.map((industry) => industry._id),
  //     });
  
  //     // Ensure response contains expected data
  //     if (!response.data || !response.data.data) {
  //       throw new Error("Invalid response from server.");
  //     }
  
  //     const candidate = response.data.data; // Ensure correct data structure
  //     console.log("Candidate data received:", candidate);
  
  //     if (!candidate.sub) {
  //       throw new Error("Candidate ID (sub) is missing from response.");
  //     }
  
  //     const candidateId = candidate.sub;
  //     toast.success("Candidate added successfully!");
  
  //     // Step 2: Upload image if selected
  //     if (selectedImage) {
  //       toast.info("Uploading candidate image, please wait...");
  
  //       const formData = new FormData();
  //       formData.append("headshotLink", selectedImage);
      
  //       const imageUploadUrl = `${apiUrl}/candidates/uploadImage/${candidateId}/${candidateData.fname}`;
  //       console.log("Uploading image to:", imageUploadUrl);
  
  //       const imageResponse = await axios.post(imageUploadUrl, formData, {
  //         headers: { "Content-Type": "multipart/form-data" },
  //       });
  
  //       if (imageResponse.data?.headshotLink) {
  //         toast.success("Image uploaded successfully!");
  //       } else {
  //         throw new Error("Image upload failed.");
  //       }
  //     } else {
  //       toast.warn("No image selected. Candidate added without an image.");
  //     }
  
  //     // Reset form on success
  //     setCandidateData({
  //       fname: "",
  //       lname: "",
  //       email: "",
  //       phone: "",
  //       candidateType: "",
  //     });
  
  //   } catch (error) {
  //     console.error("Error adding candidate:", error);
      
  //     const status = error.response?.status;
  //     if ([400, 403, 500].includes(status)) {
  //       navigate("/error", { state: { errorCode: status } });
  //     }
  
  //     toast.error(error.response?.data?.message || error.message || "Failed to add candidate. Please try again.");
  //   }
  // };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Required field validation
    if (!candidateData.fname || !candidateData.lname || !candidateData.email || !candidateData.phone || !candidateData.candidateType) {
      toast.error("Please fill in all required fields.");
      return;
    }
  
    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(candidateData.email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
  
    // Phone number validation (basic check)
    const phoneRegex = /^\d{10,15}$/; // Allows 10-15 digits
    if (!phoneRegex.test(candidateData.phone.replace(/\D/g, ""))) {
      toast.error("Please enter a valid phone number.");
      return;
    }
  
    try {
      toast.info("Submitting candidate data, please wait...");
  
      // Step 1: Submit candidate data
      const response = await axios.post(`${apiUrl}/candidates/new-candidates`, {
        ...candidateData,
        idealJobTitles: selectedJobTitles.map((jobTitle) => jobTitle._id),
        certifications: selectedCertifications.map((cert) => cert._id),
        targetIndustries: selectedTargetIndustries.map((industry) => industry._id),
      });
  
      // Ensure response contains expected data
      if (!response.data || !response.data.data) {
        throw new Error("Invalid response from server.");
      }
  
      const candidate = response.data.data;
      // console.log("Candidate data received:", candidate);
  
      if (!candidate.sub) {
        throw new Error("Candidate ID (sub) is missing from response.");
      }
  
      const candidateId = candidate.sub;
      toast.success("Candidate added successfully!");
  
      // Step 2: Upload image if selected
      if (selectedImage) {
        toast.info("Uploading candidate image, please wait...");
  
        const formData = new FormData();
        formData.append("headshotLink", selectedImage);
      
        const imageUploadUrl = `${apiUrl}/candidates/uploadImage/${candidateId}/${candidateData.fname}`;
        console.log("Uploading image to:", imageUploadUrl);
  
        const imageResponse = await axios.post(imageUploadUrl, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
  
        if (imageResponse.data?.headshotLink) {
          toast.success("Image uploaded successfully!");
        } else {
          throw new Error("Image upload failed.");
        }
      } else {
        toast.warn("No image selected. Candidate added without an image.");
      }
  
      // Step 3: Upload resume if selected
      if (selectedFile) {
        toast.info("Uploading candidate resume, please wait...");
  
        const resumeFormData = new FormData();
        resumeFormData.append("resumeLink", selectedFile);
  
        const resumeUploadUrl = `${apiUrl}/candidates/uploadResume/${candidateId}/${candidateData.fname}`;
        console.log("Uploading resume to:", resumeUploadUrl);
  
        const resumeResponse = await axios.post(resumeUploadUrl, resumeFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
  
        if (resumeResponse.data?.resumeLink) {
          toast.success("Resume uploaded successfully!");
        } else {
          throw new Error("Resume upload failed.");
        }
      } else {
        toast.warn("No resume selected. Candidate added without a resume.");
      }
  
      // Reset form on success
      setCandidateData({
        fname: "",
        lname: "",
        email: "",
        phone: "",
        candidateType: "",
      });
  
    } catch (error) {
      console.error("Error adding candidate:", error);
      
      const status = error.response?.status;
      if ([400, 403, 500].includes(status)) {
        navigate("/error", { state: { errorCode: status } });
      }
  
      toast.error(error.response?.data?.message || error.message || "Failed to add candidate. Please try again.");
    }
  };
  
  
  
  return (
    <div className="add-candidate-form">
      <ToastContainer/>
      <div className="profilePhotoCandidate"></div>
      <form onSubmit={handleSubmit}>
        <AddNewCandidatePhoto onImageSelect={handleImageSelect} />
        {/* Personal Details */}
        <div className="form-row">
          <div className="form-group ">
            <label htmlFor="fname">First Name</label>
            <input
              type="text"
              id="fname"
              name="fname"
              placeholder="Jim"
              value={candidateData.fname}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="lname">Last Name</label>
            <input
              type="text"
              id="lname"
              name="lname"
              placeholder="Smith"
              value={candidateData.lname}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="jimsmith@gmail.com"
              name="email"
              value={candidateData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="(325) 650 - 2006"
              value={candidateData.phone}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="candidateType">Candidate Type</label>
            <select
              id="candidateType"
              name="candidateType"
              value={candidateData.candidateType}
              onChange={handleChange}
            >
              <option value="">Select Candidate Type</option>
              <option value="Veteran">Veteran</option>
              <option value="Transitioning Service Member">
                Transitioning Service Member
              </option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="managerName">Manager Name</label>
            <input
              type="text"
              id="managerName"
              name="managerName"
              placeholder="Manager Name"
              value={candidateData.managerName}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Address add */}

        <div className="form-row">
          <div className="form-group">
            <div className="form-field">
              {isLoaded && (
                <div
                  style={{
                    marginBottom: "15px",
                    marginTop: "0px",
                    paddingTop: "0px",
                    position: "relative",
                  }}
                >
                  <label>
                    Location <span>*</span>
                  </label>
                  <StandaloneSearchBox
                    onLoad={(ref) => (inputref.current = ref)}
                    onPlacesChanged={handleOnPlacesChanged}
                    style={{
                      zIndex: "10000",
                      position: "absolute",
                      top: "0px",
                      width: "100%",
                    }} // Higher z-index for the search box
                  >
                    <input
                      type="text"
                      placeholder="Search Location"
                      className={!candidateData.city ? "error-borderx" : ""}
                      style={{
                        width: "100%",
                        padding: "14px",
                        borderRadius: "10px",
                        border: "1px solid #ddddde",
                      }}
                    />
                  </StandaloneSearchBox>
                  {errors.city && (
                    <span className="error-message-field">{errors.city}</span>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="form-group"></div>
        </div>

        <AddNewCandidateResume setFileInParent={setSelectedFile}/>

        {/* Upload Video Intro Component */}

       <div className="form-row">
       <div className="form-group">
            <label htmlFor="email">Video Intro</label>
            <input
              type="url"
              id="videointro"
              placeholder="https://you.tube.com/watch?231"
              name="videoIntro"
              value={candidateData.videoIntroUrl}
              onChange={handleChange}
              style={{width:"50%"}}
            />
          </div>
       </div>

        {/* Candidate Information */}

        <h6 className="sub_heading_add_new">Candidate Information</h6>

        <div className="form-row">
          <div className="form-group">
            <label>Short Bio</label>
            <textarea />
          </div>

          <div className="form-group"></div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="idealJobTitle">Ideal Job Title</label>
            <input
              type="text"
              id="idealJobTitle"
              name="idealJobTitle"
              placeholder="Type or search ideal job titles here"
              value={idealJobTitle}
              onChange={handleJobTitleInput}
            />
            {idealJobSuggestions.length > 0 && (
              <ul className="suggestions">
                {idealJobSuggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="suggestion-item"
                  >
                    {suggestion.title}
                  </li>
                ))}
              </ul>
            )}
            <div className="job-title-tags">
              {selectedJobTitles.map((job, index) => (
                <span key={index} className="tag">
                  {job.title}
                  <button onClick={() => removeJobTitle(index)}>x</button>
                </span>
              ))}
            </div>
          </div>

          <div className="form-group"></div>
        </div>

{candidateData.candidateType !== "Veteran" && (
<div>
<div className="form-row">
          <div className="form-group">
            <label htmlFor="availableToStart">Available to Start</label>
            <input
              type="date"
              id="availableToStart"
              name="skillbridgeStartDate"
              value={candidateData.skillbridgeStartDate || ""} // Ensure it's either a valid date or empty
              onChange={handleChange}
            />
          </div>

          <div className="form-group"></div>
        </div>

        {/* Location During SkillBridge */}
        <div className="form-row">
          <div className="form-group">
            <div className="form-field">
              {isLoaded && (
                <div
                  style={{
                    marginBottom: "15px",
                    marginTop: "0px",
                    paddingTop: "0px",
                  }}
                >
                  <label>Location During SkillBridge</label>
                  <StandaloneSearchBox
                    onLoad={(ref) => (inputref.current = ref)}
                    onPlacesChanged={handleOnPlacesChanged}
                    style={{
                      zIndex: "10000",
                      position: "absolute",
                      top: "0px",
                      width: "100%",
                    }} // Higher z-index for the search box
                  >
                    <input
                      type="text"
                      placeholder="Search Location"
                      className={
                        !candidateData.locationDuringSkillbridge
                          ? "error-borderx"
                          : ""
                      }
                      style={{
                        width: "100%",
                        padding: "14px",
                        borderRadius: "10px",
                        border: "1px solid #ddddde",
                      }}
                    />
                  </StandaloneSearchBox>
                  {errors.city && (
                    <span className="error-message-field">
                      {errors.locationDuringSkillbridge}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="form-group"></div>
        </div>

        {/* Location After Skillbridge */}

        <div className="form-row">
          <div className="form-group">
            <div className="form-field">
              {isLoaded && (
                <div
                  style={{
                    marginBottom: "15px",
                    marginTop: "0px",
                    paddingTop: "0px",
                  }}
                >
                  <label>Location After SkillBridge</label>
                  <StandaloneSearchBox
                    onLoad={(ref) => (inputref.current = ref)}
                    onPlacesChanged={handleOnPlacesChanged}
                    style={{
                      zIndex: "10000",
                      position: "absolute",
                      top: "0px",
                      width: "100%",
                    }} // Higher z-index for the search box
                  >
                    <input
                      type="text"
                      placeholder="Search Location"
                      className={
                        !candidateData.afterskillbridgeCity
                          ? "error-borderx"
                          : ""
                      }
                      style={{
                        width: "100%",
                        padding: "14px",
                        borderRadius: "10px",
                        border: "1px solid #ddddde",
                      }}
                    />
                  </StandaloneSearchBox>
                  {errors.city && (
                    <span className="error-message-field">
                      {errors.afterskillbridgeCity}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="form-group"></div>
        </div>
</div>
)}
       

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="education">Education</label>
            <input
              type="text"
              id="education"
              name="education"
              placeholder="Enter Value Here"
              value={candidateData.education}
              onChange={handleChange}
            />
          </div>

          <div className="form-group"></div>
        </div>

        <div className="form-row" style={{ width: "50%" }}>
          <div className="form-group">
            <label htmlFor="targetIndustries">Target Industries</label>
            <input
              type="text"
              id="targetIndustries"
              name="targetIndustries"
              placeholder="Type or search target industries here"
              value={targetIndustry}
              onChange={handleTargetIndustryInput}
            />

            {/* Suggestions */}
            {filteredIndustries.length > 0 && (
              <ul className="suggestions">
                {filteredIndustries.map((suggestion, index) => (
                  <li
                    key={suggestion._id || index}
                    onClick={() =>
                      handleTargetIndustrySuggestionClick(suggestion)
                    }
                    className="suggestion-item"
                  >
                    {suggestion.industry}
                  </li>
                ))}
              </ul>
            )}

            {/* Create New Industry Option */}
            {targetIndustry &&
              !filteredIndustries.some(
                (industryObj) =>
                  industryObj.industry.toLowerCase() ===
                  targetIndustry.toLowerCase()
              ) && (
                <div className="create-industry">
                  <button
                    type="button"
                    onClick={handleCreateNewIndustry}
                    className="create-button-industry"
                  >
                    Create "{targetIndustry}" Industry
                  </button>
                </div>
              )}

            {/* Selected Tags */}
            <div className="industry-tags">
              {selectedTargetIndustries
                .filter(Boolean) // Remove any undefined/null values
                .map((industryObj, index) => (
                  <span key={index} className="tag">
                    {typeof industryObj === "string"
                      ? industryObj
                      : industryObj?.industry || "Unknown Industry"}
                    <button
                      style={{ color: "gray" }}
                      type="button"
                      onClick={() => removeTargetIndustry(index)}
                      className="remove-tag"
                    >
                      x
                    </button>
                  </span>
                ))}
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="securityClearance">Security Clearance</label>
            <select
              id="securityClearance"
              name="securityClearance"
              value={candidateData.securityClearance}
              onChange={handleChange}
            >
              <option value="">Select Security Clearance</option>
              <option value="Confidential">Confidential</option>
              <option value="Secret">Secret</option>
              <option value="Top Secret">Top Secret</option>
              <option value="Top Secret/SCI">Top Secret/SCI</option>
              {/* Add more security clearance options as needed */}
            </select>
          </div>
          <div className="form-group"></div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="yearsOfExperience">Years of Experience</label>
            <select
              id="yearsOfExperience"
              name="yearsOfExperience"
              value={candidateData.yearsOfExperience}
              onChange={handleChange}
            >
              <option value="">Select Years of Experience</option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10+</option>
              {/* Add more years of experience options as needed */}
            </select>
          </div>

          <div className="form-group"></div>
        </div>

        {/* Professional Details */}

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="certifications">Certifications</label>
            <input
              type="text"
              id="certifications"
              name="certifications"
              placeholder="Type or search certifications here"
              value={certification}
              onChange={handleCertificationInput}
            />
            {certificationSuggestions.length > 0 && (
              <ul className="suggestions">
                {certificationSuggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() =>
                      handleCertificationSuggestionClick(suggestion)
                    }
                    className="suggestion-item"
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
            <div className="certification-tags">
              {selectedCertifications.map((cert, index) => (
                <span key={cert._id} className="tag">
                  {cert.certification}
                  <button onClick={() => removeCertification(index)}>x</button>
                </span>
              ))}
            </div>
          </div>

          <div className="form-group"></div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="branch">Branch</label>
            <select
              id="branch"
              name="branch"
              value={candidateData.branch}
              onChange={handleChange}
            >
              <option value="">Select Branch</option>
              <option value="Army">Army</option>
              <option value="Navy">Navy</option>
              <option value="Air Force">Air Force</option>
              <option value="Marines">Marines</option>
              <option value="Coast Guard">Coast Guard</option>
            </select>
          </div>

          <div className="form-group"></div>
        </div>
        {/* ranks list */}
        <div className="form-group" style={{ width: "50%" }}>
          <label htmlFor="rank">Rank</label>
          <input
            type="text"
            id="rank"
            name="rank"
            placeholder="Type or select a rank"
            value={newRank}
            onChange={handleRankInput}
            onBlur={() => setTimeout(() => setFilteredRanks([]), 200)} // Hide suggestions on blur
          />

          {/* Show the filtered ranks in a dropdown */}
          {filteredRanks.length > 0 && (
            <ul className="suggestions">
              {filteredRanks.map((rankObj) => (
                <li
                  key={rankObj._id}
                  onClick={() => handleRankSelection(rankObj.rank)}
                  className="suggestion-item"
                >
                  {rankObj.rank}
                </li>
              ))}
            </ul>
          )}

          {/* Show "Create new" option if input is not in the list */}
          {newRank &&
            !filteredRanks.some(
              (rankObj) => rankObj.rank.toLowerCase() === newRank.toLowerCase()
            ) && (
              <div className="create-rank">
                <button
                  type="button"
                  onClick={handleCreateNewRank}
                  className="create-button-rank"
                >
                  Create "{newRank}" Rank
                </button>
              </div>
            )}

          {/* Selected Ranks as Tags */}
          <div className="selected-tags" style={{ display: "flex" }}>
            {selectedRanks.map((rankId, index) => {
              const rankObj = rank.find((r) => r._id === rankId); // Get rank object using _id
              return rankObj ? (
                <span key={index} className="tag">
                  {rankObj.rank} {/* Display rank name */}
                  <button
                    type="button"
                    onClick={() => removeSelectedRank(rankId)}
                  >
                    x
                  </button>
                </span>
              ) : null;
            })}
          </div>
        </div>

        {/* Mos */}

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="mos">MOS</label>
            <select
              id="mos"
              name="mos"
              value={candidateData.mos}
              onChange={handleChange}
            >
              <option value="">Select MOS</option>
              {/* {mosList.map((mos) => (
                <option key={mos?._id} value={mos?._id}>
                  {mos?.mos}
                </option>
              ))} */}
            </select>
          </div>

          <div class="form-group"></div>
        </div> 

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="yearsServed">Years Served</label>
            <select
              id="yearsServed"
              name="yearsServed"
              value={candidateData.yearsServed}
              onChange={handleChange}
            >
              <option value="">Select Years Served</option>
              <option value="1-3 years">1-3 years</option>
              <option value="4-6 years">4-6 years</option>
              <option value="7-10 years">7-10 years</option>
              <option value="11-15 years">11-15 years</option>
              <option value="16-20 years">16-20 years</option>
              <option value="20+ years">20+ years</option>
            </select>
          </div>

          <div className="form-group"></div>
        </div>

       <div className="saveChanges_btn_section">
       <button
          type="submit"
          className="btn btn-primary"
          style={{ background: "#ffc500" }}
        >
          Save Changes
        </button>
       </div>
      </form>
    </div>
  );
};

export default AddCandidateForm;

