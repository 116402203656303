import React, { useState } from "react";
import "./ReviewLists.css";
import { ReactComponent as MoreIcon } from "../../../assets/images/More.svg";
import { ReactComponent as StarIcon } from "../../../assets/images/star.svg";

function ReviewLists() {
  // Sample reviews with dynamic ratings
  const reviews = [
    {
      review:
        "Cybersurance has a strong sense of camaraderie and offers excellent training. It's been a great place to start my cybersecurity career.",
      name: "James Parker",
      designation: "Incident Response Specialist",
      rating: 5, // 5-star rating
    },
    {
      review:
        "Working at TechGuard has allowed me to grow tremendously in my role. The mentorship program is top-notch!",
      name: "Sarah Collins",
      designation: "Cybersecurity Analyst",
      rating: 4, // 4-star rating
    },
    {
      review:
        "DataSecure provides a great balance of challenge and support. I'm constantly learning and growing here.",
      name: "Michael Smith",
      designation: "Penetration Tester",
      rating: 3, // 3-star rating
    },
    {
      review:
        "I've had the opportunity to work on cutting-edge projects and develop my skills in a supportive environment.",
      name: "Emily Johnson",
      designation: "Forensic Specialist",
      rating: 5, // 5-star rating
    },
    {
      review:
        "The team's collaboration and the leadership's vision make this a rewarding workplace.",
      name: "David Lee",
      designation: "Risk Analyst",
      rating: 4, // 4-star rating
    },
  ];

  // State to control the number of visible reviews
  const [visibleReviews, setVisibleReviews] = useState(3); // Show 3 reviews initially

  // Function to show more reviews
  const showMoreReviews = () => {
    setVisibleReviews(visibleReviews + 3); // Show 3 more reviews
  };

  return (
    <div className="reviews_section">
      <h4>Reviews</h4>
      <div className="reviews_list">
        {reviews.slice(0, visibleReviews).map((review, index) => (
          <div className="review_card" key={index}>
            <div className="review_content">
              <div className="review_rating">
                {/* Render StarIcons based on rating */}
                {Array.from({ length: review.rating }, (_, i) => (
                  <StarIcon key={i} className="star_icon" />
                ))}
              </div>
              <p>{review.review}</p>
              <h5>{review.name}</h5>
              <span>{review.designation}</span>
            </div>
            <div className="more_icon">
              <MoreIcon />
            </div>
          </div>
        ))}
      </div>

      {/* Show "More Reviews" button only if there are more reviews */}
      {visibleReviews < reviews.length && (
        <div className="show_more">
          <button onClick={showMoreReviews}>
            +{reviews.length - visibleReviews} more Reviews
          </button>
        </div>
      )}
    </div>
  );
}

export default ReviewLists;
