import React, { useState } from "react";
import "./AboutCandidate.css";
import { ReactComponent as UserIcon } from "../../../assets/images/user_img.svg";  
import { ReactComponent as PlusIcon } from "../../../assets/images/plusCircle.svg";     
import EditAboutProfileInfo from "./EditAbout/EditAboutProfileInfo";
import { ReactComponent as EditIcon } from "../../../assets/images/icon-edit-line.svg"; 

function AboutCandidate({ candidate, hideEditIcon }) {
  const [isEditing, setIsEditing] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [updatedCandidate, setUpdatedCandidate] = useState(candidate); // Track updated candidate data

  const handleUpdate = (updatedData) => {
    setUpdatedCandidate({
      ...updatedCandidate,
      jobSearchStatus: updatedData.jobSearchStatus,
      jobSearchStatusName: updatedData.jobSearchStatusName,
      description: updatedData.description || "",
      securityClearance: updatedData.securityClearance || "",
      yearsOfExperience: updatedData.yearsOfExperience || "",
      skillbridgeStartDate: updatedData.skillbridgeStartDate || "",
      skillbridgeEndDate: updatedData.skillbridgeEndDate || "",
      idealJobTitle: updatedData.idealJobTitle || "",
      duringSkillbridgeCity: updatedData.duringSkillbridgeCity || "",
      duringSkillbridgeState: updatedData.duringSkillbridgeState || "",
      afterskillbridgeCity: updatedData.afterskillbridgeCity || "",
      afterskillbridgeState: updatedData.afterskillbridgeState || "",
      relocationprefrence: updatedData.relocationprefrence || "",
      education: updatedData.education || "",
    });
  };
  const toggleEditProfile = () => {
    setShowEditProfile(!showEditProfile);
  };

  const startEditing = () => {
    setIsEditing(true);
    setShowEditProfile(true); // Edit form kholne ke liye
  };

  const stopEditing = () => {
    setIsEditing(false);
  };

  const hasInfo =
    (candidate && candidate.idealJobTitle) ||
    candidate.securityClearance ||
    candidate.yearsOfExperience ||
    candidate.skillbridgeStartDate ||
    candidate.duringSkillbridgeCity ||
    candidate.duringSkillbridgeState ||
    candidate.afterskillbridgeCity ||
    candidate.afterskillbridgeState ||
    candidate.education ||
    candidate.relocationprefrence ||
    candidate.description;

  return (
    <div className="watch_my_intro">
      <h4>Candidate Information</h4>

      {showEditProfile ? (
      <EditAboutProfileInfo
          candidate={updatedCandidate} // Pass the updated candidate to the child
          onUpdate={handleUpdate} // Callback to handle update in parent
          isOpen={showEditProfile}
          onClose={toggleEditProfile}
        />
      ) : (
        <div>
          {!hasInfo ? (
            <div className="empty-state">
              <div className="plusIcon" onClick={startEditing}>
              <PlusIcon/>
              </div>
              <div className="emptyIcon">
             <UserIcon/>
             </div>
              <p>
                Help employers understand who you are and what you bring to the
                table. Write a short bio <br /> to showcase your strengths and
                goals.
              </p>
              <button onClick={startEditing}>Add Information</button>
            </div>
          ) : (
            <div className="existing-info-about">
              {!hideEditIcon && (
                <div className="editIcon" onClick={toggleEditProfile}>
                        <EditIcon/>
                </div>
              )}

              <b>Short Info:</b>

              <p className="description">
                {updatedCandidate.description || candidate.description}
              </p>

              <div className="row-about-fields">
                <div className="left-column-box">
                  <p>Ideal Job</p>
                  <b>
                    {updatedCandidate.idealJobTitle[0] ||
                      candidate.idealJobTitle[0]}
                  </b>
                </div>

                <div className="right-column-box">
                  <p>Security Clearance</p>
                  <b>
                    {updatedCandidate.securityClearance ||
                      candidate.securityClearance}
                  </b>
                </div>
              </div>
              <div className="row-about-fields">
                <div >
                  <p>Years of Experience</p>
                  <b>
                    {updatedCandidate.yearsOfExperience ||
                      candidate.yearsOfExperience}
                  </b>
                </div>

                {candidate.type !== "Veteran" && (
                  <div className="right-column-box">
                    <p>Available Start</p>
                    <b>
                      {updatedCandidate.skillbridgeStartDate ||
                        candidate.skillbridgeStartDate}
                    </b>
                  </div>
                )}
              </div>

              {candidate.type !== "Veteran" && (
                <div className="row-about-fields">
                  {updatedCandidate.locationDuringSkillbridge ||
                    (candidate.locationDuringSkillbridge ===
                      "Yes - I know exactly where I'll live." && (
                      <div className="left-column-box">
                        <p>Location During Skillbridge</p>
                        {updatedCandidate.duringSkillbridgeCity ||
                          (candidate.duringSkillbridgeCity &&
                            updatedCandidate.duringSkillbridgeState) ||
                          (candidate.duringSkillbridgeState && (
                            <b>
                              {updatedCandidate.duringSkillbridgeCity ||
                                candidate.duringSkillbridgeCity}
                              ,
                              {updatedCandidate.duringSkillbridgeState ||
                                candidate.duringSkillbridgeState}{" "}
                            </b>
                          ))}
                      </div>
                    ))}

                  {updatedCandidate.locationAfterSkillbridge ||
                    (candidate.locationAfterSkillbridge ===
                      "Yes - I know exactly where I'll live." && (
                      <div className="right-column-box">
                        <p>Location After Skillbridge</p>
                        {updatedCandidate.afterskillbridgeCity ||
                          (candidate.afterskillbridgeCity &&
                            updatedCandidate.afterskillbridgeState) ||
                          (candidate.afterskillbridgeState && (
                            <b>
                              {updatedCandidate.afterskillbridgeCity ||
                                candidate.afterskillbridgeCity}
                              ,
                              {updatedCandidate.afterskillbridgeState ||
                                candidate.afterskillbridgeState}
                            </b>
                          ))}
                      </div>
                    ))}
                </div>
              )}

              <div className="row-about-fields">
                <div className="left-column-box">
                  <p>Education</p>
                  <b>{updatedCandidate.education || candidate.education}</b>
                </div>

                <div className="right-column-box">
                  <p>Relocation Option</p>
                  <b>
                    {updatedCandidate.relocationprefrence ||
                      candidate.relocationprefrence}
                  </b>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AboutCandidate;
