import React from 'react';
import { ReactComponent as Logo } from "../../assets/images/logo-7eagle.svg";
import { Link , useNavigate} from "react-router-dom";
import useAdmin from './useAdmin';  
import Header from '../../components/Common/Header/Header';
import { useLocation } from 'react-router-dom';
import './PageError.css';

function DynamicError() {
  const { admin, loading } = useAdmin();
  const location = useLocation(); // Get the state from navigation
  const errorCode = location.state?.errorCode || 400; // Default to 400 if no error code is passed

  if (loading) {
      return (
        <div className="loading-container">
          <Logo style={{width:"150px"}}/>
          <div className="spinner"></div>
          <div>
            <h6>Please Wait..</h6>
          </div>
        </div>
      );
  }

  return (
    <div>
        <Header admin={admin} />

        <div className="error-page">
        <div className="error-page-content">
      
          <div>
            <p className="sub-error">Error {errorCode}: {getErrorMessage(errorCode)}</p>
            <h2>Something's Not Quite Right</h2>
            <p>
              Please check the information you entered or refresh the page and try again.
            </p>
            <div className="back-to-home">
              <a href="/admin/dashboard">Back to Dashboard</a>
            </div>

            <div className="error-page-footer">
              <p>
                Still having trouble? <a href="">Contact Support</a>
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

const getErrorMessage = (code) => {
  switch (code) {
    case 400: return "Bad Request";
    case 403: return "Forbidden";
    case 500: return "Internal Server Error";
    default: return "Unknown Error";
  }
};

export default DynamicError;
