import React, { useState, useEffect, useRef, useContext } from "react";
import "./CandidateInfo.css";
import { ReactComponent as MoreIcon } from "../../../assets/images/More.svg"; 
import { ReactComponent as ExternalLinkIcon } from "../../../assets/images/ExternalLink.svg"; 
import { ReactComponent as MarkIcon } from "../../../assets/images/mark_icon.svg"; 
import { ReactComponent as IconFire} from "../../../assets/images/icon-fire-fill.svg"; 
import { ReactComponent as SendIcon } from "../../../assets/images/send_icon.svg"; 
import { ReactComponent as TrashIcon } from "../../../assets/images/trash.svg"; 
import { AuthContext } from '../../../context/AuthContext';
import { ReactComponent as EditIconLine } from "../../../assets/images/icon-edit-line.svg"; 
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure you import the CSS
import {
  MdOutlineEmail,
  MdOutlinePhone,
  MdOutlineLocationOn,
} from "react-icons/md";
import UpdateBasicInformation from "../Profile Information/UpdateBasicInformationPopup";
import axios from "axios";

function CandidateInfo({ candidate, toggleSubmitForm }) {
  const { keycloak } = useContext(AuthContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedCandidate, setUpdatedCandidate] = useState(candidate);
  const [isManager1DropdownOpen, setIsManager1DropdownOpen] = useState(false);
  const [isManager2DropdownOpen, setIsManager2DropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [managers, setManagers] = useState(["Jordie", "Jethro", "Tyler"]); // Sample list of managers
  const [selectedManagers, setSelectedManagers] = useState(
    candidate.managers || []
  );


  const manager1DropdownRef = useRef(null);
  const manager2DropdownRef = useRef(null);

// Tags Section UseState
const [searchTag, setSearchTag] = useState("");
const [selectedTags, setSelectedTags] = useState([]);
const [allTags, setAllTags] = useState([]);
const [isTagDropdownOpen, setIsTagDropdownOpen] = useState(false);

// const filteredTags = allTags.filter((tag) =>
//   tag.toLowerCase().includes(searchTag.toLowerCase())
// );

// const toggleTagDropdown = () => {
//   setIsTagDropdownOpen(!isTagDropdownOpen);
//   setSearchTag(""); // Reset search when dropdown opens
// };

// const handleTagSelect = (tag) => {
//   setSelectedTags((prevTags) =>
//     prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
//   );
  
// };

// const handleCreateNewTag = () => {
//   if (searchTag.trim() !== "") {
//     setAllTags((prevTags) => [...prevTags, searchTag]);
//     setSelectedTags((prevTags) => [...prevTags, searchTag]);
//     setSearchTag("");
//   }
// };


// **Step 1: Fetch Existing Tags from Backend**
const apiUrl = process.env.REACT_APP_API_URL;
useEffect(() => {
  axios.get(`${apiUrl}/candidates/tags`)
    .then(response => {
      setAllTags(response.data.tags);
    })
    .catch(error => console.error("Error fetching tags:", error));
}, []);

// **Step 2: Filter Tags Based on Search**
const filteredTags = allTags.filter((tag) =>
  tag.toLowerCase().includes(searchTag.toLowerCase())
);

// **Step 3: Toggle Dropdown**
const toggleTagDropdown = () => {
  setIsTagDropdownOpen(!isTagDropdownOpen);
  setSearchTag(""); // Reset search when dropdown opens
};

// **Step 4: Handle Tag Selection**
const handleTagSelect = (tag) => {
  setSelectedTags((prevTags) =>
    prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
  );

  // Save Selected Tags in Backend
  axios.patch(`${apiUrl}/candidates/updateCandidateTags/${candidate._id}`, {
    tags: [...selectedTags, tag]
  }).catch(error => console.error("Error updating tags:", error));
};

// **Step 5: Handle New Tag Creation**
const handleCreateNewTag = () => {
  if (searchTag.trim() !== "") {
    axios.post(`${apiUrl}/candidates/tags`, { tag: searchTag })
      .then(response => {
        const newTag = response.data.tag;
        setAllTags((prevTags) => [...prevTags, newTag]);
        setSelectedTags((prevTags) => [...prevTags, newTag]);

        // Save New Tag to Candidate Profile
        axios.patch(`${apiUrl}/candidates/updateCandidateTags/${candidate._id}`, {
          tags: [...selectedTags, newTag]
        }).catch(error => console.error("Error updating candidate tags:", error));

        setSearchTag("");
      })
      .catch(error => console.error("Error creating tag:", error));
  }
};

  const handleDeleteCandidate = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this candidate? This action cannot be undone."
    );

    if (!confirmDelete) return;

    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await fetch(
        `${apiUrl}/candidates/delete/${candidate._id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error:", errorData.message);
        toast.error(`Error: ${errorData.message}`);
      } else {
        toast.success("Candidate deleted successfully!");
        // Optionally refresh the candidate list or navigate away
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("An error occurred while deleting the candidate.");
    }
  };
  const dropdownRef = useRef(null); // Ref for main dropdown
  const managerDropdownRef = useRef(null); // Ref for manager dropdown
  const TagDropdownRef = useRef(null); // Ref for manager dropdown

  // Calculate Progress Percentage
  const hasBasicInfo =
    candidate.fname &&
    candidate.lname &&
    candidate.phone &&
    candidate.city &&
    candidate.state;

  const hasMilitaryBackground =
    candidate.branch &&
    candidate.rank &&
    candidate.mos &&
    candidate.yearsServed;

  const hasCompleteProfileInfo =
    candidate.description &&
    candidate.securityClearance &&
    candidate.yearsOfExperience &&
    candidate.idealJobTitle &&
    candidate.relocationprefrence;

  const hasProfilePhoto = candidate.headshotLink;

  const totalSections = 4;
  let completedSections = 0;

  if (hasBasicInfo) completedSections++;
  if (hasMilitaryBackground) completedSections++;
  if (hasProfilePhoto) completedSections++;
  if (hasCompleteProfileInfo) completedSections++;

  const progress = (completedSections / totalSections) * 100;

  const toggleForm = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleUpdate = (updatedData) => {
    setUpdatedCandidate({
      ...updatedCandidate,
      fname: updatedData.fname,
      lname: updatedData.lname,
      email: updatedData.email,
      phone: updatedData.phone,
      idno: updatedData.idno,
      state: updatedData.state,
      city: updatedData.city,
      type: updatedData.type,
    });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // ✅ Toggle function for Manager 1 dropdown
  const toggleManager1Dropdown = () => {
    setIsManager1DropdownOpen(!isManager1DropdownOpen);
    setIsManager2DropdownOpen(false); // Close Manager 2 dropdown
  };

  // ✅ Toggle function for Manager 2 dropdown
  const toggleManager2Dropdown = () => {
    setIsManager2DropdownOpen(!isManager2DropdownOpen);
    setIsManager1DropdownOpen(false); // Close Manager 1 dropdown
  };

  const handleManagerSelect = (manager) => {
    if (selectedManagers.includes(manager)) {
      setSelectedManagers(selectedManagers.filter((m) => m !== manager));
    } else if (selectedManagers.length < 2) {
      setSelectedManagers([...selectedManagers, manager]);
    } else {
      toast.error("You can select a maximum of 2 managers.");
    }
  };

  const handleSaveManagers = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await fetch(
        `${apiUrl}/candidates/addManager/${candidate._id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ managers: selectedManagers }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error:", errorData.message);
        toast.error("Error:", errorData.message);
      } else {
        toast.success("Manager Added Successful!");
      }
    } catch (error) {
      console.error("Error:", error.message);
      alert("An error occurred while updating managers.");
      toast.error("An error occurred while updating managers");
    }
  };

  const filteredManagers = managers.filter((manager) =>
    manager.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Close dropdowns on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isDropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }

      if (
        isManager1DropdownOpen &&
        manager1DropdownRef.current &&
        !manager1DropdownRef.current.contains(event.target)
      ) {
        setIsManager1DropdownOpen(false);
      }

      if (
        isManager2DropdownOpen &&
        manager2DropdownRef.current &&
        !manager2DropdownRef.current.contains(event.target)
      ) {
        setIsManager2DropdownOpen(false);
      }


      if (
        isTagDropdownOpen &&
        TagDropdownRef.current &&
        !TagDropdownRef.current.contains(event.target)
      ) {
        setIsTagDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen, isManager1DropdownOpen, isManager2DropdownOpen , isTagDropdownOpen]);

  return (
    <div>
      <ToastContainer />
      {!isModalOpen ? (
        <div className="profile_info_candidate_section">
          <div className="profile_visiblity">
            <div>
              <p>
                Profile Visibility: <b>Public</b>
              </p>
            </div>
            <div className="open_new_window">
              <a href="">
                Open in new Window{" "}
               <ExternalLinkIcon/>
              </a>
            </div>
          </div>

     <div className="profile_info_candidates">
  <div className="profile_info_detail">
    {candidate?.headshotLink ? (
      <img
        src={candidate?.headshotLink}
        className="profile_img"
        style={{ marginRight: "10px" }}
        alt="Candidate Headshot"
      />
    ) : (
      <div
        style={{
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          backgroundColor: "rgb(248 206 69)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "8px",
        }}
      >
        <span style={{ fontSize: "40px", color: "#fff" }}>
          {candidate?.fname ? candidate.fname.charAt(0) : "?"}
        </span>
      </div>
    )}

    <div className="candidate_info_name">
      <h2>
        {updatedCandidate?.fname || candidate?.fname || "Unknown"}{" "}
        {updatedCandidate?.lname || candidate?.lname || ""}
      </h2>

      <h5 style={{ textTransform: "uppercase" }}>
  ID{(updatedCandidate?.sub || candidate?.sub || "ID Not Available").slice(-5)}
</h5>


      <span>{updatedCandidate?.type || candidate?.type || "Not Specified"}</span>
    </div>
  </div>

  <div className="more_icon" ref={dropdownRef} onClick={toggleDropdown}>
    <MoreIcon />

    {isDropdownOpen && (
      <div className="dropdown-edit-profile">
        <button onClick={toggleForm}>
          <EditIconLine style={{ marginRight: "1px", width: "18px", height: "18px" }} /> Edit Info
        </button>
        <button onClick={toggleSubmitForm}>
          <SendIcon style={{ marginRight: "1px", width: "18px", height: "18px" }} /> Present to Company
        </button>
        <button>
          <IconFire style={{ marginRight: "1px", width: "18px", height: "18px" }} /> Add to Hot Candidates
        </button>
        <button>
          <MarkIcon style={{ marginRight: "1px", width: "18px", height: "18px" }} /> Mark as Hired
        </button>
        <button onClick={handleDeleteCandidate}>
          <TrashIcon style={{ marginRight: "1px", width: "18px", height: "18px" }} /> Delete Candidate
        </button>
      </div>
    )}
  </div>
</div>


          <div className="profile_info_details">
            <div className="location_address">
              <p>
                <MdOutlineLocationOn
                  style={{
                    color: "#4A4D50",
                    fontSize: "18px",
                    marginRight: "10px",
                  }}
                />
                {updatedCandidate?.city || candidate?.city},{" "}
                {updatedCandidate?.state || candidate?.state}
              </p>
              <p>
                <MdOutlineEmail
                  style={{
                    color: "#4A4D50",
                    fontSize: "18px",
                    marginRight: "10px",
                  }}
                />
                {updatedCandidate?.email || candidate?.email}
              </p>
              <p>
                <MdOutlinePhone
                  style={{
                    color: "#4A4D50",
                    fontSize: "18px",
                    marginRight: "10px",
                  }}
                />
                {updatedCandidate?.phone || candidate?.phone}
              </p>
            </div>

            <div className="profile_complete">
              <p className="account_progress">Profile Progress</p>
              <h6>{Math.round(progress)}%</h6>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#e0e0df",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    width: `${progress}%`,
                    height: "7px",
                    backgroundColor: "#4caf50",
                    transition: "width 0.5s ease-in-out",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="candidate_tags">
      <div className="candidate_tag">
        {selectedTags.map((tag, index) => (
          <span key={index} className="tag">{tag}</span>
        ))}
      </div>
      <div className="plus_tag">
        <button className="add_tag_btn" onClick={toggleTagDropdown}>
          + Add Tag
        </button>
        {isTagDropdownOpen && (
          <div className="dropdown-tag" ref={TagDropdownRef}>
            <input
              type="text"
              placeholder="Search tag..."
              value={searchTag}
              onChange={(e) => setSearchTag(e.target.value)}
              className="search-tag"
            />
            <ul>
              {filteredTags.length > 0 ? (
                filteredTags.map((tag, index) => (
                  <li
                    key={index}
                    onClick={() => handleTagSelect(tag)}
                    style={{
                      backgroundColor: selectedTags.includes(tag)
                        ? "rgba(224, 224, 224, 0.47)"
                        : "transparent",
                    }}
                  >
                    {tag}
                  </li>
                ))
              ) : (
                <li className="create-new" onClick={handleCreateNewTag} style={{ color: "blue", cursor: "pointer" }}>
                  + Create New: "{searchTag}"
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>

          <div
            className="candidate_managerer_affiliate"
            ref={managerDropdownRef}
          >
       
      {/* ✅ Manager 1 */}
      <div className="managers_affiliate" ref={manager1DropdownRef}>
        <p>
          Manager 1{" "}
          <span>
            {selectedManagers.length > 0
              ? selectedManagers[0]
              : candidate?.managers && candidate?.managers.length > 0
              ? candidate.managers[0]
              : "None"}{" "}
            <b onClick={toggleManager1Dropdown} style={{ cursor: "pointer" }}> ▼ </b>
          </span>
        </p>

        {isManager1DropdownOpen && (
          <div className="dropdown-manager">
      
                  <input
                    type="text"
                    placeholder="Search manager..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-manager"
                  />
                  <ul>
                    {filteredManagers.map((manager, index) => (
                      <li
                        key={index}
                        onClick={() => handleManagerSelect(manager)}
                        style={{
                          backgroundColor: selectedManagers.includes(manager)
                            ? "rgba(224, 224, 224, 0.47)"
                            : "transparent",
                        }}
                      >
                        {manager}
                      </li>
                    ))}
                  </ul>
                  <button className="saveManager" onClick={handleSaveManagers}>
                    Save
                  </button>
             
          </div>
        )}
      </div>

      {/* ✅ Manager 2 */}
      <div className="managers_affiliate" ref={manager2DropdownRef}>
        <p>
          Manager 2{" "}
          <span>
            {selectedManagers.length > 1
              ? selectedManagers[1]
              : candidate?.managers && candidate?.managers.length > 1
              ? candidate.managers[1]
              : "None"}{" "}
            <b onClick={toggleManager2Dropdown} style={{ cursor: "pointer" }}> ▼ </b>
          </span>
        </p>

        {isManager2DropdownOpen && (
          <div className="dropdown-manager second-dropdown-manager">
            <div className="">
                  <input
                    type="text"
                    placeholder="Search manager..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-manager"
                  />
                  <ul>
                    {filteredManagers.map((manager, index) => (
                      <li
                        key={index}
                        onClick={() => handleManagerSelect(manager)}
                        style={{
                          backgroundColor: selectedManagers.includes(manager)
                            ? "rgba(224, 224, 224, 0.47)"
                            : "transparent",
                        }}
                      >
                        {manager}
                      </li>
                    ))}
                  </ul>
                  <button className="saveManager" onClick={handleSaveManagers}>
                    Save
                  </button>
                </div>
          </div>
        )}
      </div>
    
            
          </div>
        </div>
      ) : (
        <UpdateBasicInformation
          candidate={updatedCandidate}
          onUpdate={handleUpdate}
          isOpen={isModalOpen}
          onClose={toggleForm}
        />
      )}
    </div>
  );
}

export default CandidateInfo;
