import React, { useState, useEffect, useRef } from "react";
import "./CertificationFilter.css";
import { ReactComponent as IconAward } from "../../../assets/images/icon-award-fill.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";

const CertificationFilter = ({
  isOpen,
  onClose,
  candidateData,
  onSelectCertification,
  certificationFilterType,
  setCertificationFilterType,
  selectedCertifications,
  setSelectedCertifications,
  onDelete,
  setFilteredData,
  fetchFilteredCandidates,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [certificationDisplay, setCertificationDisplay] = useState("");

  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {

    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
  return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const handleDone = () => {
    if (searchTerm && searchTerm.length >= 2) {
      setSelectedCertifications(searchTerm);
      onSelectCertification(searchTerm);
      setIsConfirmed(true);
      setCertificationDisplay(searchTerm);
    
    }
  };

  const handleEdit = () => {
    setIsConfirmed(false);
  };

  useEffect(() => {
    if (selectedCertifications === null || selectedCertifications === "") {
      console.log("Certifications are null, resetting table...");
      setFilteredData([]);
    }
  }, [selectedCertifications]);
  

  const handleDelete = () => {
    console.log("Deleting manager filter...");
    setSelectedCertifications(null);
    setSearchTerm("");
    setIsConfirmed(false);
    onDelete(null);
    fetchFilteredCandidates(null);
  };

  const handleFilterTypeChange = (event) => {
    setCertificationFilterType(event.target.value);
   
  };

  if (!isOpen) return null;

  return (
    <div className="certification-modal-main">
      <div className="certification-modal-content" ref={modalRef}>
        {isConfirmed ? (
          <div className="confirmation-view">
            <div className="selected_header">
              <div>
                <h6>
                  <IconAward /> Certification
                </h6>
              </div>
              <div className="manage-icons">
                <EditIcon onClick={handleEdit} />
                <DeleteIcon onClick={handleDelete} />
              </div>
            </div>
            <p className="selected-certifications">
              {certificationFilterType} {certificationDisplay}
            </p>
          </div>
        ) : (
          <>
            <div className="filter-options">
              <h6>
                <IconAward /> Certification
              </h6>
              <div className="certification-list-radio-buttons" style={{display:"grid"}}>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="contains one of"
                    checked={certificationFilterType === "contains one of"}
                    onChange={handleFilterTypeChange}
                  />
                  Contains one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="does not contain any of"
                    checked={certificationFilterType === "does not contain any of"}
                    onChange={handleFilterTypeChange}
                  />
                  Does not contain any of
                </label>
              </div>
            </div>
            <input
              type="text"
              placeholder="Search Certification"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="certification-search-bar"
            />
            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CertificationFilter;