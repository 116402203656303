import React from "react";
import "./Roles.css";
import { ReactComponent as EditIcon } from "../../../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/images/trash.svg";


const Roles = () => {
  const roles = [
    {
   
      role: "Admin",
    },
    {
    
      role: "7 Eagle Recruiter",
    },
    {
    
      role: "7 Eagle Intern",
    },
  ];

  return (
    <div className="section_roles">
      <div className="header_roles">
        <h2>All Roles</h2>
        <button className="add-btn">Add Roles +</button>
      </div>
      <div className="roles_table">
        <table className="table">
          <thead>
            <tr>
      
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
  {roles.map((role, index) => (
    <tr key={index}>
      <td>
        {role.role}
      </td>
      <td>
        <EditIcon style={{ marginRight: "5px" }} alt="Edit Role" />
        <DeleteIcon alt="Delete Role" />
      </td>
    </tr>
  ))}
</tbody>

        </table>
      </div>
    </div>
  );
};

export default Roles;
