import React, { useState,useContext } from "react";
import "./CompanyImageUpload.css";
import { ReactComponent as UploadIcon } from "../../../assets/images/uploadIcon.svg";
import fileImg from "../../../assets/images/file.png";
import { AuthContext } from "../../../context/AuthContext";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

function CompleteCompanyImageUpload({ company, onClose, onImageUpload, newImageUrl }) {
   const { keycloak } = useContext(AuthContext);
  const [image, setImage] = useState(null); // State for the image
  const [uploading, setUploading] = useState(false); // State for upload progress
  const [progress, setProgress] = useState(0); // State for progress bar
  const [error, setError] = useState(""); // State for error handling

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setError("");
      setImage(file); // Store the image file in the state
    } else {
      setError(
        "You can’t upload this file. Only .jpg and .png formats are allowed."
      );
    }
  };

  const handleSaveChanges = async () => {
    if (!image) {
      setError("Please select an image first.");
      return;
    }
  
    if (!keycloak || !keycloak.token) {
      setError("Authentication error: Missing token.");
      return;
    }
  
    setUploading(true);
    setError("");
  
    const formData = new FormData();
    formData.append("logo", image);
  
    try {
      const response = await axios.post(
        `/api/admin/company/${company._id}/${company.name}/upload/logo`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const percentComplete = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentComplete);
            }
          },
        }
      );
  
      if (response.status === 200) {
        setProgress(100);
  
        setTimeout(() => {
          setUploading(false);
          toast.success("Profile Image Uploaded successfully!");
          console.log("Response data:", response.data);
          if (onImageUpload) {
            onImageUpload(response.data.fileUrl);
          }
          onClose();
        }, 1000);
      }
    } catch (error) {
      setError(
        error.response?.data?.message || "Error uploading image. Please try again."
      );
    } finally {
      setUploading(false);
      setImage(null); // Reset file input
    }
  };
  
  

  return (
    <>
    <ToastContainer />
      <div className="popup-header">
        <h2>Upload or Edit Company logo</h2>
        <p>
          Upload a clear, professional logo to help candidate profile stand out
          to hiring companies.
        </p>
      </div>
      <hr />
      <div className="upload-heading">
        <h6>Upload Your logo</h6>
      </div>
      <div className="row drag-row" style={{ alignItems: "center" }}>
        <div className="col-lg-6">
          <div
            className="upload-box"
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              handleFileChange({
                target: { files: e.dataTransfer.files },
              });
            }}
          >
            {image ? (
              <div className="image-preview">
                <img src={URL.createObjectURL(image)} alt="Preview" />
              </div>
            ) : (
              <>
 <UploadIcon/>
                <p
                  style={{
                    padding: "10px",
                    fontWeight: "600",
                    fontSize: "16px",
                    paddingBottom: "0px",
                    margin: "0px",
                  }}
                >
                  Drag & drop files or{" "}
                  <label
                    htmlFor="file-upload"
                    className="browse-link"
                    style={{ cursor: "pointer " }}
                  >
                    <b> Browse</b>
                  </label>
                </p>
                <p style={{ fontSize: "12px" }}>
                  Supported formats: .jpg, .png
                </p>
              </>
            )}
            <input
              type="file"
              id="file-upload"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <div className="left-content-popup">
            <h5 className="popup-left-heading">
              Show companies the best version of yourself!
            </h5>
            <p style={{ marginBottom: "0px" }}>
              Must be an actual logo of you. Logos, clip-art, group photos, and
              digitally-altered images are not allowed.
            </p>
          </div>
        </div>
      </div>

      <div className="main-progress-bar">
        {/* Progress bar */}
        {uploading && (
          <div className="progress-bar">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <img src={fileImg} alt="fileImg"  style={{marginRight:"10px", width:"30px"}}/>
              </div>

              <div>
                <p style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                  Uploading...
                </p>

                <b style={{ marginBottom: "5px" , fontSize:"12px"}}>{image.name}</b>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
          </div>
        )}
      </div>
      {error && <p className="error-message">{error}</p>}

      <hr style={{ marginTop: "30px" }} />

      <div className="form-btn">
        <button onClick={handleSaveChanges}>Save Changes</button>
      </div>
    </>
  );
}

export default CompleteCompanyImageUpload;
