import React, { useState } from "react";
import "./NotesTemplates.css";
import { ReactComponent as EditIcon } from "../../../../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/trash.svg";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Chevron icons

const NoteTemplates = () => {
  const [isExpanded, setIsExpanded] = useState(false); // Toggle state

  const note_templates = [
    {
      name: "777 - Candidate Pool - Introducing POC",
      type: "Candidate Note",
    },
    {
      name: "777 - Candidate Pool - Introducing POC",
      type: "Candidate Note",
    },
    {
      name: "777 - Candidate Pool - Introducing POC",
      subject: "Your dedicated POC at...",
      type: "Candidate Note",
    },
  ];

  return (
    <div className="section_note_templates">
      {/* Header with Chevron Toggle */}
      <div className="note-template-header" onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? <FaChevronUp style={{color:"#065fb5"}} /> : <FaChevronDown style={{color:"#065fb5"}} />} {/* Dynamic Chevron */}
        <h6 style={{ marginLeft: "8px", display: "inline-block", cursor: "pointer" }}>
          Note Template
        </h6>
      </div>

      {/* Table section (only show if expanded) */}
      {isExpanded && (
        <div className="note_templates_table">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {note_templates.map((note, index) => (
                <tr key={index}>
                  <td>{note.name}</td>
                  <td>{note.type}</td>
                  <td>
                    <EditIcon style={{ marginRight: "5px", cursor: "pointer" }} />
                    <DeleteIcon style={{ cursor: "pointer" }} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default NoteTemplates;
