import React, { useState, useEffect, useContext } from "react";
import "../Military Background/Add Military/AddMilitary.css";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";

function CompleteProfileMilitaryBackground({ candidate, onClose, onUpdate }) {
    const { keycloak } = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_URL;

    const [ranks, setRanks] = useState([]);
    const [mosList, setMosList] = useState([]);
    const [formData, setFormData] = useState({
        branch: candidate?.branch || "",
        rank: candidate?.rank.rank || {}, 
        mos: candidate?.mos.mos || {},   
        yearsServed: candidate?.yearsServed || "",
    });
    


    
    useEffect(() => {
        const fetchMOS = async () => {
            try {
                console.log("Fetching MOS...");
                const headers = {
                    Authorization: `Bearer ${keycloak.token}`,
                    "Content-Type": "application/json",
                };
                const response = await fetch(`${apiUrl}/moslist/moslist`, { headers });
                const data = await response.json();
                console.log("MOS Response:", data); // ✅ Debugging
                setMosList(data.data || []);
            } catch (error) {
                console.error("❌ Error fetching MOS:", error);
                toast.error("Failed to fetch MOS data");
            }
        };
    
        const fetchRanks = async () => {
            try {
                console.log("Fetching Ranks...");
                const headers = {
                    Authorization: `Bearer ${keycloak.token}`,
                    "Content-Type": "application/json",
                };
                const response = await fetch(`${apiUrl}/rank/rank`, { headers });
                const data = await response.json();
                console.log("Rank Response:", data); // ✅ Debugging
                setRanks(data.data || data || []);

            } catch (error) {
                console.error(" Error fetching Ranks:", error);
                toast.error("Failed to fetch Rank data");
            }
        };
    
        if (keycloak?.token) {
            fetchMOS();
            fetchRanks();
        }
    }, [apiUrl, keycloak?.token]); // ✅ yahan sirf `apiUrl` aur `keycloak?.token` rakho
    

    // ✅ Handle MOS Change
    const handleMosChange = (e) => {
        const selectedMos = mosList.find((mos) => mos._id === e.target.value);
        setFormData((prev) => ({ ...prev, mos: selectedMos || null }));
    };

    // ✅ Handle Rank Change
    const handleRankChange = (e) => {
        const selectedRank = ranks.find((rank) => rank._id === e.target.value);
        setFormData((prev) => ({ ...prev, rank: selectedRank || null }));
    };

    // ✅ Handle Submit Form
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("🚀 Submitting Form Data:", formData);

        try {
            const response = await fetch(`${apiUrl}/candidates/${candidate._id}`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) throw new Error("Failed to update candidate");

            const updatedCandidate = await response.json();
            toast.success(" Candidate updated successfully!");
            if (onUpdate) onUpdate(updatedCandidate);
            
            setTimeout(() => {
                if (onClose) onClose();
            }, 2000);
        } catch (error) {
            console.error("Error updating candidate:", error);
            toast.error("Error updating candidate: " + error.message);
        }
    };

    return (
        <div className="popup-content">
            <div className="popup-form add-military">
                {/* 🔹 Branch Selection */}
                <div className="form-field">
                    <label>Branch <span>*</span></label>
                    <select 
                        value={formData.branch} 
                        onChange={(e) => setFormData({ ...formData, branch: e.target.value })}
                    >
                        <option value="">Select Branch</option>
                        {["Army", "Navy", "Air Force"].map((branch, index) => (
                            <option key={index} value={branch}>{branch}</option>
                        ))}
                    </select>
                </div>

                {/* 🔹 MOS Selection */}
                <div className="form-field">
                    <label>MOS <span>*</span></label>
                    <select 
                        value={formData.mos?._id || ""} 
                        onChange={handleMosChange}
                    >
                        <option value="">Select MOS</option>
                        {mosList.length > 0 ? (
                            mosList.map((mos) => (
                                <option key={mos._id} value={mos._id}>{mos.mos}</option>
                            ))
                        ) : (
                            <option disabled>Loading...</option>
                        )}
                    </select>
                </div>

                {/* 🔹 Rank Selection */}
                <div className="form-field">
                    <label>Rank <span>*</span></label>
                    <select 
                        value={formData.rank?._id || ""} 
                        onChange={handleRankChange}
                    >
                        <option value="">Select Rank</option>
                        {ranks.length > 0 ? (
                            ranks.map((rank) => (
                                <option key={rank._id} value={rank._id}>{rank.rank}</option>
                            ))
                        ) : (
                            <option disabled>Loading...</option>
                        )}
                    </select>
                </div>

                {/* 🔹 Years Served Input */}
                <div className="form-field">
                    <label>Years Served <span>*</span></label>
                    <input
                        type="text"
                        placeholder="Type Here"
                        name="yearsServed"
                        value={formData.yearsServed || candidate.yearsServed}
                        onChange={(e) => setFormData({ ...formData, yearsServed: e.target.value })}
                    />
                </div>
            </div>

            {/* 🔹 Submit Button */}
            <div className="form-btn">
                <button onClick={handleSubmit} className="btn-save-changes">Save</button>
            </div>
        </div>
    );
}

export default CompleteProfileMilitaryBackground;
