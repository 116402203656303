import React, { useState } from 'react';
import './ManageDropdownOptions.css';
import { ReactComponent as EditIcon } from "../../../../assets/images/edit.svg";
import EditJobTitleChoices from "./AllEditChoice/EditJobTitleChoice/EditJobTitleChoice"; // Import Edit Component

const options = [
  'Job Title',
  'Security Clearance',
  'Certifications',
  'Target Industries',
  'Job Search Status',
  'Branch',
  'MOS',
  'Rank',
  'Years of Experience',
  'Relocation Preference'
];

function ManageDropdownOptions() {
  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <div className="dropdown-container">
   

      {/* Conditional Rendering */}
      {selectedOption === "Job Title" ? (
        <>
         
        <EditJobTitleChoices onClose={() => setSelectedOption(null)} />
        </>
      ) : (
// Manager Drop Down Default Section
        <>
           <div className="header_profile">
            <div className="title_choice">
            <h2 className="dropdown-title">Manage Dropdown Options</h2>
            </div>
        
      </div>

      <div className="dropdown-list">
          {options.map((option, index) => (
            <div key={index} className="dropdown-item">
              <span>{option}</span>
              <button
                className="edit-button"
                onClick={() => setSelectedOption(option)}
              >
                <EditIcon />
              </button>
            </div>
          ))}
        </div>
        </>
     
      )}
    </div>
  );
}

export default ManageDropdownOptions;
