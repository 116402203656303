import React, { useState, useEffect } from "react";
import "./MilitaryBackground.css";
import { ReactComponent as MilitaryIcon } from "../../../assets/images/military.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusCircle.svg";
import AddMilitary from "./Add Military/AddMilitary";  // ✅ Ensure correct import path
import { ReactComponent as EditIcon } from "../../../assets/images/icon-edit-line.svg";

function MilitaryBackground({ candidate = {}, hideEditIcon }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedCandidate, setUpdatedCandidate] = useState(candidate || {}); // ✅ Default empty object

  useEffect(() => {
    // console.log("Candidate data received:", candidate);  // ✅ Debugging
    if (candidate && Object.keys(candidate).length > 0) {
      setUpdatedCandidate(candidate);
    }
  }, [candidate]);

  const handleUpdate = (updatedData) => {
    setUpdatedCandidate((prevState) => ({
      ...prevState,
      branch: updatedData?.branch || prevState?.branch || "N/A",
      rank: updatedData?.rank || prevState?.rank || "N/A",
      mos: updatedData?.mos || prevState?.mos || "N/A",
      yearsServed: updatedData?.yearsServed || prevState?.yearsServed || "N/A",
    }));
  };

  const toggleMilitaryBackground = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // ✅ **Ensure safe default value**
  const militaryData = updatedCandidate && Object.keys(updatedCandidate).length > 0 
    ? updatedCandidate 
    : { branch: "N/A", rank: "N/A", mos: "N/A", yearsServed: "N/A" };

  // console.log("Final militaryData:", militaryData); // ✅ Debugging

  return (
    <div className="target_industries">
      <h4>Military Background</h4>
      {!isModalOpen ? (
        <div>
          {Object.keys(militaryData).length === 0 ? (
            <div className="empty-state">
              <div className="plusIcon" onClick={toggleMilitaryBackground}>
                <PlusIcon />
              </div>
              <div className="emptyIcon">
              <MilitaryIcon />
              </div>
              <p>
                Your military service is valuable! Fill in your branch, rank, and MOS to help employers understand your background.
              </p>
              <button onClick={toggleMilitaryBackground}>Add Military Background</button>
            </div>
          ) : (
            <div className="exist-state">
              {!hideEditIcon && (
                <div className="editIcon" onClick={toggleMilitaryBackground}>
                  <EditIcon />
                </div>
              )}
  <div className="military-values">
  <div className="branch-value">
    <p>Military Branch</p>
    <span><b>{militaryData?.branch || updatedCandidate?.branch || "N/A"}</b></span>
  </div>
  <div className="branch-value">
    <p>Rank</p>
    <span><b>{Array.isArray(militaryData?.rank) && militaryData.rank.length > 0 
      ? militaryData.rank[0].rank 
      : updatedCandidate?.rank || "N/A"}</b></span>
  </div>
  <div className="branch-value">
    <p>MOS</p>
    <span><b>{Array.isArray(militaryData?.mos) && militaryData.mos.length > 0
      ? militaryData.mos[0].mos
      : updatedCandidate?.mos || "N/A"}</b></span>
  </div>
  <div className="branch-value">
    <p>Years Served</p>
    <span><b>{militaryData?.yearsServed || updatedCandidate?.yearsServed || "N/A"}</b></span>
  </div>
</div>


            </div>
          )}
        </div>
      ) : (
        <AddMilitary
          isOpen={isModalOpen}
          onClose={closeModal}
          candidate={updatedCandidate}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  );
}

export default MilitaryBackground;
