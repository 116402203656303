import React, { useState } from "react";
import "./Interviews.css";

const Interviews = () => {
  const [activeTab, setActiveTab] = useState("Upcoming");
  const [expandedCards, setExpandedCards] = useState({}); // State to track expanded cards

  // Static data
  const interviews = [
    {
      id: "ID23434",
      attendees: [
        {
          name: "Jethro Jamero",
          profileUrl:
            "https://media.licdn.com/dms/image/v2/D5603AQHKXYaNGVpW_w/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1732514966132?e=1743638400&v=beta&t=mT5lTn6bas0chPUtPTJD7WyVwhtr7GCuL076eUnIgmA",
        },
        {
          name: "Arsalan Sheikh",
          profileUrl:
            "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_original/v1/attachments/profile/photo/1bf034bd201ec98d522982923a1e60b5-855180091707311068472/JPEG_20240207_180427_1242289348973385449.jpg",
        },
      ],
      date: "2025-01-28T12:00:00Z",
      time: "12:00pm - 12:30pm",
      status: "Accepted",
      link: "https://us02web.zoom.us/rec/share/",
      createdAt: "2024-08-03T00:00:00Z",
    },
    {
      id: "ID56789",
      attendees: [
        {
          name: "Jethro Jamero",
          profileUrl:
            "https://media.licdn.com/dms/image/v2/D5603AQHKXYaNGVpW_w/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1732514966132?e=1743638400&v=beta&t=mT5lTn6bas0chPUtPTJD7WyVwhtr7GCuL076eUnIgmA",
        },
        {
          name: "Arsalan Sheikh",
          profileUrl:
            "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_original/v1/attachments/profile/photo/1bf034bd201ec98d522982923a1e60b5-855180091707311068472/JPEG_20240207_180427_1242289348973385449.jpg",
        },
      ],
      date: "2024-01-15T14:00:00Z",
      time: "12:00pm - 12:30pm",
      status: "Pending",
      link: "https://us02web.zoom.us/rec/share/",
      createdAt: "2024-08-03T00:00:00Z",
    },
  ];

  // Filter interviews based on the active tab
  const filteredInterviews = interviews.filter((interview) => {
    const now = new Date();
    const interviewDate = new Date(interview.date);

    if (activeTab === "Upcoming") return interviewDate > now;
    if (activeTab === "Past") return interviewDate < now;
    return true; // For Pending, Cancelled, etc.
  });

  // Toggle expanded state for a specific interview
  const toggleDetails = (id) => {
    setExpandedCards((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div className="interviews-container">
      {/* Tabs */}
      <div className="tabs">
        {["Upcoming", "Pending", "Past", "Cancelled"].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`tab-button ${activeTab === tab ? "active-tab" : ""}`}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Interviews List */}
      <div className="interviews-list">
        {filteredInterviews.map((interview) => (
          <div key={interview.id} className="interview-card">
            <div className="header-card">
              <p className="interview-date">
                {new Date(interview.date).toLocaleDateString("en-US", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            </div>

            <div className="short_meeting_details">
              <div className="meeting_date">
                <p>{interview.time}</p>
              </div>

              <div className="meeting_with">
                <p>
                  Meeting With{" "}
                  <img
                    src={interview.attendees[1].profileUrl}
                    alt={interview.attendees[1].name}
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                    }}
                  />
                  <span>{interview.attendees[1].name}</span>
                </p>
              </div>

              <div className="meeting_status">
                <p>{interview.status}</p>
              </div>

              <div className="meeting_details">
                <p
                  onClick={() => toggleDetails(interview.id)}
                  style={{ cursor: "pointer", color: "#034A8C" }}
                >
                  {expandedCards[interview.id]
                    ? "Less Details"
                    : "More Details"}
                </p>
              </div>
            </div>

            {/* Details Card */}
            {expandedCards[interview.id] && (
              <div className="meeting_details_card">
                <div className="card-body">
                  <h6>Attendees</h6>
                  <div
                    className="attendees"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <div>
                      <img
                        src={interview.attendees[0].profileUrl}
                        alt={interview.attendees[0].name}
                        style={{
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          marginRight: "10px",
                        }}
                      />
                      <span
                        style={{
                          fontWeight: "600",
                          color: "#4A4D50",
                          fontSize: "14px",
                        }}
                      >
                        {interview.attendees[0].name}
                      </span>
                    </div>

                    <div>
                      <img
                        src={interview.attendees[1].profileUrl}
                        alt={interview.attendees[1].name}
                        style={{
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          marginRight: "10px",
                        }}
                      />
                      <span
                        style={{
                          fontWeight: "600",
                          color: "#034A8C",
                          fontSize: "14px",
                        }}
                      >
                        {interview.attendees[1].name}
                      </span>
                    </div>
                  </div>

                  <h6 style={{ paddingTop: "15px" }}>Meeting Link</h6>
                  <a
                    href={interview.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#034A8C" }}
                  >
                    {interview.link}
                  </a>

                  <h6 style={{ paddingTop: "10px" }}>Date Created</h6>
                  <p>
                    {new Date(interview.createdAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                </div>

                <div className="card-actions">
                  <button className="join-meeting">Join Meeting</button>
                  <button className="add-to-calendar">Add to calendar</button>
                  <button className="reschedule">Reschedule</button>
                  <button className="cancel">Cancel</button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Interviews;
