import React, { useState, useEffect, useRef } from "react";
import "./JobSearchStatusFilter.css";

import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";
import { ReactComponent as IconSearch } from "../../../assets/images/icon-search-fill.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";

const JobSearchStatusFilter = ({
  isOpen,
  onClose,
  candidateData,
  onSelectStatus,
  jobSearchfilterType,
  setJobSearchfilterType,
  selectedStatus,
  setSelectedStatus,
  onDelete,
  setFilteredData,
  fetchFilteredCandidates // Ensure function is passed as a prop
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [displayStatus, setIsDisplayStatus] = useState("");

  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      console.log("OutsideClick");
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const handleDone = () => {
    if (searchTerm && searchTerm.length >= 2) {
      setSelectedStatus(searchTerm);
      onSelectStatus(searchTerm);
      setIsDisplayStatus(searchTerm);
      setIsConfirmed(true);
    }
  };

  const handleEdit = () => {
    setIsConfirmed(false);
  };

  useEffect(() => {
    if (selectedStatus === null || selectedStatus === "") {
      console.log("Status is null, resetting table...");
      setFilteredData([]);
    }
  }, [selectedStatus]);

  const handleDelete = () => {
    console.log("Deleting status filter...");
    setSelectedStatus(null);
    setSearchTerm("");
    setIsConfirmed(false);
    onDelete(null);
  };

  // Handle filter change and fetch new data
  const handleFilterTypeChange = (event) => {
    setJobSearchfilterType(event.target.value);
  
  };

  if (!isOpen) return null;

  return (
    <div className="jobsearch-status-modal-main">
      <div className="jobsearch-status-modal-content" ref={modalRef}>
        {isConfirmed ? (
          <div className="confirmation-view">
            <div className="selected_header">
              <div>
                <h6>
                  <IconSearch /> Job Search Status
                </h6>
              </div>
              <div className="manage-icons">
                <EditIcon onClick={handleEdit} />
                <DeleteIcon onClick={handleDelete} />
              </div>
            </div>
            <p className="selected-status-name">
              {jobSearchfilterType} {displayStatus}
            </p>
          </div>
        ) : (
          <>
            <div className="filter-options">
              <h6>
                <IconSearch /> Job Search Status
              </h6>
              <div className="status-list-radio-buttons">
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is one of"
                    checked={jobSearchfilterType === "is one of"}
                    onChange={handleFilterTypeChange}
                  />
                  Is one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not one of"
                    checked={jobSearchfilterType === "is not one of"}
                    onChange={handleFilterTypeChange}
                  />
                  Is not one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is set"
                    checked={jobSearchfilterType === "is set"}
                    onChange={handleFilterTypeChange}
                  />
                  Is set
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not set"
                    checked={jobSearchfilterType === "is not set"}
                    onChange={handleFilterTypeChange}
                  />
                  Is not set
                </label>
              </div>
            </div>
            <input
              type="text"
              placeholder="Search Job Status"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="status-search-bar"
            />
            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default JobSearchStatusFilter;
