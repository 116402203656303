import React, { useState } from "react";
import "./AddJobTitlePopup.css"; // Add styles

const AddJobTitlePopup = ({ onClose, onAdd }) => {
  const [inputValue, setInputValue] = useState("");

  const handleAdd = () => {
    const newTitles = inputValue
      .split(",") // Split input by comma
      .map(title => title.trim()) // Trim spaces
      .filter(title => title !== ""); // Remove empty entries

    if (newTitles.length > 0) {
      onAdd(newTitles); // Pass new job titles to parent
      setInputValue("");
      onClose(); // Close modal
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content add-title-popup-content">
          <div className="add_popup_title">
          <h2>Add Choices</h2>
        <p>Add multiple choices separated by a comma.</p>
        <textarea
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Enter job titles separated by commas"
          className="popup-textarea"
        />
          </div>
        <div className="popup-actions">
          <button onClick={onClose} className="cancel-button">X</button>
          <button onClick={handleAdd} className="add-button">Add</button>
        </div>
      </div>
    </div>
  );
};

export default AddJobTitlePopup;
