import React, { useState, useEffect,useContext } from "react";
import "./RecentActivities.css";
import { AuthContext } from "../../../context/AuthContext";

function RecentActivites({ candidate }) {
  const { keycloak } = useContext(AuthContext);
  // State to store the activities data
  const [activities, setActivities] = useState([]);

  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Format options for the date and time
    const options = {
      month: "short", // e.g. "Nov"
      day: "numeric", // e.g. "19"
      hour: "numeric", // e.g. "7"
      minute: "numeric", // e.g. "00"
      hour12: true, // 12-hour format
    };

    // Create the formatted date string
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    // Add 'at' and the formatted time (with AM/PM)
    const time = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return `${formattedDate}`;
  };

  // Fetch activities on component mount or when candidate._id changes
  useEffect(() => {
    if (!candidate || !candidate._id) {
      return; // Prevent fetching if candidate or candidate._id is not available
    }
  
    fetch(`${process.env.REACT_APP_API_URL}/candidates/${candidate._id}/activities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloak.token}`, // Authentication token
      },
    })
      .then((response) => response.json()) // Parse JSON response
      .then((data) => setActivities(data)) // Update state with the activities data
      .catch((error) => console.error("Error fetching activities:", error));
  
  }, [candidate?._id]); // Re-fetch when candidate._id changes
  

  return (
    <div>
      <div className="recentactivity_compontent">
        <div className="activity_main_bar">
          <h6>Recent Activity</h6>
          <p>Filter Activities</p>
        </div>

        {/* Map through the activities and display them */}
        {activities.length > 0 ? (
          activities.map((activity, index) => (
            <div key={index} className="activities_box">
              <div className="box_main_bar">
                <h6 className="activity_heading">Profile Updated</h6>

                <div className="profile_info_img">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${activity?.headshotUrl}`}
                    className="profile_img_activity"
                    alt="Profile"
                  />
                  <h6>{activity?.candidateName}</h6>
                </div>

                {/* Format and display the date */}
                <h6 className="date_time">{formatDate(activity?.createdAt)}</h6>
              </div>

              <div className="activities_desc">
                <p>{activity.action}</p>
              </div>
            </div>
          ))
        ) : (
          <p>No activities available.</p>
        )}
      </div>
    </div>
  );
}

export default RecentActivites;
