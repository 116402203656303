import React, { useState, useContext } from "react";
import axios from "axios";
import "./UpdateAdminProfile.css";
import { FaCheck, FaTimes } from "react-icons/fa";
import { ReactComponent as UploadIcon } from "../../../../assets/images/uploadIcon.svg";
import { AuthContext } from "../../../../context/AuthContext";

function AdminUpdateProfile({ setEditProfile, admin,setAdmin }) {
  const { keycloak } = useContext(AuthContext); // Get Keycloak instance
  const [formData, setFormData] = useState(admin);
  const [profileImage, setProfileImage] = useState(null); // Store selected image file
  const [previewImage, setPreviewImage] = useState(admin.headshotLink); // Store preview image URL

  // Handle Input Changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle Image Selection (Update Preview but don't Upload)
  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      setPreviewImage(URL.createObjectURL(file)); // Set preview image
    }
  };

  // Handle Save (Upload Image if Selected + Update Profile)


  const handleSave = () => {
    if (!formData._id) {
      console.error("Missing _id, unable to update profile.");
      return;
    }
  
    const { headshotLink, ...updatedData } = { 
      ...formData, 
      name: `${formData.fname} ${formData.lname}`.trim() 
    };
    axios
      .put(`/api/admin/${formData._id}`, updatedData, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const updatedAdmin = response.data; // Get the latest admin data
  
        // If there's an image to upload, process it
        if (profileImage) {
          return uploadProfileImage(profileImage, formData._id).then((imageUrl) => {
            updatedAdmin.headshotLink = imageUrl; // Update headshotLink with uploaded image URL
            return updatedAdmin;
          });
        }
        return updatedAdmin; // Return admin data if no image upload is needed
      })
      .then((finalAdminData) => {
        // console.log('finalAdminData::::::::',finalAdminData);
        setAdmin({
          _id: finalAdminData.admin._id ?? "",
          fname: finalAdminData.admin.name?.split(" ")[0] ?? "",
          lname: finalAdminData.admin.name?.includes(" ") ? finalAdminData.admin.name.split(" ").slice(1).join(" ") : "",
          email: finalAdminData.admin.email ?? "",
          phone: finalAdminData.admin.phone ?? "",
          calendlyLink: finalAdminData.admin.calendlyLink ?? "",
          sub: finalAdminData.admin.sub ?? "",
          headshotLink: finalAdminData.admin.headshotLink ?? "",
        });
  
        alert("Profile updated successfully!");
        setEditProfile(false);
      })
      .catch((err) => console.error("Error updating profile:", err));
  };
  
  

  // Upload Image Function
  const uploadProfileImage = (imageFile, adminId) => {
    const formData = new FormData();
    formData.append("headshotLink", imageFile);

    return axios
      .post(`/api/admin/${adminId}`, formData, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data.imageUrl)
      .catch((err) => {
        console.error("Error uploading image:", err);
        return null;
      });
  };

  return (
    <div className="updated-profile-container">
      <h2 className="profile-title">Personal Information</h2>
      <p className="profile-description">Manage your personal details and connect your email account.</p>

      <div className="profile-section">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* Profile Photo Upload */}
          <div className="profile-photo-container">
            <div className="profile_photo_admin">
              {previewImage ? (
                <img src={previewImage} alt="Profile" className="profile_image" />
              ) : (
                <div className="ProfileDefaultImg">
                  <h2>{formData?.firstName?.charAt(0) || "N"}</h2>
                </div>
              )}
            </div>
            <label className="upload-label">
              Upload new photo
            </label>
          </div>

          {/* File Upload Box */}
          <div className="file-upload-box" style={{ cursor: "pointer" }} onClick={() => document.getElementById("fileUploadInput").click()}>
            <UploadIcon style={{ width: "50px", height: "50px" }} />
            <p className="file-upload-text">Drag & drop files or <span className="browse">Browse</span></p>
            <p className="file-support">Supported formats: JPG, PNG</p>

            {/* Hidden File Input */}
            <input type="file" id="fileUploadInput" accept="image/*" style={{ display: "none" }} onChange={handleImageSelect} />
          </div>
        </div>
      </div>

      {/* Form Fields */}
      <div className="form-group">
        <div className="form-field">
          <label>First Name</label>
          <input type="text" name="fname" value={formData.fname} onChange={handleChange} placeholder="Type here" />
        </div>
        <div className="form-field">
          <label>Last Name</label>
          <input type="text" name="lname" value={formData.lname} onChange={handleChange} placeholder="Type here" />
        </div>
      </div>

      <div className="form-group">
        <div className="form-field">
          <label>Email</label>
          <input type="text" value={formData?.email || "demo@7eagle.com"} disabled className="disabled-input" />
        </div>
        <div className="form-field">
          <label>Phone <span className="required">*</span></label>
          <input type="text" name="phone" value={formData.phone} onChange={handleChange} placeholder="Type here" />
        </div>
      </div>

      <div className="form-field">
        <label>Calendly Link</label>
        <input type="text" name="calendlyLink" value={formData.calendlyLink} onChange={handleChange} placeholder="Type here" />
      </div>

      {/* Action Buttons */}
      <div className="action-buttons">
        <button className="cancel-btn" onClick={() => setEditProfile(false)}>
          <FaTimes style={{ marginRight: "5px" }} /> Cancel
        </button>
        <button className="save-btn" onClick={handleSave}>
          <FaCheck style={{ marginRight: "5px" }} /> Save
        </button>
      </div>
    </div>
  );
}

export default AdminUpdateProfile;
