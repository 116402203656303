import React, { useState, useEffect } from "react";
import "./AffiliateFilter.css";
import { ReactComponent as IconUser } from "../../../assets/images/icon-user-fill.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";

const AffiliateFilter = ({
  isOpen,
  onClose,
  onSelectAffiliate,
  affiliateFilterType,
  setAffiliateFilterType,
  onDelete,
  setFilteredData,
  fetchFilteredAffiliates,
  selectedAffiliate,
  setSelectedAffiliate
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAffiliateValue, setSelectedAffiliateValue] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleDone = () => {
    if (searchTerm.trim().length >= 2) {
      setSelectedAffiliate(searchTerm);
      onSelectAffiliate(searchTerm);
      setSelectedAffiliateValue(searchTerm);
      setIsConfirmed(true);
    }
  };

  const handleEdit = () => {
    setIsConfirmed(false);
  };

  const handleDelete = () => {
    setSelectedAffiliate(null);
    setSearchTerm("");
    setIsConfirmed(false);
    setFilteredData([]);
    onDelete(null);
  };

  useEffect(() => {
    if (selectedAffiliate === null || selectedAffiliate === "") {
      setFilteredData([]);
    }
  }, [selectedAffiliate]);

  const handleFilterTypeChange = (event) => {
    setAffiliateFilterType(event.target.value);
    fetchFilteredAffiliates();
  };

  if (!isOpen) return null;

  return (
    <div className="affiliate-filter-modal-main">
      <div className="affiliate-filter-modal-content">
        {isConfirmed ? (
          <div className="confirmation-view">
            <div className="selected_header">
              <h6>
                <IconUser /> Affiliate
              </h6>
              <div className="manage-icons">
                <EditIcon onClick={handleEdit} />
                <DeleteIcon onClick={handleDelete} />
              </div>
            </div>
            <p className="selected-affiliate-name">
              {affiliateFilterType} {" "} {selectedAffiliateValue}
            </p>
          </div>
        ) : (
          <>
            <div className="filter-options">
              <h6>
                <IconUser /> Affiliate
              </h6>
              <div className="affiliate-list-radio-buttons" style={{ paddingTop: "10px" }}>
                {["is one of", "is not one of", "is set", "is not set"].map((filter) => (
                  <label key={filter}>
                    <input
                      type="radio"
                      name="filter"
                      value={filter}
                      checked={affiliateFilterType === filter}
                      onChange={handleFilterTypeChange}
                    />
                    {filter}
                  </label>
                ))}
              </div>
            </div>
            <input
              type="text"
              placeholder="Enter Affiliate"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="affiliate-search-bar"
            />
            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AffiliateFilter;
