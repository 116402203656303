import React, { useState, useEffect } from "react";
import "./AddNewCandidateResume.css";
import { ReactComponent as UploadIcon } from "../../../assets/images/uploadIcon.svg";
import fileImg from "../../../assets/images/pdfIcon.svg";
import axios from "axios";

function AddNewCandidateResume({ setFileInParent }) {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  
console.log("file pdf,",file)
  const validateFile = (selectedFile) => {
    console.log("Selected file type:", selectedFile.type); // Debug log
    if (
      selectedFile &&
      [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(selectedFile.type)
    ) {
      setError("");  // Clear error if the file is valid
      setFile(selectedFile);
      setFileInParent(selectedFile);
    } else {
      setError("You can’t upload this file. Only .pdf, .doc, and .docx formats are allowed.");
      setFile(null);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    console.log("file pdf selectedFile ",selectedFile);
    if (selectedFile) {
      validateFile(selectedFile);
      console.log("File selected from input:", selectedFile.name);  // Log file name after selection from input
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    console.log("file pdf droppedFile ",droppedFile);
    if (droppedFile) {
      validateFile(droppedFile);
      console.log("File dropped:", droppedFile.name);  // Log file name after drop
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      setError("Please select a file first.");
      return;
    }

    setIsLoading(true);
    setSuccessMessage("");

    const formData = new FormData();
    formData.append("resumeLink", file);

    try {
      const response = await axios.post(
        `${apiUrl}/candidates/uploadResume/67b1be8c10e457890596b95c/Arsalan`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      if (response.status !== 200) {
        throw new Error("Failed to upload file.");
      }

      setSuccessMessage("File uploaded successfully!");
    } catch (error) {
      setError(`Error: ${error.response?.data?.message || error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setFileUrl(objectUrl);
      console.log("File selected:", file.name);  // Log file name after selection
      console.log("File URL:", objectUrl);  // Log the URL of the selected file

      return () => URL.revokeObjectURL(objectUrl); // Cleanup URL when component unmounts or file changes
    }
  }, [file]);
  return (
    <>
    <p
      style={{
        fontSize: "14px",
        fontWeight: "600",
        marginBottom: "5px",
        marginTop: "5px",
      }}
    >
      Resume
    </p>
    <div className="row" style={{ alignItems: "center" }}>
      <div className="col-lg-6">
        <div
          className="upload-box upload_new_candidate_resume"
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          {file ? (
   <div className="file-preview">
   {file.type === "application/pdf" ? (
     <iframe
       src={URL.createObjectURL(file)}
       width="100%"
       height="300px"
       style={{ border: "none" }}
     ></iframe>
   ) : (
     <>
       <img src={fileImg} alt="File Icon" className="fileIcon" />
       <p>{file.name}</p>
     </>
   )}
 </div>
          ) : (
            <>
           <UploadIcon/>
              <p
                style={{
                  padding: "10px",
                  fontWeight: "600",
                  fontSize: "16px",
                  paddingBottom: "0px",
                  margin: "0px",
                }}
              >
                Drag & drop files or{" "}
                <label
                  htmlFor="file-upload"
                  className="browse-link"
                  style={{ cursor: "pointer " }}
                >
                  <b> Browse</b>
                </label>
              </p>
              <p style={{ fontSize: "12px" }}>
                Supported formats: .pdf, .doc, .docx
              </p>
            </>
          )}
          <input
            type="file"
            id="file-upload"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
      </div>

      <div className="col-lg-6"></div>
    </div>

    {error && <p className="error-message" style={{width:"49%", margin:" 0 0px 14px"}}>{error}</p>}
</>
  );
}

export default AddNewCandidateResume;
