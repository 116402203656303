import React, { useState } from "react";
import "./CandidateDetailsPopup.css";
import CandidateInfo from "../Candidate Info/CandidateInfo";
import JobSearchStatus from "../Job Search Status/JobSearchStatus";
import Resume from "../Resume/ReductedResume";
import AboutCandidate from "../CandidateAbout/AboutCandidate";
import Certification from "../Certifications/Certification";
import { ReactComponent as CloseIcon } from "../../../assets/images/close.svg";  
import EmailEditor from "../Email Sender/EmailEditor";
import NotesEditor from "../Notes Editor/NotesEditor";
import RecentActivites from "../Recent Activites/RecentActivites";
import CompaniesJobs from "../../Companies/Companies Jobs/CompaniesJobsListing";
import NotesList from "../Notes List/NotesList";
import TargetIndustries from "../Target Industries/TargetIndustries";
import MilitaryBackground from "../Military Background/MilitaryBackground";
import UploadFiles from "../Upload Files/UploadFiles";
import FileList from "../File Lists/FileList";
import SubmitToCompanyForm from "../SubmitToCompany/SubmitToCompany";
import UploadOriginalResume from "../Resume/UploadResume/UploadOriginalResume";
import UploadResumeFile from "../Resume/UploadResume/UploadResumeFile";
import ResumeList from "../ResumeList/ResumeList";
import OriginalResume from "../Resume/OriginalResume";

const CandidateDetailsPopup = ({
  candidate: initialCandidate,
  onClose,
  admin,
}) => {
  const [candidate, setCandidate] = useState(initialCandidate);
  const [activeTab, setActiveTab] = useState("email");
  const [showViewResume, setShowViewResume] = useState(false);
  const [showViewReductedResume, setShowViewReductedResume] = useState(false);

  const [redactedResumeSrc, setRedactedResumeSrc] = useState(candidate.redactedResumeLink);
  const [OriginalResumeSrc, setOriginalResumeSrc] = useState(candidate.resumeLink);

  const [activeSection, setActiveSection] = useState("activity");
  const [isSubmitCompanyModalOpen, setIsSubmitCompanyModalOpen] =
    useState(false);
  if (!candidate) return null;

  const toggleSubmitForm = () => {
    setIsSubmitCompanyModalOpen(!isSubmitCompanyModalOpen);
  };

  const handleGoBack = () =>{
    setShowViewResume(false);
    setShowViewReductedResume(false);
  }
  const handleViewResume = (originalresumeUrl) => {
    setRedactedResumeSrc(originalresumeUrl); 
    setShowViewResume(!showViewResume);
    setActiveSection("files");
  };
  
  // const handleReductedViewResume = () => {
  //   setShowViewReductedResume(!showViewReductedResume);
  //   setActiveSection("files");
  // };
  

  const handleReductedViewResume = (resumeURL) => {
    setRedactedResumeSrc(resumeURL); // Updated ya original resume set karo
    setShowViewReductedResume(true);
    setActiveSection("files");
  };

  const handleCandidateUpdate = (updatedCandidate) => {
    setCandidate(updatedCandidate); // Update candidate state dynamically
  };

  return (
    <>
      <div className="overlay"></div>
      <div className={`candidate-details-popup ${candidate ? "show" : ""}`}>
        <div className="popup-content">
          <button className="close-button" onClick={onClose}>
          <CloseIcon style={{ width: '30px !important', height: '30px !important' }} />
            
          </button>

          <div className="row profile_row">
            <div className="col-lg-4 col-md-4 col-12 left_column">
              <div className="popup_left_profile">
                <CandidateInfo
                  candidate={candidate}
                  toggleSubmitForm={toggleSubmitForm}
                />

                <JobSearchStatus
                  candidate={candidate}
                  onUpdate={handleCandidateUpdate} // Pass update handler
                />
                <OriginalResume candidate={candidate} onViewResume={handleViewResume}/>
                <Resume candidate={candidate} onViewReductedResume={handleReductedViewResume} />
                <AboutCandidate candidate={candidate} />
                
                <Certification candidate={candidate} />
                <TargetIndustries candidate={candidate} />
                <MilitaryBackground candidate={candidate} />
              </div>
            </div>

            <div className="col-lg-8 col-md-8 col-12">
              <div className="popup_right_profile">
                {isSubmitCompanyModalOpen && (
                  <SubmitToCompanyForm candidate={candidate} />
                )}

                {!isSubmitCompanyModalOpen && (
                  <>
                    {/* Tab Buttons */}
                    <div className="tab-buttons">
                      <button
                        className={activeTab === "email" ? "active" : ""}
                        onClick={() => setActiveTab("email")}
                      >
                        Email
                      </button>
                      <button
                        className={activeTab === "notes" ? "active" : ""}
                        onClick={() => setActiveTab("notes")}
                      >
                        Notes
                      </button>
                    </div>

                    {/* Render components based on the active tab */}
                    {activeTab === "email" && (
                      <EmailEditor candidate={candidate} />
                    )}
                    {activeTab === "notes" && (
                      <NotesEditor candidate={candidate} />
                    )}

                    {/* Section Buttons */}
                    <div className="recent-section-buttons">
                      <button
                        className={activeSection === "activity" ? "active" : ""}
                        onClick={() => setActiveSection("activity")}
                      >
                        Recent Activity
                      </button>
                      <button
                        className={activeSection === "jobs" ? "active" : ""}
                        onClick={() => setActiveSection("jobs")}
                      >
                        Companies & Jobs
                      </button>

                      <button
                        className={
                          activeSection === "interviews" ? "active" : ""
                        }
                        onClick={() => setActiveSection("interviews")}
                      >
                        Interviews
                      </button>

                      <button
                        className={activeSection === "files" ? "active" : ""}
                        onClick={() => setActiveSection("files")}
                      >
                        Files
                      </button>

                      <button
                        className={activeSection === "notes" ? "active" : ""}
                        onClick={() => setActiveSection("notes")}
                      >
                        Notes
                      </button>
                    </div>

                    {/* Render components based on the active section */}
                    {activeSection === "activity" && (
                      <RecentActivites candidate={candidate} />
                
                    )}
                    {activeSection === "jobs" && (
                      <CompaniesJobs candidate={candidate} />
                    )}

                    {activeSection === "notes" && (
                      <NotesList candidate={candidate} admin={admin} />
                    )}

                    {/* {activeSection === "files" && (
                      <>
                        <div className="offer_letter_section">
                          <h6>Upload supporting documents</h6>
                          <UploadFiles candidate={candidate} />
                          <FileList candidate={candidate} />
                        </div>

                        <div className="upload_resume_section">
                          <h6>Resume File</h6>
                          <ResumeList candidate={candidate} />
                        </div>
                      </>
                    )} */}


{activeSection === "files" && (
  <>
    {showViewReductedResume ? (
      <div className="ViewResume">
        <div className="view_resume_iframe">
          <div className="resume_header">
            <div className="go_btn">
              <button onClick={handleGoBack}>&lt; Go Back</button>
            </div>
            <div className="ResumeTitle">{candidate.fname}-Reducted-Resume.pdf</div>
            <div></div>
          </div>
          <iframe 
           src={redactedResumeSrc}
            title="Resume" 
            style={{ width: "100%", height: "600px", borderRadius: "10px" }} 
          />
        </div>
      </div>
    ) : showViewResume ? (
      <div className="ViewResume">
        <div className="view_resume_iframe">
          <div className="resume_header">
            <div className="go_btn">
              <button onClick={handleGoBack}>&lt; Go Back</button>
            </div>
            <div className="ResumeTitle">{candidate.fname} - Resume.pdf</div>
            <div></div>
          </div>
          <iframe 
            src={OriginalResumeSrc} 
            title="Resume" 
            style={{ width: "100%", height: "600px", borderRadius: "10px" }} 
          />
        </div>
      </div>
    ) : (
      <div className="multiple_files">
        <div className="offer_letter_section">
          <h6>Upload supporting documents</h6>
          <UploadFiles candidate={candidate} />
          <FileList candidate={candidate} />
        </div>
        <div className="upload_resume_section">
          <h6>Resume File</h6>
          <ResumeList candidate={candidate} />
        </div>
      </div>
    )}
  </>
)}

                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateDetailsPopup;
