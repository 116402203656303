import React, { useState } from "react";
import { ReactComponent as Logo } from "../../assets/images/logo-7eagle.svg";
import useAdmin from '../Common/useAdmin';  // Import the custom hook
import Users from "../../components/Admin/Settings/Users/Users";
import Roles from "../../components/Admin/Settings/Roles/Roles";
import LeftTabs from "../../components/Admin/Settings/LeftTabs/LeftTabs";
import Header from "../../components/Common/Header/Header";
import Sidebar from "../../components/Common/Sidebar/Sidebar";
import "./SettingPages.css";
import Templates from "../../components/Admin/Settings/Templates/Templates";
import AdminProfile from "../../components/Admin/Settings/AdminProfile/AdminProfile";
import ManageDropdownOptions from "../../components/Admin/Settings/ManageDropdownOptions/ManageDropdownOptions";




// Main Settings Page
const SettingPages = () => {
  const { admin, loading,setAdmin } = useAdmin();
  const [activeTab, setActiveTab] = useState("Users");
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);


  const handleToggleSidebar = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };


    if (loading) {
        return (
          <div className="loading-container">
            <Logo style={{width:"150px"}}/>
            <div className="spinner"></div>
            <div>
              <h6>Please Wait..</h6>
            </div>
          </div>
        );
    }
  return (
    <div className="setting-page">
    <Header admin={admin}/>

    <div className="row dashboardrow" style={{ minHeight: "100vh" }}>
      <div
        className={`col-lg-2 col-md-2 col-sm-4 col-4 sidebar ${
          isSidebarCollapsed ? "sidebarCollapsed" : ""
        }`}
      >
        <Sidebar onToggleSidebar={handleToggleSidebar} admin={admin} />
      </div>
      <div
        className={`col-lg-10 col-md-10 col-sm-8 col-8 sidebar-column contentbar ${
          isSidebarCollapsed ? "contentExpanded" : ""
        }`}
      >
        <main>
          <div className="main_heading_dashboardd">
            <div className="content">
              <h4 style={{ color: "black" }}>Settings</h4>

              <div className="setting-section">
  
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-4 col-12">
            <LeftTabs activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>

          <div className="col-lg-9 col-md-9 col-sm-8 col-12">
            <div className="content">
              {activeTab === "Users" && <Users />}
              {activeTab === "Roles" && <Roles />}
              {activeTab === "Profile" && <AdminProfile admin={admin} setAdmin={setAdmin}/>} 
              {activeTab === "Templates" && <Templates />}
              {activeTab === "Manage Dropdown" && <ManageDropdownOptions/>}
            </div>
          </div>
        </div>
      </div>

            </div>
          </div>
        </main>
      </div>
    </div>
  
  </div>
  );
};

export default SettingPages;
