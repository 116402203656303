import AddFilterModal from "./AddNewFilter";
import AffiliateFilter from "./AffiliateFilter";
import CandidateTypeFilter from "./CandidateTypeFilter";
import CertificationFilter from "./CertificationFilter";
import TargetIndustriesFilter from "./IndustriesFilter";
import IsHotCandidateFilters from "./isHotCandidateFilter";
import JobSearchStatusFilter from "./JobSearchStatusFilter";
import ManagerFilter from "./ManagerFilter";
import MilitaryBranchFilter from "./MilitaryBranchFilter";
import RelocationFilter from "./RelocationFilter";
import SecurityClearanceFilter from "./SecurityClearanceFilter";
import TextSearchFilter from "./TextSearchFilter";

import React, { useState } from "react";
const AllFiltersModal = ({
    isFilterModalOpen,
    onDelete,
    setIsFilterModalOpen,
    isManagerModalOpen,
    setIsManagerModalOpen,
    isTextSearchModalOpen,
    setIsTextSearchModalOpen,
    isCandidateTypeModalOpen,
    setIsCandidateTypeModalOpen,
    isMilitaryBranchModalOpen,
    setMilitaryBranchModalOpen,
    isJobStatusModalOpen,
    setIsJobStatusModalOpen,
    isRelocationModalOpen,
    setIsRelocationModalOpen,
    isSecurityClearanceModalOpen,
    setIsSecurityClearanceModalOpen,
    candidateData,
    handleFilterClick,
    handleManagerSelect,
    resetTableData,
    handleSelectCandidate,
    handleCandidateTypeSelect,
    handleSelectBranch,
    handleSelectStatus,
    handleSecurityClearance,
    setFilteredData,
    filterType,
    setFilterType,
    selectedCandidateType,
    setSelectedCandidateType,
    fetchFilteredCandidates,
    typeFilterType,
    setTypeFilterType,
    branchFilterType,
    setBranchFilterType,
    selectedBranch,
    setSelectedBranch,
    selectedManager,
    setSelectedManager,
    handleTextSearch,
    selectedTextSearch,
    setSelectedTextSearch,
    textSearchFilterType,
    setTextSearchFilterType,
    selectedStatus,
    setSelectedStatus,
    jobSearchfilterType,

    setJobSearchfilterType,
    securityFilterType,
    setSecurityFilterType,
    selectedSecurityClearance,
    setSelectedSecurityClearance,

    isCertificationModalOpen,
    setIsCertificationModalOpen,
    selectedCertifications,
    setSelectedCertifications,
    certificationFilterType,
    setCertificationFilterType,
    handleCertificationSelect,

    industryFilterType,
    setIndustryFilterType,
    selectedIndustries,
    setSelectedIndustries,
    isTargetIndustriesModalOpen,
    setTargetIndustriesModalOpen,
  
    handleIndustrySelect,

    selectedRelocation,
    setSelectedRelocation,
    relocationFilterType,
    setRelocationFilterType,
    handleSelectRelocation,
    isEditing,

    handleAffiliateFilter,
    selectedAffiliate,
    setSelectedAffiliate,
    affiliateFilterType,
    setAffiliateFilterType,
    isAffiliateModalOpen,
    setIsAffiliateModalOpen,
    hotCandidateFilterType,
    setHotCandidateFilterType,
    isHotCandidateModalOpen,
    setIsHotCandidateModalOpen,
    selectedHotCandidate,
    setSelectedHotCandidate,
    handleHotCandidate
  }) => {

    const [isEditView, setIsEditView] = useState(false);

 

    return (
     <>
            <AddFilterModal
              isOpen={isFilterModalOpen}
              onClose={() => setIsFilterModalOpen(false)}
              onFilterClick={handleFilterClick}
            />
    
            <ManagerFilter
              isOpen={isManagerModalOpen}
              onClose={() => setIsManagerModalOpen(false)}
              candidateData={candidateData}
              onSelectManager={handleManagerSelect}
              onResetFilters={resetTableData}
              setSelectedManager={setSelectedManager}
              filterType={filterType}
              setFilterType={setFilterType}
              onDelete={onDelete}
              setFilteredData={setFilteredData}
              fetchFilteredCandidates={fetchFilteredCandidates}
            />
    
            <TextSearchFilter
              isOpen={isTextSearchModalOpen}
              onClose={() => setIsTextSearchModalOpen(false)}
              candidateData={candidateData}
              onSelectCandidate={handleSelectCandidate}
              handleTextSearch={handleTextSearch}
              selectedTextSearch={selectedTextSearch}
              setSelectedTextSearch={setSelectedTextSearch}
              textSearchFilterType={textSearchFilterType}
              setTextSearchFilterType={setTextSearchFilterType}
              setFilteredData={setFilteredData}
              onDelete={onDelete}
              fetchFilteredCandidates={fetchFilteredCandidates}
            />
    
            <CandidateTypeFilter
              isOpen={isCandidateTypeModalOpen}
              onClose={() => setIsCandidateTypeModalOpen(false)}
              candidateData={candidateData}
              onSelectCandidateType={handleCandidateTypeSelect}
              selectedCandidateType={selectedCandidateType}
              setSelectedCandidateType={setSelectedCandidateType}
              setTypeFilterType={setTypeFilterType}
              typeFilterType={typeFilterType}
              onDelete={onDelete}
              setFilteredData={setFilteredData}
              fetchFilteredCandidates={fetchFilteredCandidates}
              selectedBranch={selectedBranch}
              setSelectedBranch={setSelectedBranch}
            />
    
            <MilitaryBranchFilter
              isOpen={isMilitaryBranchModalOpen}
              onClose={() => setMilitaryBranchModalOpen(false)}
              candidateData={candidateData}
              onSelectBranch={handleSelectBranch}
              branchFilterType={branchFilterType}
              setBranchFilterType={setBranchFilterType}
              selectedBranch={selectedBranch}
              setSelectedBranch={setSelectedBranch}
              onDelete={onDelete}
              setFilteredData={setFilteredData}
              fetchFilteredCandidates={fetchFilteredCandidates}
              selectedManager={selectedManager}
              selectedCandidateType={selectedCandidateType}
            />
    
            <JobSearchStatusFilter
              isOpen={isJobStatusModalOpen}
              onClose={() => setIsJobStatusModalOpen(false)}
              candidateData={candidateData}
              onSelectStatus={handleSelectStatus}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              jobSearchfilterType={jobSearchfilterType}
              setJobSearchfilterType={setJobSearchfilterType}
              onDelete={onDelete}
              setFilteredData={setFilteredData}
            />
    
            <RelocationFilter
              isOpen={isRelocationModalOpen}
              onClose={() => setIsRelocationModalOpen(false)}
              candidateData={candidateData}
              onSelectRelocationPreference={handleSelectRelocation}
              onDelete={onDelete}
              setFilteredData={setFilteredData}
              fetchFilteredCandidates={fetchFilteredCandidates}
              selectedRelocation={selectedRelocation}
              setSelectedRelocation={setSelectedRelocation}
              setRelocationTypeFilter={setRelocationFilterType}
              relocationTypeFilter={relocationFilterType}
            />
    
            <SecurityClearanceFilter
              isOpen={isSecurityClearanceModalOpen}
              onClose={() => setIsSecurityClearanceModalOpen(false)}
              candidateData={candidateData}
              onSelectSecurityClearance={handleSecurityClearance}
              securityFilterType={securityFilterType}
              setSecurityFilterType={setSecurityFilterType}
              selectedSecurityClearance={selectedSecurityClearance}
              setSelectedSecurityClearance={setSelectedSecurityClearance}
              onDelete={onDelete}
              setFilteredData={setFilteredData}
            />
    
            <CertificationFilter
              isOpen={isCertificationModalOpen}
              onClose={() => setIsCertificationModalOpen(false)}
              candidateData={candidateData}
              setFilteredData={setFilteredData}
              onDelete={onDelete}
              fetchFilteredCandidates={fetchFilteredCandidates}
              selectedCertifications={selectedCertifications}
              setSelectedCertifications={setSelectedCertifications}
              certificationFilterType={certificationFilterType}
              setCertificationFilterType={setCertificationFilterType}
              handleCertificationSelect={handleCertificationSelect}
              onSelectCertification={handleCertificationSelect}
            />
    
            <TargetIndustriesFilter
              isOpen={isTargetIndustriesModalOpen}
              onClose={() => setTargetIndustriesModalOpen(false)}
              onSelectIndustry={handleIndustrySelect}
              industryFilterType={industryFilterType}
              setIndustryFilterType={setIndustryFilterType}
              selectedIndustries={selectedIndustries}
              setSelectedIndustries={setSelectedIndustries}
              candidateData={candidateData}
              setFilteredData={setFilteredData}
              onDelete={onDelete}
              fetchFilteredCandidates={fetchFilteredCandidates}
            />
    
            <AffiliateFilter
              isOpen={isAffiliateModalOpen}
              onClose={() => setIsAffiliateModalOpen(false)}
              candidateData={candidateData}
              setFilteredData={setFilteredData}
              onDelete={onDelete}
              fetchFilteredCandidates={fetchFilteredCandidates}
              onSelectAffiliate={handleAffiliateFilter}
              selectedAffiliate={selectedAffiliate}
              setSelectedAffiliate={setSelectedAffiliate}
              affiliateFilterType={affiliateFilterType}
              setAffiliateFilterType={setAffiliateFilterType}
            />
    
            <IsHotCandidateFilters
              isOpen={isHotCandidateModalOpen}
              onClose={() => setIsHotCandidateModalOpen(false)}
              candidateData={candidateData}
              setFilteredData={setFilteredData}
              onSelectHotCandidate={handleHotCandidate}
              onDelete={onDelete}
              fetchFilteredCandidates={fetchFilteredCandidates}
              hotCandidateFilterType={hotCandidateFilterType}
              setHotCandidateFilterType={setHotCandidateFilterType}
              selectedHotCandidate={selectedHotCandidate}
              setSelectedHotCandidate={setSelectedHotCandidate}
            />
          </>
        )}
 
  export default AllFiltersModal;
  