import React , { useContext, useState,useEffect }from "react";
import "./Users.css";
import { ReactComponent as EditIcon } from "../../../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/images/trash.svg";
import AddNewUserPopup from "./AddNewUserPopup/AddNewUserPopup";
import EditUserPopup from "./EditUserPopup/EditUserPopup";
import { AuthContext } from "../../../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const getColorForLetter = (letter) => {
  const colors = {
    A: "#FFD757",
    B: "#288C03",
    C: "#03A9F4",
    D: "#FF5722",
    E: "#8E24AA",
    F: "#03DAC5",
    G: "#2196F3",
    H: "#FF4081",
    I: "#9C27B0",
    J: "#4CAF50",
    K: "#FF9800",
    L: "#607D8B",
    M: "#00BCD4",
    N: "#FFEB3B",
    O: "#8BC34A",
    P: "#673AB7",
    Q: "#FF4081",
    R: "#9E9E9E",
    S: "#3F51B5",
    T: "#00BFAE",
    U: "#9E9E9E",
    V: "#4CAF50",
    W: "#3F51B5",
    X: "#E91E63",
    Y: "#FFEB3B",
    Z: "#9C27B0",
  };
  return colors[letter.toUpperCase()] || "rgb(200, 200, 200)";
};

const Users = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const[users,setUsers]=useState([]);
  const { keycloak } = useContext(AuthContext);
  console.log("keycloak", keycloak?.token);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`/api/admin/getall`, {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        });

        console.log("API Candidate Data", response.data);
        setUsers(response.data);
        // setFilteredData(response.data);
      } catch (error) {
        console.error("Error fetching candidate data:", error);

        // Redirect if error is 400, 403, or 500
        if (error.response) {
          const status = error.response.status;
          if ([400, 403, 500].includes(status)) {
            navigate("/error", { state: { errorCode: status } });
          }
        }
      }
    };

    fetchUser();
  }, []);
  // const users = [
  //   {
  //     name: "Jordie Kern",
  //     email: "jethro@7eagle.com",
  //     phone: "914-469-9320",
  //     calendly: "https://calendly.com/jethrojamero/30min"
  //   },
  //   {
  //     name: "Arsalan Shamim",
  //     email: "arsalan@7eagle.com",
  //       phone: "914-469-9320",
  //     calendly: "https://calendly.com/jethrojamero/30min"
  //   },
  //   {
  //     name: "Jethro Jamero",
  //     email: "jethro@7eagle.com",
  //      phone: "914-469-9320",
  //     calendly: "https://calendly.com/jethrojamero/30min"
  //   },
  // ];
  // /api/admin
  return (
   <>
    <div className="section_users">
      <div className="header_users">
        <h2>All Users</h2>
        {/* <button className="add-btn" onClick={() => setIsPopupOpen(true)}>Add User +</button> */}
      </div>
      <div className="users_table">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th style={{width:"20%"}}>Phone</th>
              <th style={{width:"30%"}}>Calendly</th>
            </tr>
          </thead>
          <tbody>
          {users.length && users.map((user) => (
  <tr key={user.email || user.phone || Math.random()}>
    <td className="user-info">
      <div
        style={{
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          backgroundColor: getColorForLetter(user?.name?.charAt(0) || "U"), // Default to 'U'
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          fontSize: "18px",
          marginRight: "8px",
        }}
      >
        {user?.name?.charAt(0) || "U"}
      </div>
      {user?.name || "Unknown"}
    </td>
    <td>{user?.email || ""}</td>
    <td>{user?.phone || ""}</td>
    <td>
      {/* <EditIcon style={{ marginRight: "5px" }} onClick={() => setIsEditPopupOpen(true)} />
      <DeleteIcon /> */}
      {user?.calendly || ""}
    </td>
  </tr>
))}

          </tbody>
        </table>
      </div>
    </div>
    

   {/* <AddNewUserPopup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}/>
    <EditUserPopup isOpen={isEditPopupOpen} onClose={()=> setIsEditPopupOpen(false)}/> */}
   </>
  );
};

export default Users;
