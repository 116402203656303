import React, { useState } from "react";
import axios from "axios";
import TextEditor from "../TextEditor/TextEditor"; // Import TextEditor component
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function NotesEditor({ company }) {
  const [content, setContent] = useState(""); // Store the content of the note
  const [username, setUsername] = useState(company.name || "Anonymous"); // Set default username if fname is not available

  // Function to handle posting the note
  const handlePost = async () => {
    // Check if content is empty
    if (!content.trim()) {
      toast.error("Note content cannot be empty!"); // Display error toast if content is empty
      return;
    }

    try {
      const note = { content, username };

      // Make a backend API request to post the note
      const response = await axios.post(
        `http://localhost:5000/api/admin/companys/${company._id}/notes`,
        note
      );

      // If the note is successfully added
      if (response.status === 201) {
        toast.success("Note successfully added!"); // Success toast
        setContent(""); // Reset content after posting
      } else {
        toast.error("Failed to add note."); // Error toast if the note is not added
      }
    } catch (error) {
      console.error("Error adding note:", error);
      toast.error("Error during adding note: " + error.message); // Display error toast with the message from the exception
    }
  };

  // Handle content change from the TextEditor
  const handleEditorChange = (newContent) => {
    setContent(newContent); // Update content when typed in the TextEditor
  };

  return (
    <div className="notes_editor">
      {/* Render TextEditor component and handle content change */}
      <TextEditor
        value={content}
        onChange={handleEditorChange}
        placeholder="Write your note here"
      />

      {/* Post button to trigger the note posting */}
      <div className="footer_text_area">
        <button onClick={handlePost}>Post</button>
      </div>

      {/* ToastContainer to render the toast notifications */}
      <ToastContainer />
    </div>
  );
}

export default NotesEditor;
