import React from 'react';
import { ReactComponent as Logo } from "../../assets/images/logo-7eagle.svg";
import { ReactComponent as Icon403 } from "../../assets/images/403.svg";

import useAdmin from './useAdmin';  // Import the custom hook
import Header from '../../components/Common/Header/Header';
import './PageError.css';
function PageError403() {
  const { admin, loading } = useAdmin();

  if (loading) {
      return (
        <div className="loading-container">
          <Logo style={{width:"150px"}}/>
          <div className="spinner"></div>
          <div>
            <h6>Please Wait..</h6>
          </div>
        </div>
      );
  }

  return (
    <div>
        <Header admin={admin} />

        <div className="error-page">
        <div className="error-page-content">
          <div className="icon-error">
            <Icon403 />
          </div>
          <div>
            <p className="sub-error">Error 403: Forbidden</p>
            <h2>Access Denied </h2>
            <p>
            It looks like you’ve hit a restricted area. Please ensure you’re logged in with the correct account.
            </p>
            <div className="back-to-home">
              <a href="/admin/dashboard">Back to Dashboard</a>
            </div>

            <div className="error-page-footer">
              <p>
                {" "}
                Still having trouble? <a href="">Contact Support</a>
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default PageError403;
