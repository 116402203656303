import React, { useState, useRef, useContext ,useEffect } from "react";
import Modal from "react-modal"; // Modal library import
import "./EditAboutProfileInfo.css";
import { toast } from "react-toastify"; // For displaying success/error messages
import { AuthContext } from "../../../../context/AuthContext";

// import { IdealJobList } from "./IdealJobList";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
Modal.setAppElement("#root");

function EditAboutProfileInfo({ onClose, candidate, onUpdate }) {
   const { keycloak } = useContext(AuthContext);
  const [currentIdealJob, setCurrentIdealJob] = useState(
    Array.isArray(candidate.idealJobTitle)
      ? candidate.idealJobTitle
      : candidate.idealJobTitle
      ? [candidate.idealJobTitle]
      : []
  );
  const [IdealJobList, setIdealJobList] = useState([]);
  const [duringquestion, setDuringQuetion] = useState();
  const [inputValue, setInputValue] = useState("");
  const [currentSuggestions, setCurrentSuggestions] = useState([]);
  const [formData, setFormData] = useState({
    ...candidate,
    fname: candidate.fname || "",
    lname: candidate.lname || "",
    candidateType: candidate.type || "",
    state: candidate.state || "",
    city: candidate.city || "",
    jobSearchStatus: candidate.jobSearchStatus[0].value|| "",
    jobSearchStatusName:
      candidate.jobSearchStatus[0].status ||
      "",
    description: candidate.description || "",
    securityClearance: candidate.securityClearance || "",
    yearsOfExperience: candidate.yearsOfExperience || "",
    skillbridgeStartDate: candidate.skillbridgeStartDate || "",
    skillbridgeEndDate: candidate.skillbridgeEndDate || "",
    idealJobTitle: candidate.idealJobTitle || "",
    duringSkillbridgeCity: candidate.duringSkillbridgeCity || "",
    duringSkillbridgeState: candidate.duringSkillbridgeState || "",
    afterskillbridgeCity: candidate.afterskillbridgeCity || "",
    afterskillbridgeState: candidate.afterskillbridgeState || "",
    relocationprefrence: candidate.relocationprefrence || "",
    education: candidate.education || "",
    idno: candidate.recruiterFlowId || "", // Yeh ID RecruiterFlow ki hai
    email: candidate.email || "",
    phone: candidate.phone || "",
    certifications: candidate.certifications || "",
    Pursuingcertifications: candidate.Pursuingcertifications || "",
    branch: candidate.branch || "", // Optional chaining
    rank: candidate.rank || "",
    mos: candidate.mos || "",
    targetIndustries: candidate.targetIndustries || "",
    yearsServed: candidate.yearsServed || "",
    locationDuringSkillbridge: candidate.locationDuringSkillbridge || "",
    locationAfterSkillbridge: candidate.locationAfterSkillbridge || "",
  });

  useEffect(() => {
    const fetchJobTitles = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        
        const response = await fetch(`${apiUrl}/jobtitle/jobtitle`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`, 
          },
        });
  
        if (!response.ok) {
          throw new Error("Failed to fetch job titles");
        }
  
        const data = await response.json();
        setIdealJobList(data);
      } catch (error) {
        console.error("Error fetching job titles:", error);
      }
    };
  
    fetchJobTitles();
  }, [keycloak.token]);
  

  const validateForm = () => {
    let newErrors = {};
    if (!formData.description)
      newErrors.description = "This field can not be empty";
    if (!formData.securityClearance)
      newErrors.securityClearance = "This field can not be empty";
    if (!formData.yearsOfExperience)
      newErrors.yearsOfExperience = "This field can not be empty";

    if (!formData.idealJobTitle)
      newErrors.idealJobTitle = "This field can not be empty";

    if (!formData.relocationprefrence)
      newErrors.relocationprefrence = "This field can not be empty";

    if (!formData.education)
      newErrors.education = "This field can not be empty";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // const addCurrentJobTitle = (certification) => {
  //   if (!currentIdealJob.includes(certification)) {
  //     setCurrentIdealJob((prev) => [...prev, certification]);
  //   }
  // };

  const addCurrentJobTitle = (job) => {
    if (!currentIdealJob.some((item) => item._id === job._id)) {
      setCurrentIdealJob((prev) => [...prev, job]);
    }
  };
  

  // Handle input for current certifications

  const handleCurrentInput = (e) => {
    const input = e.target.value;
    setInputValue(input);
  
    if (input.length > 0) {
      const filteredSuggestions = IdealJobList?.filter((job) =>
        job?.title?.toLowerCase().includes(input.toLowerCase())
      ) || [];
  
      if (!filteredSuggestions.some((job) => job.title === input)) {
        filteredSuggestions.push({ _id: "new", title: `Create new: ${input}` });
      }
  
      setCurrentSuggestions(filteredSuggestions);
    } else {
      setCurrentSuggestions([]);
    }
  };
  
  
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      addCurrentJobTitle(inputValue.trim());
      setInputValue("");
      setCurrentSuggestions([]);
    }
  };



  // const handleCurrentSuggestionClick = (certification) => {
    
  //   if (certification.startsWith("Create new: ")) {
  //     const newJobTitle = certification.replace("Create new: ", "");
  //     const newJob = { _id: null, title: newJobTitle }; // ID null, kyunki new hai
  //     addCurrentJobTitle(newJob);
  //   } else {
  //     const selectedJob = IdealJobList.find((job) => job.title === certification);
  //     if (selectedJob) {
  //       addCurrentJobTitle(selectedJob);
  //     }
  //   }
  //   setInputValue("");
  //   setCurrentSuggestions([]);
  // };
  

  const handleCurrentSuggestionClick = (job) => {
    if (!job || !job.title) return; // Ensure job exists
  
    if (job.title.startsWith("Create new: ")) {
      const newJobTitle = job.title.replace("Create new: ", "");
      const newJob = { _id: null, title: newJobTitle };
      addCurrentJobTitle(newJob);
    } else {
      addCurrentJobTitle(job);
    }
  
    setInputValue("");
    setCurrentSuggestions([]);
  };
  

  
  // Remove current certification
  const handleRemoveCert = (index) => {
    const updatedJobTitle = currentIdealJob.filter((_, i) => i !== index);
    setCurrentIdealJob(updatedJobTitle);
  };

  const handleDuringSkillbridgeQuestion = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setDuringQuetion(e.target);
    console.log(duringquestion);
  };

  const handleAfterSkillbridgeQuestion = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const securityClearances = [
    "Confidential",
    "Secret",
    "Top Secret",
    "Top Secret with Full Scope Poly",
    "Top Secret with CI Poly",
    "Don't have a clearance",
  ];
  const yearOfExperiences = [
    "Zero - I'm making a career pivot.",
    "Minimal - I have less than 2 years of experience.",
    "Mid - I have 3-7 years of experience.",
    "Senior - I have 7+ years of experience.",
  ];

  const skillbridgeQuestions = [
    "Yes - I know exactly where I'll live.",
    "Almost - I have a few options I'm considering.",
    "No - but I'm willing to relocate for the job.",
  ];

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (value) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please Complete Candidate Information Fields");
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(
        `${apiUrl}/candidates/${candidate._id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            idealJobTitle: currentIdealJob,
          }),
        }
      );

      // Check response status and parse error message if possible
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.message || "Failed to update candidate.";
        throw new Error(errorMessage);
      }

      // If successful, parse and log the updated candidate
      const updatedCandidate = await response.json();
      console.log("Updated Candidate:", updatedCandidate);

      toast.success("Candidate updated successfully!");

      // Pass updated candidate data to parent using onUpdate callback
      if (onUpdate) {
        onUpdate(formData); // Send updated candidate to the parent component
      }

      setTimeout(() => {
        if (onClose) {
          onClose(); // This will close the form by changing the state in the parent
        }
      }, 2000); // Close the form after the update
    } catch (error) {
      console.error("Error during candidate update:", error);
      toast.error(`Error updating candidate: ${error.message}`);
    }
  };

  const inputref = useRef(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDV7VADzUdaHFisJU5k_6I454xH9qnuQz0",
    libraries: ["places"],
  });

  const handleOnPlacesChangedDuringSkillBridge = () => {
    let places = inputref.current.getPlaces();

    if (places && places.length > 0) {
      const addressComponents = places[0].address_components;

      if (addressComponents) {
        let cityName = "";
        let stateName = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            stateName = component.long_name;
          }
        });

        // Update the formData state
        setFormData((prevData) => ({
          ...prevData,
          duringSkillbridgeCity: cityName,
          duringSkillbridgeState: stateName,
        }));

        console.log("During SkillBridgeCity:", cityName);
        console.log("During SkillBridgeState:", stateName);
      } else {
        console.error("No address components found.");
      }
    } else {
      console.error("No places found.");
    }
  };

  const handleOnPlacesChangedAfterSkillBridge = () => {
    let places = inputref.current.getPlaces();

    if (places && places.length > 0) {
      const addressComponents = places[0].address_components;

      if (addressComponents) {
        let cityName = "";
        let stateName = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            stateName = component.long_name;
          }
        });

        // Update the formData state
        setFormData((prevData) => ({
          ...prevData,
          afterskillbridgeCity: cityName,
          afterskillbridgeState: stateName,
        }));

        console.log("During SkillBridgeCity:", cityName);
        console.log("During SkillBridgeState:", stateName);
      } else {
        console.error("No address components found.");
      }
    } else {
      console.error("No places found.");
    }
  };

  return (
    <>
      <div className="popup-content">
        <div className="popup-form about-form">
          <div className="form-field">
            <label>Short Bio</label>
            <textarea
              placeholder="Type Here"
              name="description"
              value={formData.description}
              onChange={handleChange}
              className={!formData.description ? "error-border" : ""}
            />

            {errors.description && (
              <span className="error-message-field">{errors.description}</span>
            )}
          </div>

          <div className="form-field">
            <>
              <label>Ideal Job</label>

              <input
                type="text"
                value={inputValue}
                onChange={handleCurrentInput}
                onKeyDown={handleKeyDown}
                placeholder="Type or search your jobtitle"
              />

              {currentSuggestions.length > 0 && (
              <ul className="suggestions">
              {currentSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleCurrentSuggestionClick(suggestion)}
                  className="suggestion-item"
                >
                  {suggestion.title} {/* Fix applied */}
                </li>
              ))}
            </ul>
            
              )}

              <div className="certifications-tags">
                {currentIdealJob.map((cert, index) => (
                  <span
                    key={index}
                    className="tag"
                    style={{ marginBottom: "10px" }}
                  >
                    {cert}{" "}
                    <button onClick={() => handleRemoveCert(index)}>x</button>
                  </span>
                ))}
              </div>
            </>
          </div>

          {/* Security Clearance - Years of Experience */}
          <div className="two-fields">
            <div className="form-field">
              <label>Security Clearance</label>
              <select
                value={formData.securityClearance}
                onChange={handleChange}
                name="securityClearance"
              >
                <option value="">Select Option</option>
                {securityClearances.map((loc, index) => (
                  <option key={index} value={loc}>
                    {loc}
                  </option>
                ))}
              </select>

              {errors.securityClearance && (
                <span className="error-message-field">
                  {errors.securityClearance}
                </span>
              )}
            </div>

            <div className="form-field">
              <label>Years of Experience</label>
              <select
                value={formData.yearsOfExperience}
                onChange={handleChange}
                name="yearsOfExperience"
              >
                <option value="">Select Option</option>
                {yearOfExperiences.map((exp, index) => (
                  <option key={index} value={exp}>
                    {exp}
                  </option>
                ))}
              </select>

              {errors.yearsOfExperience && (
                <span className="error-message-field">
                  {errors.yearsOfExperience}
                </span>
              )}
            </div>
          </div>

          {/* Check if candidate type is not 'Veteran' */}
          {candidate.type !== "Veteran" && (
            <>
              {/* SkillBridge Start Date - Skillbridge End date */}
              <div className="two-fields">
                <div className="form-field">
                  <label>Skillbridge Start Date</label>
                  <input
                    type="date"
                    name="skillbridgeStartDate"
                    value={formData.skillbridgeStartDate}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-field">
                  <label>Skillbridge End Date</label>
                  <input
                    type="date"
                    name="skillbridgeEndDate"
                    value={formData.skillbridgeEndDate}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="form-field">
                <label>
                  Do you know where you will live DURING SkillBridge?{" "}
                </label>
                <select
                  value={formData.locationDuringSkillbridge}
                  onChange={handleDuringSkillbridgeQuestion}
                  name="locationDuringSkillbridge"
                >
                  <option value="">Select Option</option>
                  {skillbridgeQuestions.map((exp, index) => (
                    <option key={index} value={exp}>
                      {exp}
                    </option>
                  ))}
                </select>
              </div>
              {(formData.locationDuringSkillbridge ===
                "Yes - I know exactly where I'll live." ||
                formData.locationDuringSkillbridge ===
                  "Almost - I have a few options I'm considering.") && (
                <div className="duringskillbridgecondition">
                  {/* During SkillBridge */}

                  <div className="form-field" style={{ marginBottom: "15px" }}>
                    {isLoaded && (
                      <div>
                        <label>Location During Skillbrdige</label>
                        <StandaloneSearchBox
                          onLoad={(ref) => (inputref.current = ref)}
                          onPlacesChanged={
                            handleOnPlacesChangedDuringSkillBridge
                          }
                          style={{
                            zIndex: "10000",
                            position: "absolute",
                            top: "0px",
                            width: "100%",
                          }} // Higher z-index for the search box
                        >
                          <input
                            type="text"
                            placeholder="Search location"
                            style={{
                              width: "100%",
                              padding: "14px",
                              borderRadius: "10px",
                              border: "1px solid #ddddde",
                            }}
                          />
                        </StandaloneSearchBox>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="form-field">
                <label>
                  Do you know where you will live After SkillBridge?{" "}
                </label>
                <select
                  value={formData.locationAfterSkillbridge}
                  onChange={handleAfterSkillbridgeQuestion}
                  name="locationAfterSkillbridge"
                >
                  <option value="">Select Option</option>
                  {skillbridgeQuestions.map((exp, index) => (
                    <option key={index} value={exp}>
                      {exp}
                    </option>
                  ))}
                </select>
              </div>

              {(formData.locationAfterSkillbridge ===
                "Yes - I know exactly where I'll live." ||
                formData.locationAfterSkillbridge ===
                  "Almost - I have a few options I'm considering.") && (
                <div className="condition-afterskillbridge">
                  {/* Location After Skillbridge */}
                  <div className="form-field" style={{ marginBottom: "15px" }}>
                    {isLoaded && (
                      <div>
                        <label>Location After Skillbrdige</label>
                        <StandaloneSearchBox
                          onLoad={(ref) => (inputref.current = ref)}
                          onPlacesChanged={
                            handleOnPlacesChangedAfterSkillBridge
                          }
                          style={{
                            zIndex: "10000",
                            position: "absolute",
                            top: "0px",
                            width: "100%",
                          }} // Higher z-index for the search box
                        >
                          <input
                            type="text"
                            placeholder="Search location"
                            style={{
                              width: "100%",
                              padding: "14px",
                              borderRadius: "10px",
                              border: "1px solid #ddddde",
                            }}
                          />
                        </StandaloneSearchBox>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          {/* Relocation Preference */}
          <div className="form-field">
            <label>
              Are you open to relocating? <span>*</span>
            </label>
            <select
              name="relocationprefrence"
              value={formData.relocationprefrence}
              onChange={handleChange}
            >
              <option value="">Select Option</option>
              <option value="I can live practically anywhere…my bag is already packed!">
                I can live practically anywhere…my bag is already packed!
              </option>
              <option
                value=" I would strongly consider relocating for the right SkillBridge
                Opportunity."
              >
                I would strongly consider relocating for the right SkillBridge
                Opportunity.
              </option>
              <option value=" It's unlikely unless it's an amazing offer.">
                It's unlikely unless it's an amazing offer.
              </option>
              <option value="It's impossible to relocate…I must live in a certain location.">
                It's impossible to relocate…I must live in a certain location.
              </option>
            </select>

            {errors.relocationprefrence && (
              <span className="error-message-field">
                {errors.relocationprefrence}
              </span>
            )}
          </div>

          {/* Education */}
          <div className="form-field">
            <label>
              Education <span>*</span>
            </label>
            <input
              type="text"
              placeholder="Type Here"
              name="education"
              value={formData.education}
              onChange={handleChange}
              className={!formData.education ? "error-border" : ""}
            />

            {errors.education && (
              <span className="error-message-field">{errors.education}</span>
            )}
          </div>
        </div>

        <div className="form-btn">
          <button
            type="submit"
            className="btn-save-changes"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default EditAboutProfileInfo;
