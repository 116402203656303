import React, { useState, useEffect, useContext } from "react";
import "./RecentActivities.css";
import { AuthContext } from "../../../context/AuthContext";

function RecentActivities({ candidate }) {
  const [activities, setActivities] = useState([]);
  const { keycloak } = useContext(AuthContext);

  // Updated function to prevent "Invalid time value" error
  const formatDate = (dateString) => {
    if (!dateString) return "Unknown Date"; // Handle missing date

    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Invalid Date"; // Handle invalid date values

    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
  };

  useEffect(() => {
    if (!candidate || !candidate._id) return; // Prevent API call if candidate is missing

    const fetchActivities = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/candidates/${candidate._id}/activities`,
          {
            headers: {
              Authorization: `Bearer ${keycloak?.token || ""}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setActivities(Array.isArray(data) ? data : []); // Ensure it's an array
      } catch (error) {
        console.error("Error fetching activities:", error);
        setActivities([]); // Ensure activities state is always an array
      }
    };

    fetchActivities();
  }, [candidate?._id, keycloak?.token]); // Depend only on safe values

  return (
    <div>
      <div className="recentactivity_compontent">
        <div className="activity_main_bar">
          <h6>Recent Activity</h6>
          <p>Filter Activities</p>
        </div>

        {activities.length > 0 ? (
          activities.map((activity, index) => (
            <div key={index} className="activities_box">
              <div className="box_main_bar">
                <h6 className="activity_heading">{activity?.action || "Unknown Action"}</h6>

                <div className="profile_info_img">
                  <img
                    src={
                      activity?.headshotUrl
                        ? `${process.env.REACT_APP_API_URL}/${activity.headshotUrl}`
                        : "/default-profile.png"
                    }
                    className="profile_img_activity"
                    alt="Profile"
                  />
                  <h6>{activity?.candidateName || "Unknown Name"}</h6>
                </div>

                <h6 className="date_time">{formatDate(activity?.createdAt)}</h6>
              </div>

              <div className="activities_desc">
                <p>{activity?.action || "No action recorded."}</p>
              </div>
            </div>
          ))
        ) : (
          <p>No activities available.</p>
        )}
      </div>
    </div>

   
  );
}

export default RecentActivities;
