import React, { useState, useEffect, useRef } from "react";
import "./IndustriesFilter.css";
import { ReactComponent as IndustryIcon } from "../../../assets/images/icon-industry-fill.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";

const TargetIndustriesFilter = ({
  isOpen,
  onClose,
  onSelectIndustry,
  industryFilterType,
  setIndustryFilterType,
  selectedIndustries,
  setSelectedIndustries,
  onDelete,
  setFilteredData,
  fetchFilteredCandidates,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [industryDisplay, setIndustryDisplay] = useState("");
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {};

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const handleDone = () => {
    if (searchTerm && searchTerm.length >= 2) {
      setSelectedIndustries(searchTerm);
      onSelectIndustry(searchTerm);
      setIsConfirmed(true);
      setIndustryDisplay(searchTerm);
    }
  };

  const handleEdit = () => {
    setIsConfirmed(false);
  };

  useEffect(() => {
    if (selectedIndustries === null || selectedIndustries === "") {
      setFilteredData([]);
    }
  }, [selectedIndustries]);

  const handleDelete = () => {
    setSelectedIndustries(null);
    setSearchTerm("");
    setIsConfirmed(false);
    onDelete(null);
    fetchFilteredCandidates(null);
  };

  const handleFilterTypeChange = (event) => {
    setIndustryFilterType(event.target.value);
  };

  if (!isOpen) return null;

  return (
    <div className="industry-modal-main">
      <div className="industry-modal-content" ref={modalRef}>
        {isConfirmed ? (
          <div className="confirmation-view">
            <div className="selected_header">
              <div>
                <h6>
                  <IndustryIcon /> Target Industry
                </h6>
              </div>
              <div className="manage-icons">
                <EditIcon onClick={handleEdit} />
                <DeleteIcon onClick={handleDelete} />
              </div>
            </div>
            <p className="selected-industries" style={{marginBottom:"0px", paddingBottom:"0px"}}>
              {industryFilterType} {industryDisplay}
            </p>
          </div>
        ) : (
          <>
            <div className="filter-options">
              <h6>
                <IndustryIcon /> Target Industry
              </h6>
              <div className="industry-list-radio-buttons" style={{ display: "grid" }}>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="contains one of"
                    checked={industryFilterType === "contains one of"}
                    onChange={handleFilterTypeChange}
                  />
                  Contains one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="does not contain any of"
                    checked={industryFilterType === "does not contain any of"}
                    onChange={handleFilterTypeChange}
                  />
                  Does not contain any of
                </label>
              </div>
            </div>
            <input
              type="text"
              placeholder="Search Industry"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="industry-search-bar"
            />
            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TargetIndustriesFilter;
