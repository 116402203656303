import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext"; // AuthProvider import karein
import PrivateRoute from "./components/PrivateRoute"; // PrivateRoute import karein
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AdminCandidateDashboard from "./pages/Candidates/Candidates";
import CandidateCompleteProfile from "./pages/Candidates/CandidateCompleteProfile";
import CandidateProfilePage from "./pages/Candidates/CandidateProfilePage";
import "react-toastify/dist/ReactToastify.css";
import Courses from "./pages/Courses/Course-Page";
import CourseDetail from "./pages/Courses/CourseDetail";
import CandidatePublicProfile from "./pages/Candidates/CandidatePublicProfile";
import ClientRecords from "./pages/Company/Companies";
import AddNewCandidatesPage from "./pages/Candidates/AddNewCandidates";
import AdminDashboard from "./pages/AdminDashboard";
import AdminDashboardPage from "./pages/AdminDashboard";
import AddNewCompanyPage from "./pages/Company/AddNewCompanyPages/AddNewCompanyPage";
import PageError404 from "./pages/Common/PageError404";
import PageError403 from "./pages/Common/PageError403";
import PageError500 from "./pages/Common/PageError500";
import DynamicError from "./pages/Common/DynamicError";
import SettingPages from "./pages/Settings/SettingPages";
// import JobListsPage from "./pages/Jobs/JobLists";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route
            path="/admin/candidates"
            element={
              <PrivateRoute>
                <AdminCandidateDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/companies"
            element={
              <PrivateRoute>
                <ClientRecords />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/courses"
            element={
              <PrivateRoute>
                <Courses />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/course/:id"
            element={
              <PrivateRoute>
                <CourseDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/create-new-candidates"
            element={
              <PrivateRoute>
                <AddNewCandidatesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/dashboard"
            element={
              <PrivateRoute>
                <AdminDashboardPage />
              </PrivateRoute>
            }
          />

<Route
            path="/admin/settings"
            element={
              <PrivateRoute>
                <SettingPages />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/create-new-company"
            element={
              <PrivateRoute>
                <AddNewCompanyPage />
              </PrivateRoute>
            }
          />
          {/* Catch-all route for 404 errors */}
          <Route
            path="*"
            element={<PageError404 />} // all undefined routes will be redirected to 404 page
          />

<Route
            path="/admin/error-403"
            element={<PageError403/>} // Custom 403 error page
          />

<Route
            path="/error"
            element={<DynamicError/>} // Custom 400 error page
          />

<Route
            path="/admin/error-500"
            element={<PageError500/>} // Custom 500 error page
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
