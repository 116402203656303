import React, { useState, useEffect, useRef } from "react";
import "./RelocationFilter.css";

import { ReactComponent as IconUser } from "../../../assets/images/icon-job-fill.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";

const RelocationFilter = ({
  isOpen,
  onClose,
  onSelectRelocationPreference,
  relocationTypeFilter,
  setRelocationTypeFilter,
  selectedRelocation,
  setSelectedRelocation,
  onDelete,
  setFilteredData,
  fetchFilteredCandidates
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRelocationValue, setSelectedRelocationValue] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const modalRef = useRef(null);

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
    
  //   };

  //   if (isOpen) {
  //     document.addEventListener("mousedown", handleOutsideClick);
  //   }
  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, [isOpen, onClose]);

  const handleDone = () => {
    if (searchTerm && searchTerm.length >= 2) {
      setSelectedRelocation(searchTerm);
      onSelectRelocationPreference(searchTerm);
      setSelectedRelocationValue(searchTerm);
      setIsConfirmed(true);
    }
  };

  const handleEdit = () => {
    setIsConfirmed(false);
  };

  useEffect(() => {
    if (selectedRelocation === null || selectedRelocation === "") {
      console.log("Relocation Preference is null, resetting table...");
      setFilteredData([]);
    }
  }, [selectedRelocation]);

  const handleDelete = () => {
    console.log("Deleting relocation preference filter...");
    setSelectedRelocation(null);
    setSearchTerm("");
    setIsConfirmed(false);
    onDelete(null);
  };

  const handleFilterTypeChange = (event) => {
    setRelocationTypeFilter(event.target.value);
  };

  if (!isOpen) return null;

  return (
    <div className="relocation-filter-modal-main">
      <div className="relocation-filter-modal-content" ref={modalRef}>
        {isConfirmed ? (
          <div className="confirmation-view">
            <div className="selected_header">
              <div>
                <h6>
                  <IconUser /> Relocation Preference
                </h6>
              </div>
              <div className="manage-icons">
                <EditIcon onClick={handleEdit} />
                <DeleteIcon onClick={handleDelete} />
              </div>
            </div>
            <p className="selected-relocation-name">
              {relocationTypeFilter} {" "} {selectedRelocationValue}
            </p>
          </div>
        ) : (
          <>
            <div className="filter-options">
              <h6>
                <IconUser /> Relocation Preference
              </h6>
              <div className="relocation-radio-buttons" style={{ paddingTop: "10px", paddingTop: "10px",
    display: "grid",
    paddingBottom: "10px",
    fontSize: "14px" }}>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is one of"
                    checked={relocationTypeFilter === "is one of"}
                    onChange={handleFilterTypeChange}
                    style={{marginRight:"5px"}}
                  />
                  Is one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not one of"
                    checked={relocationTypeFilter === "is not one of"}
                    onChange={handleFilterTypeChange}
                    style={{marginRight:"5px"}}
                  />
                  Is not one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is set"
                    checked={relocationTypeFilter === "is set"}
                    onChange={handleFilterTypeChange}
                    style={{marginRight:"5px"}}
                  />
                  Is set
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not set"
                    checked={relocationTypeFilter === "is not set"}
                    onChange={handleFilterTypeChange}
                    style={{marginRight:"5px"}}
                  />
                  Is not set
                </label>
              </div>
            </div>
            <input
              type="text"
              placeholder="Enter Relocation Preference"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="relocation-search-bar"
            />
            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RelocationFilter;
