import React, { useState , useEffect } from "react";
import "./CompanyGridList.css";
import CompanyDetailPopup from "../Company Details/CompanyDetailPopup";
import { ReactComponent as VerifiedIcon } from "../../../assets/images/verified.svg";  

const CompanyGridList = ({ data, admin ,totalRecords, totalPages, currentPage, setCurrentPage, setRecordsPerPage, recordsPerPage}) => {

  const [selectedClient, setselectedClient] = useState(null); // State to store selected candidate
  const [columns, setColumns] = useState([
    {
      id: 1,
      name: "Interested Candidates",
      field: "interestedCandidate",
      visible: true,
    },
    { id: 2, name: "Applicants", field: "applicants", visible: true },
  { id: 3, name: "Favorite Candidates", field: "favoriteCandidates", visible: true },
    { id: 4, name: "Interview", field: "interview", visible: true },
    {id: 5, name: "Submitted Candidates", field: "submittedCandidates", visible: true,
    },
    { id: 6, name: "Hires", field: "hiredCandidates", visible: true},
    { id: 7, name: "Sent Offer", field: "sentOffer", visible: true },
  ]);
  const visibleColumns = columns.filter((col) => col.visible);



  const [currentRecords, setCurrentRecords] = useState([]);
  
  useEffect(() => {
    const indexOfLastRecord = Math.min(currentPage * recordsPerPage, data.length);
    const indexOfFirstRecord = Math.max(0, indexOfLastRecord - recordsPerPage);
    setCurrentRecords(data.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [currentPage, recordsPerPage, data]); // Re-run on state change

  // Handle candidate row click
  const handleClientClick = (candidate) => {
    setselectedClient(candidate);
  };
  

  const getColorForLetter = (letter) => {
    const colors = {
      A: "#FFD757",
      B: "#288C03",
      C: "#03A9F4",
      D: "#FF5722",
      E: "#8E24AA",
      F: "#03DAC5",
      G: "#2196F3",
      H: "#FF4081",
      I: "#9C27B0",
      J: "#4CAF50",
      K: "#FF9800",
      L: "#607D8B",
      M: "#00BCD4",
      N: "#FFEB3B",
      O: "#8BC34A",
      P: "#673AB7",
      Q: "#FF4081",
      R: "#9E9E9E",
      S: "#3F51B5",
      T: "#00BFAE",
      U: "#9E9E9E",
      V: "#4CAF50",
      W: "#3F51B5",
      X: "#E91E63",
      Y: "#FFEB3B",
      Z: "#9C27B0",
    };
    return colors[letter.toUpperCase()] || "rgb(200, 200, 200)";
  };

  // Close the popup
  const closePopup = () => {
    setselectedClient(null);
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Handle records per page change

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when changing records per page
  };

  return (
    <>
      <div className="grid_candidates">
        <div className="grid-container">
          {currentRecords.map((row) => (
            <div
              key={row.id}
              className="grid-item"
              onClick={() => handleClientClick(row)}
              style={{cursor:"pointer"}}
            >
              <div className="grid-header-company">
                <div className="company-logo">
                  {row.logo ? (
                    <img
                      src={`${row.logo}`}
                      alt="Profile"
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        backgroundColor: getColorForLetter(
                          row.name ? row.name.charAt(0) : "?"
                        ),
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontSize: "16px", color: "#fff" }}>
                        {row.name.charAt(0)}
                      </span>
                    </div>
                  )}
                </div>
                <div className="company-name">
                  <span>{row.name}</span>
                  <VerifiedIcon style={{marginLeft:"5px"}}/>
                </div>

       

<div className="grid-content">
  <ul>
    <li
      className={`user-type ${
        row.userType === "Basic Plan"
          ? "basicPlanGrid"
          : row.userType === "All-Access Plan"
          ? "allAccessPlanGrid"
          : row.userType === "Enterprise Plan"
          ? "enterprisePlanGrid"
          : ""
      }`}
    >
      {row.userType}
    </li>
    <li>3 Users</li>
    <li>{row.manager}</li>
  </ul>
</div>

              </div>

              <div className="company-details">
                {/* <div className="grid-fields">
                  {visibleColumns
                    .filter((col) => col.field !== "name") // Skip name column here
                    .map((col) => (
                      <div key={col.id} className="grid-field">
                        <strong>{col.name} </strong>
                        <span>{row[col.field]}</span>
                      </div>
                    ))}
                </div> */}

<div className="grid-fields">
  {visibleColumns
    .filter((col) => col.field !== "name") // Skip name column here
    .map((col) => (
      <div key={col.id} className="grid-field">
        <strong>{col.name} </strong>
        <span>
          {Array.isArray(row[col.field]) ? row[col.field].length || 0 : row[col.field] || 0}
        </span>
      </div>
    ))}
</div>


              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="pagination-limit">
  <div>
    <label htmlFor="recordsPerPage">Companies per page: </label>
    <select
      id="recordsPerPage"
      value={recordsPerPage}
      onChange={handleRecordsPerPageChange}
    >
      <option value={10}>10</option>
      <option value={20}>20</option>
      <option value={30}>30</option>
      <option value={40}>40</option>
      <option value={50}>50</option>
    </select>
  </div>

  <div className="pagination_numbers">
    {totalRecords > recordsPerPage && (
      <div>
        {/* Previous Page Button */}
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt;
        </button>

        {/* First 6 Pages */}
        {Array.from({ length: Math.min(6, totalPages) }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}

        {/* Ellipsis if more pages exist */}
        {totalPages > 6 && <span>...</span>}

        {/* Last Page */}
        {totalPages > 6 && (
          <button
            onClick={() => handlePageChange(totalPages)}
            className={currentPage === totalPages ? "active" : ""}
          >
            {totalPages}
          </button>
        )}

        {/* Next Page Button */}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>
    )}
  </div>
</div>


            {/* Render the popup component */}
            {selectedClient && (
        <CompanyDetailPopup
          company={selectedClient}
          onClose={closePopup}
          admin={admin}
        />
      )}
    </>
  );
};

export default CompanyGridList;
