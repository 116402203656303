import React from "react";
import "./Templates.css";
import EmailTemplates from "./EmailTemplates/EmailTemplates";
import NoteTemplates from "./NotesTemplates/NotesTemplates";



const Templates = () => {

  return (
    <div className="section_templates">
      <div className="header_templates">
        <h2>All Templates</h2>
        <button className="add-btn">Add Templates +</button>
      </div>
     
{/*  Email Template */}

<EmailTemplates/>
<NoteTemplates/>
    </div>
  );
};

export default Templates;
