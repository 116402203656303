import React, { useState, useContext } from "react";
import { ReactComponent as EditIconLine } from "../../../assets/images/icon-edit-line.svg";
import "./CompanyProfileBox.css";
import CompanyImageUpload from "./CompanyImageUpload";

function CompanyProfileBox({ company }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Modal open or close function
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const [profileImage, setProfileImage] = useState(`${company.logo}`);
    const handleImageUploadSuccess = (newImageUrl) => {
        setProfileImage(newImageUrl);  // State update karein
        company.logo = newImageUrl; // Candidate object update karein
    };



    return (
        <>
            <div className="profile-box-wrap-dashboard">
                <div className="left-box">
                    <div className="img-box profile-box-img">
                        <div>
                            {company
                                .logo ? (
                                <div className="exist-profile-image">
                                    <img
                                        src={profileImage}
                                        alt="Profile-Img"
                                        className="company-logo-img"
                                    />

                                </div>
                            ) : (

                                <div className="ProfileDefaultImg">
                                    <h2>{company
                                        .name.charAt(0)}</h2>
                                </div>
                            )}
                        </div>
                        <div
                            className="profileEdit"
                            onClick={openModal}
                            company={company}
                        >
                            <EditIconLine />
                        </div>
                    </div>
                </div>
            </div>
            <CompanyImageUpload
                isOpen={isModalOpen}
                onClose={closeModal}
                company={company}
                onImageUpload={handleImageUploadSuccess}
            />
        </>
    );
}

export default CompanyProfileBox;
