import React, { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import { Link , useNavigate} from "react-router-dom";
import { AuthContext } from '../../context/AuthContext';
import { ReactComponent as Logo } from "../../assets/images/logo-7eagle.svg";
import ColumnEditModal from "../../components/Candidates/Candidate Records Table/ColumnEditModal";
import Header from "../../components/Common/Header/Header";
import Sidebar from "../../components/Common/Sidebar/Sidebar";
import "../AdminDashboard.css";
import AddFilterModal from "../../components/Candidates/Candidate Filters/AddNewFilter";
import TextSearchFilter from "../../components/Candidates/Candidate Filters/TextSearchFilter";
import MilitaryBranchFilter from "../../components/Candidates/Candidate Filters/MilitaryBranchFilter";
import JobSearchStatusFilter from "../../components/Candidates/Candidate Filters/JobSearchStatusFilter";
import RelocationFilter from "../../components/Candidates/Candidate Filters/RelocationFilter";
import SecurityClearanceFilter from "../../components/Candidates/Candidate Filters/SecurityClearanceFilter";
import ComapnyListTable from "../../components/Companies/Company List/CompanyListTable";
import CompanySearch from "../../components/Companies/SearchFilters/CompanySearchFilter";
import CompanyGridList from "../../components/Companies/Company List/CompanyGridList";
import { ReactComponent as GridIcon } from "../../assets/images/icon_grid.svg";
import { ReactComponent as ListIcon } from "../../assets/images/icon_list.svg";
import { ReactComponent as KanbanIcon } from "../../assets/images/icon-kanban.svg";
import { ReactComponent as FilterIcon } from "../../assets/images/FilterIcon.svg";
import { ReactComponent as FilterWhiteIcon } from "../../assets/images/filterWhite.svg";
import "./Companies.css";
import CompanyManagerFilter from "../../components/Companies/SearchFilters/CompanyManagerFilter";
import CompanyNameFilter from "../../components/Companies/SearchFilters/CompanyNameFilter";
import CurrentPlanFilter from "../../components/Companies/SearchFilters/CurrentPlanFilter";
import TokenDecode from "../../context/DecodeJWT";
import CompanyKanbanView from "../../components/Companies/Company List/CompanyKanbanViewList";
import AllCompanyFilters from "../../components/Companies/SearchFilters/CompanyAllFiltersModal";

const CompanyProfilePage = () => {
  const decodedToken = TokenDecode();
    const navigate = useNavigate();
      const { keycloak } = useContext(AuthContext);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const [admin, setAdmin] = useState(null);
  const [activeView, setActiveView] = useState("tablelist");
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [companyData, setcompanyData] = useState([]); // Initialize company data as empty array
  const [filteredData, setFilteredData] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isFilterDivModalOpen, setIsFilterDivModalOpen] = useState(false);
  const [filterType, setFilterType] = useState("is one of"); // Define filterType in parent

  const [isManagerModalOpen, setIsManagerModalOpen] = useState(false);
  const [isTextSearchModalOpen, setIsTextSearchModalOpen] = useState(false);
  const [isRelocationModalOpen, setIsRelocationModalOpen] = useState(false);
  const [isGridView, setIsGridView] = useState(false);
  const [isKanbanView, setIsKanbanView] = useState(false);

  const [isCertificationModalOpen, setIsCertificationModalOpen] =
    useState(false);

  const [isSecurityClearanceModalOpen, setIsSecurityClearanceModalOpen] =
    useState(false);

  const [isJobStatusModalOpen, setIsJobStatusModalOpen] = useState(false);

  const [isCompanyNameModalOpen, setIsCompanyNameModalOpen] = useState(false);
  const [isCurrentFilterModalOpen, setIsCurrentFilterModalOpen] =
    useState(false);

  const [isMilitaryBranchModalOpen, setMilitaryBranchModalOpen] =
    useState(false);

  const [selectedManager, setSelectedManager] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState(null);
  const [selectedCurrentPlan, setselectedCurrentPlan] = useState(null);

  const [selectedMilitaryBranch, setSelectedMilitaryBranch] = useState(null);
  const [selectedJobStatus, setSelectedJobStatus] = useState(null);
  const [selectedRelocation, setSelectedRelocation] = useState(null);
  const [selectedCertification, setSelectedCertification] = useState(null);

  const [selectedSecurityClearance, setSelectedSecurityClearance] =
    useState(null);


    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
  const handleSelectedCertification = (selectedCertifications) => {
    setSelectedCertification(selectedCertifications); // Save the selected certifications

    const filtered = companyData.filter((candidate) => {
      // Ensure candidate.certifications is an array and check if any of the selected certifications are in the candidate's certifications array
      return selectedCertifications.every((selectedCert) =>
        candidate.certifications?.some(
          (cert) =>
            cert &&
            String(cert).toLowerCase() === String(selectedCert).toLowerCase()
        )
      );
    });

    setFilteredData(filtered); // Update filtered data based on selected certifications
  };

  // Function to toggle to Grid View
// Function to toggle to Grid View
const showGridView = () => {
  setActiveView("grid");
  setIsGridView(true); // Activate Grid View
  setIsKanbanView(false); // Deactivate Kanban View
};

// Function to toggle to Kanban View
const showKanbanView = () => {
  setActiveView("kanban");
  setIsKanbanView(true); // Activate Kanban View
  setIsGridView(false); // Deactivate Grid View
};

// Function to toggle to Table View
const showTableView = () => {
  setActiveView("tablelist");
  setIsGridView(false); // Deactivate Grid View
  setIsKanbanView(false); // Deactivate Kanban View
};
 
useEffect(() => {
  const fetchCompanieseData = async () => {


    try {
      const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/company/companies`, {
        params: {
          page: currentPage,
          perPage: recordsPerPage
        },
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      });
      console.log("company data",response2)
      setcompanyData(response2.data.companies);
      setFilteredData(response2.data.companies);
      setTotalRecords(response2.data.total);
      setTotalPages(response2.data.totalPages);
     
    } catch (error) {
      console.error("Error fetching candidate data:", error);
      // Redirect if error is 400, 403, or 500
    const status = error.response?.status;
    if ([400, 403, 500].includes(status)) {
      navigate("/error", { state: { errorCode: status } });
    }
    }
  };

  fetchCompanieseData();
}, [currentPage, recordsPerPage]);


const fetchFilteredCompanies = useCallback(async (managerOverride) => {
  try {
    const params = { _t: new Date().getTime() };

    if (searchTerm && searchTerm.trim() !== "") {
      params.search = searchTerm.trim();
    }

    console.log("Fetching with params:", params);

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/company/companies`, {
      params,
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "Cache-Control": "no-cache",
      },
    });

    console.log("API Response:", response.data);

    setFilteredData(response.data.companies);
    setcompanyData(response.data.companies);

  } catch (error) {
    console.error("Error fetching companies", error);
  }
}, [searchTerm]);  // ❌ fetchFilteredCompanies ko yahan mat rakho


// ✅ Page Load pe data bina debounce ke le lo
useEffect(() => {
  console.log("Fetching data on page load...");
  fetchFilteredCompanies();
}, [searchTerm, fetchFilteredCompanies]); 


useEffect(() => {
  const timeoutId = setTimeout(() => {
    fetchFilteredCompanies();
  }, 500); 

  return () => {
    clearTimeout(timeoutId);
  };
}, [searchTerm, fetchFilteredCompanies]);




  const handleManagerSelect = (managerName) => {
    setSelectedManager(managerName);

    // Perform filtering based on the selected filter type
    const filtered = companyData.filter((company) => {
      if (filterType === "is one of") {
        // Exact match
        return (
          company.manager[0].name &&
          company.manager[0].name.toLowerCase() === managerName.toLowerCase()
        );
      } else if (filterType === "is not one of") {
        // Exact non-match
        return (
          company.manager[0].name &&
          company.manager[0].name.toLowerCase() !== managerName.toLowerCase()
        );
      } else if (filterType === "is set") {
        // Check if the manager name is not empty or null
        return company.manager[0].name && company.manager[0].name.trim() !== "";
      } else if (filterType === "is not set") {
        // Check if the manager name is empty or null
        return (
          !company.manager[0].name || company.manager[0].name.trim() === ""
        );
      } else {
        // Default case for "contains"
        return (
          company.manager[0].name &&
          company.manager[0].name
            .toLowerCase()
            .includes(managerName.toLowerCase())
        );
      }
    });

    setFilteredData(filtered);
  };
  // Handle Text Search
  const handleTextSearch = (selectedCompany) => {
    // Ensure selectedCompany is a string or extract the field you want
    const searchTerm =
      typeof selectedCompany === "string"
        ? selectedCompany
        : selectedCompany.name || "";

    setSelectedCandidate(selectedCompany);

    // Dynamic search: Partial match on any field
    const filtered = companyData.filter((company) =>
      Object.values(company).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    setFilteredData(filtered);
  };

  const handleCompanyNameFilter = (companyName) => {
    setSelectedCompanyName(companyName);

    // Perform filtering based on the selected filter type
    const filtered = companyData.filter((company) => {
      if (filterType === "is one of") {
        // Exact match
        return (
          company.name &&
          company.name.toLowerCase() === companyName.toLowerCase()
        );
      } else if (filterType === "is not one of") {
        // Exact non-match
        return (
          company.name &&
          company.name.toLowerCase() !== companyName.toLowerCase()
        );
      } else if (filterType === "is set") {
        // Check if the manager name is not empty or null
        return company.name && company.name.trim() !== "";
      } else if (filterType === "is not set") {
        // Check if the manager name is empty or null
        return !company.name || company.name.trim() === "";
      } else {
        // Default case for "contains"
        return (
          company.name &&
          company.name.toLowerCase().includes(companyName.toLowerCase())
        );
      }
    });

    setFilteredData(filtered);
  };

  const handleCurrentPlanFilter = (currentFilter) => {
    setselectedCurrentPlan(currentFilter);

    // Perform filtering based on the selected filter type
    const filtered = companyData.filter((company) => {
      if (filterType === "is one of") {
        // Exact match
        return (
          company.userType &&
          company.userType.toLowerCase() === currentFilter.toLowerCase()
        );
      } else if (filterType === "is not one of") {
        // Exact non-match
        return (
          company.userType &&
          company.userType.toLowerCase() !== currentFilter.toLowerCase()
        );
      } else if (filterType === "is set") {
        // Check if the manager name is not empty or null
        return company.userType && company.userType.trim() !== "";
      } else if (filterType === "is not set") {
        // Check if the manager name is empty or null
        return !company.userType || company.userType.trim() === "";
      } else {
        // Default case for "contains"
        return (
          company.userType &&
          company.userType.toLowerCase().includes(currentFilter.toLowerCase())
        );
      }
    });

    setFilteredData(filtered);
  };

  const handleSecurityClearance = (securityClearance) => {
    console.log("Selected security clearance:", securityClearance); // Debugging line

    // If the securityClearance is an object, extract the string value
    const securityClearanceString =
      typeof securityClearance === "object" &&
      securityClearance.securityClearance
        ? securityClearance.securityClearance
        : securityClearance;

    setSelectedSecurityClearance(securityClearanceString);

    // Ensure securityClearance is a string, fallback to empty string if it's not
    const securityClearanceName =
      typeof securityClearanceString === "string"
        ? securityClearanceString.trim().toLowerCase()
        : "";

    // Filter candidates based on security clearance (both trimmed and lowercased)
    const filtered = companyData.filter((candidate) => {
      const candidateSecurityClearance =
        typeof candidate.securityClearance === "string"
          ? candidate.securityClearance.trim().toLowerCase()
          : ""; // Fallback to empty string if not a string
      return candidateSecurityClearance === securityClearanceName;
    });

    // Log the filtered data for debugging
    console.log("Filtered data:", filtered);

    // Update the filteredData state
    setFilteredData(filtered);
  };

  const handleSelectRelocation = (relocation) => {
    console.log("Selected relocation:", relocation); // Debugging line

    // If the relocation is an object, extract the string value
    const relocationString =
      typeof relocation === "object" && relocation.relocation
        ? relocation.relocation
        : relocation;

    setSelectedRelocation(relocationString);

    // Ensure relocation is a string, fallback to empty string if it's not
    const relocationName =
      typeof relocationString === "string"
        ? relocationString.trim().toLowerCase()
        : "";

    // Filter candidates based on relocation preference (both trimmed and lowercased)
    const filtered = companyData.filter((candidate) => {
      const candidateRelocation =
        typeof candidate.relocationprefrence === "string"
          ? candidate.relocationprefrence.trim().toLowerCase()
          : ""; // Fallback to empty string if not a string
      return candidateRelocation === relocationName;
    });

    // Log the filtered data for debugging
    console.log("Filtered data:", filtered);

    // Update the filteredData state
    setFilteredData(filtered);
  };

  const handleSelectStatus = (jobstatus) => {
    setSelectedJobStatus(jobstatus);

    console.log("Filtering candidates with job status:", jobstatus);

    // If jobstatus is an object, extract the correct string property (e.g., 'name' or 'statusName')
    const jobstatusName =
      jobstatus && typeof jobstatus === "object"
        ? jobstatus.statusName
        : jobstatus;

    const filtered = companyData.filter((candidate) => {
      const statusName = candidate.jobSearchStatusName || "";
      console.log(
        `Candidate job status: ${statusName}, Filter status: ${jobstatusName}`
      );
      return statusName === jobstatusName;
    });

    setFilteredData(filtered);
  };

  const handleSelectBranch = (branch) => {
    setSelectedMilitaryBranch(branch);
    const filtered = companyData.filter(
      (candidate) => candidate.branch && candidate.branch === branch
    );
    setFilteredData(filtered);
  };

  const resetTableData = () => {
    setFilteredData(companyData); // Reset to original candidate data
  };

  const handleToggleSidebar = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  const [columns, setColumns] = useState([
    { id: 1, name: "Company Name", field: "name", visible: true },
    { id: 2, name: "Current Plan", field: "plan", visible: true },
    { id: 3, name: "Manager", field: "manager", visible: true },
    { id: 4, name: "Referred By", field: "referredBy", visible: true }, // `type` field mapped here
    { id: 5, name: "Recent Activity", field: "recentActivity", visible: true },
    { id: 6, name: "Applicants", field: "applicants", visible: true },
    {
      id: 7,
      name: "Hired Candidates",
      field: "hiredCandidates",
      visible: true,
    },
    {
      id: 8,
      name: "Interested Candidates",
      field: "interestedCandidate",
      visible: true,
    },

    { id: 9, name: "Interview", field: "interview", visible: true },
    { id: 10, name: "Sent Offer", field: "sentOffer", visible: true },
    {
      id: 11,
      name: "Submitted Candidates",
      field: "submittedCandidates",
      visible: true,
    },

    {
      id: 12,
      name: "Interested Candidates",
      field: "interestedcandidates",
      visible: true,
    },
    {
      id: 13,
      name: "Favorite Candidates",
      field: "favoriteCandidates",
      visible: true,
    },
    { id: 14, name: "Affiliate", field: "affiliate", visible: true },

    { id: 15, name: "Hot Candidate", field: "hotCandidate", visible: true },
  ]);

  // Select Filter Section
  const handleFilterClick = (filter) => {
    if (filter === "Manager") {
      setIsManagerModalOpen(true);
    }

    if (filter === "Text Search") {
      setIsTextSearchModalOpen(true);
    }

    if (filter === "Company Name") {
      setIsCompanyNameModalOpen(true);
    }

    if (filter === "Current Plan") {
      setIsCurrentFilterModalOpen(true);
    }

    if (filter === "Job Search Status") {
      setIsJobStatusModalOpen(true);
    }

    if (filter === "Relocation Option") {
      setIsRelocationModalOpen(true);
    }

    if (filter === "Security Clearance") {
      setIsSecurityClearanceModalOpen(true);
    }

    if (filter === "Certifications") {
      setIsCertificationModalOpen(true);
    } else {
      console.log(`${filter} filter clicked!`);
    }
  };
  const toggleColumnVisibility = (id) => {
    setColumns(
      columns.map((col) =>
        col.id === id ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const onColumnReorder = (newColumns) => {
    setColumns(newColumns);
  };



 

  // Filter Company data based on search term

  // useEffect(() => {
  //   console.log("Company Data: ", companyData); // Debugging
  
  //   // Ensure that companyData is an object and has a companies array
  //   if (!companyData || !Array.isArray(companyData.companies)) {
  //     console.error("companyData.companies is not an array", companyData.companies);
  //     return;
  //   }
  
  //   const lowerCaseSearchTerm = searchTerm.toLowerCase();
  //   const filtered = companyData.companies.filter((company) =>
  //     Object.values(company).some(
  //       (value) => String(value).toLowerCase().includes(lowerCaseSearchTerm)
  //     )
  //   );
  
  //   setFilteredData(filtered);
  // }, [searchTerm, companyData]);
  
  // Admin

  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        setLoading(true);
        const userEmail = decodedToken?.email;
     
      if (userEmail) {
          const response = await axios.get(`/api/admin/?email=${userEmail}`);
          setAdmin(response.data);
          console.log(response.data.email
          );
        }
      } catch (error) {
        console.log(
          "Error fetching Admin:",
          error.response ? error.response.data : error.message
        );
        // Redirect if error is 400, 403, or 500
      const status = error.response?.status;
      if ([400, 403, 500].includes(status)) {
        navigate("/error", { state: { errorCode: status } });
      }
      } finally {
        setLoading(false);
      }
    };

    fetchAdmin();
  }, [decodedToken]);

  if (loading) {
    return (
      <div className="loading-container">
         <Logo style={{width:"150px"}}/>
        <div className="spinner"></div>
        <div>
          <h6>Please Wait..</h6>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <Header admin={admin} />
      <div className="row dashboardrow" style={{ minHeight: "100vh" }}>
        <div
          className={`col-lg-2 col-md-2 col-sm-4 col-4 sidebar ${
            isSidebarCollapsed ? "sidebarCollapsed" : ""
          }`}
        >
          <Sidebar onToggleSidebar={handleToggleSidebar} admin={admin} />
        </div>
        <div
          className={`col-lg-10 col-md-10 col-sm-8 col-8 sidebar-column contentbar ${
            isSidebarCollapsed ? "contentExpanded" : ""
          }`}
        >
          <main>
            <div className="main_heading_dashboard">
              <div className="left_content">
                <h4 style={{ color: "black" }}>Companies</h4>
                <span>{filteredData.length} Companies</span>
              </div>

              <div className="right_content">
                <Link to="/admin/create-new-company"> <button>Add New Company + </button></Link>
               
              </div>
            </div>

            <div className="search_filter_section">
              <div className="row">
                <div className="col-lg-7 col-md-8">
                  <div className="search_bar">
                    <CompanySearch onSearch={setSearchTerm} />

                    <div className="toggle-btns">
                      <button onClick={showTableView} className={activeView === "tablelist" ? "active" : ""}>
                      <ListIcon/>
                      </button>
                      <button onClick={showGridView} className={activeView === "grid" ? "active" : ""}>
                      <GridIcon/>
                      </button>


                      <button onClick={showKanbanView} className={activeView === "kanban" ? "active" : ""}>
                      <KanbanIcon/>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 col-md-4">
                  <div className="select_options_filter">
                    <button
                      className="add_new_filter_company"
                      onClick={() => setIsFilterModalOpen(true)}
                    >
                      <FilterIcon/>
                      Add Filter
                    </button>

                    <button
                      className="edit_filter_company"
                      onClick={() => setIsFilterModalOpen(true)}
                    >
                     <FilterWhiteIcon/>
                      Edit Filters (3)
                    </button>
                    <span style={{ fontSize: "12px", color: "#212529" }}>
                      {" "}
                      Sort By:
                    </span>
                    <select className="recently-added">
                      <option>Recently Added</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row" >
                <div className="col-lg-12 col-md-12">
                  <div className="candidate_table_records">
                  {isKanbanView ? (
  <CompanyKanbanView data={filteredData} 
  
  totalRecords={totalRecords}
  totalPages={totalPages}
  currentPage={currentPage}
  setCurrentPage={setCurrentPage}
  setRecordsPerPage={setRecordsPerPage}
  recordsPerPage={recordsPerPage}
  />
) : isGridView ? (
  <CompanyGridList columns={columns} data={filteredData} admin={admin} 
  totalRecords={totalRecords}
  totalPages={totalPages}
  currentPage={currentPage}
  setCurrentPage={setCurrentPage}
  setRecordsPerPage={setRecordsPerPage}
  recordsPerPage={recordsPerPage}
  />
) : (
  <ComapnyListTable columns={columns} data={filteredData} admin={admin} 
  totalRecords={totalRecords}
  totalPages={totalPages}
  currentPage={currentPage}
  setCurrentPage={setCurrentPage}
  setRecordsPerPage={setRecordsPerPage}
  recordsPerPage={recordsPerPage}
  />
  // <h2>Company List Table</h2>
)}

                  </div>
                </div>

                <div
                  className={`main-box-filter ${
                    isFilterModalOpen ? "show" : "hide"
                  }`}
                >
                  <div className="mainbox-filter-header">
                    <h6>
                  <FilterIcon style={{marginRight:"6px"}}/>
                      Company Filter
                    </h6>

                    <div className="clearFilter">
              <FilterIcon style={{marginRight:"6px"}} />

                      <h5>Clear Filter</h5>
                      <span
                        onClick={() => setIsFilterModalOpen(false)}
                        style={{ cursor: "pointer" }}
                      >
                        X
                      </span>
                    </div>
                  </div>
                 
                 <div className="all_company_filters_component">
                 <AllCompanyFilters
                    isOpen={isFilterModalOpen}
                    onClose={() => setIsFilterModalOpen(true)}
                    onFilterClick={handleFilterClick}
                  />

                  {/* <CompanyManagerFilter
                    isOpen={isManagerModalOpen}
                    onClose={() => setIsManagerModalOpen(false)}
                    companyData={companyData} // Pass candidate data here
                    onSelectManager={handleManagerSelect} // Pass the handler
                    filterType={filterType} // Pass filterType as a prop to the child
                    setFilterType={setFilterType} // Pass setFilterType to update filterType
                    onResetFilters={resetTableData}
                  />

                  <TextSearchFilter
                    isOpen={isTextSearchModalOpen}
                    onClose={() => setIsTextSearchModalOpen(false)}
                    companyData={companyData}
                    onSelectcompany={handleTextSearch}
                  />

                  <CompanyNameFilter
                    isOpen={isCompanyNameModalOpen}
                    onClose={() => setIsCompanyNameModalOpen(false)}
                    filterType={filterType} // Pass filterType as a prop to the child
                    setFilterType={setFilterType}
                    companyData={companyData}
                    onSelectCompanyName={handleCompanyNameFilter}
                  />

                  <CurrentPlanFilter
                    isOpen={isCurrentFilterModalOpen}
                    onClose={() => setIsCurrentFilterModalOpen(false)}
                    filterType={filterType} // Pass filterType as a prop to the child
                    setFilterType={setFilterType}
                    companyData={companyData}
                    onSelectCurrentplan={handleCurrentPlanFilter}
                  />

                  <MilitaryBranchFilter
                    isOpen={isMilitaryBranchModalOpen}
                    onClose={() => setMilitaryBranchModalOpen(false)}
                    companyData={companyData}
                    onSelectBranch={handleSelectBranch}
                  />

                  <JobSearchStatusFilter
                    isOpen={isJobStatusModalOpen}
                    onClose={() => setIsJobStatusModalOpen(false)}
                    companyData={companyData}
                    onSelectStatus={handleSelectStatus}
                  />

                  <RelocationFilter
                    isOpen={isRelocationModalOpen}
                    onClose={() => setIsRelocationModalOpen(false)}
                    companyData={companyData}
                    onSelectRelocationPreference={handleSelectRelocation}
                  /> */}
                 </div>

                  {/* <SecurityClearanceFilter
                    isOpen={isSecurityClearanceModalOpen}
                    onClose={() => setIsSecurityClearanceModalOpen(false)}
                    companyData={companyData}
                    onSelectSecurityClearance={handleSecurityClearance}
                  /> */}
                </div>
              </div>
            </div>

            {/* Button to open modal */}

            <ColumnEditModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              columns={columns}
              toggleColumnVisibility={toggleColumnVisibility}
              onColumnReorder={onColumnReorder}
            />
          </main>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfilePage;
